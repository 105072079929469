import { StorageEnum } from "src/enums/AppLables.enum";
import { getSessionStorage } from "src/utils/Cookie.util";
import { UrlProvider } from "../../enums/UrlProvider.enum";
import {
  IForgotPassword,
  IGenerateMajors,
  ILogin,
  IRegister,
  IUpdatePersonalInfo,
  IUpdateProfileInfoRequest,
  SearchHistoryPayload,
  UserFavoritesRequestData,
  UserSearchHistoryRequestData,
  favoritesRequestObject
} from "../../interfaces/account/Account";
import {
  DeleteApi,
  PostApi,
  PutApi,
  getApi,
  getRefreshTokenOperation,
  getUserInfoApi,
} from "../Base.service";

export const Register = (formData: IRegister) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.Register}`;
  return PostApi(formData, apiUrl);
};

export const Login = (formData: ILogin) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.Login}`;
  return PostApi(formData, apiUrl);
};

export const ResetPassword = (formData: IForgotPassword) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.ResetPassword}`;
  return PostApi(formData, apiUrl);
};

export const UserSettings = (id: string) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.UserSettings}`;
  return getApi(id, apiUrl);
};

export const generateMajorsRequest = (requestBody: IGenerateMajors, userId?:number) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.GenerateMajors}/${userId}`;
  return PostApi(requestBody, apiUrl);
};

export const addFavoritesRequest = (
  requestBody: favoritesRequestObject,
  userEmail: string
) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.AddUserFavorites}/${userEmail}`;
  return PostApi(requestBody, apiUrl);
};

export const getPersonalInfoRequest = (userEmail: string) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.PersonalInfo}/${userEmail}`;
  return getUserInfoApi(apiUrl);
};

export const getRefreshToken = () => {
  const userEmail: any = getSessionStorage(StorageEnum.LoggedInUserEmail);
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.GetRefreshToken}/${userEmail}`;
  
  return getRefreshTokenOperation(apiUrl);
};

export const getUserFavorites = (userEmail: string) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.GetUserFavorites}/${userEmail}`;
  return getUserInfoApi(apiUrl);
};

export const updateUserPersonalInfo = (
  requestBody: IUpdatePersonalInfo,
  email: string
) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.UpdatePersonalInfo}/${email}`;

  return PutApi(requestBody, apiUrl);
};

export const getProfileInfoRequest = (userEmail: string) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.profileInfo}/${userEmail}`;
  return getUserInfoApi(apiUrl);
};

export const updateUserProfileInfo = (
  requestBody: IUpdateProfileInfoRequest,
  email: string
) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.UpdateProfileInfo}/${email}`;

  return PutApi(requestBody, apiUrl);
};

export const createUserProfileInfo = (
  requestBody: IUpdateProfileInfoRequest,
  email: string
) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.CreateProfileInfo}/${email}`;

  return PostApi(requestBody, apiUrl);
};

export const deleteUserProfileInfo = (email?: string) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.DeleteProfileInfo}/${email}`;

  return DeleteApi(email, apiUrl);
};

export const deleteUserFavoritesAPI = (
  request?: UserFavoritesRequestData,
  email?: string
) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.DeleteUserFavorites}/${email}`;

  return DeleteApi(request, apiUrl);
};

export const saveHistoryOperation = (requestBody: SearchHistoryPayload, email?:string) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.UserSearchHistory}/${email}`;
  return PostApi(requestBody, apiUrl);
};


export const getUserSearchHistory = (userEmail: string) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.GetUserSearchHistory}/${userEmail}`;
  return getUserInfoApi(apiUrl);
};

export const deleteUserSearchHistoryAPI = (
  request?: UserSearchHistoryRequestData,
  email?: string
) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}${UrlProvider.DeleteUserSearchHistory}/${email}`;

  return DeleteApi(request, apiUrl);
};

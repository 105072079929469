import { Page } from "../page/Page";

import "./FindColleges.styles.scss";

export const FindColleges = () => {
  return (
    <Page>
      <div>
        <h1 className="find-colleges-page">Under Development</h1>
      </div>
    </Page>
  );
};

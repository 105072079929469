import { Checkbox, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { SignupButtonsLabel } from "../../enums/AppButtonEnums.enum";
import { SignupErrorsEnum } from "../../enums/AppErrorsEnum.enum";
import { SignupLabels } from "../../enums/AppLables.enum";
import { SignupPlaceholders } from "../../enums/AppPlaceholderEnums.enum";

import React from "react";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "src/enums/RouterPath.enum";
import { CollegeCrafterLogoForSignUp } from "src/header/SignupLog";
import "../../App.scss";
import {
  StringConstant,
  emailRegex,
  passwordRegex,
} from "../../enums/StringConstant";
import { Register } from "../../services/account/Account.service";
import { isEmpty } from "../../utils/App.util";
import { BackgroundImage } from "../backgroundimage/BackgroundImage";
import { CollegeCrafterEmail } from "../footer/CollegeCrafterEmail";
import { Copyright } from "../footer/CopyRight";
import { Loader } from "../loader/Loader";
import "./SignupForm.styles.scss";

const initialData = {
  firstname: StringConstant.EMPTY,
  lastname: StringConstant.EMPTY,
  email: StringConstant.EMPTY,
  password: StringConstant.EMPTY,
  invitationCode: StringConstant.EMPTY,
};

export const SignupForm = () => {
  const defaultTheme = createTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialData);
  const [validationErrors, setValidationErrors] = useState(initialData);
  const [errorMessage, setErrorMessage] = useState("");
  const [aggreed, setAggreed] = useState(false);
  const [checkBoxError, setCheckBoxError] = useState(false);
  const [apiResponse, setApiResponse] = React.useState("");
  const [showLoader, setShowLoader] = React.useState(false);
  const navigateToLogin = () => {
    navigate(RouterPath.Login);
  };

  const validateEmail = (email: any) => {
    return emailRegex.test(email);
  };

  const validatePassword = (password: any) => {
    return passwordRegex.test(password);
  };

  const validatePasswordCreteria = (password: string) => {
    if (password?.length > 0 && !passwordRegex.test(password)) {
      setErrorMessage("Password criteria not met");
    } else if (password?.length === 0 || !password) {
      setErrorMessage("Please enter valid password");
    } else {
      setErrorMessage("");
    }
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });

    if (name === SignupErrorsEnum.FirstName) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        firstname: value
          ? StringConstant.EMPTY
          : SignupErrorsEnum.FirstNameRequired,
      }));
    }
    if (name === SignupErrorsEnum.LastName) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        lastname: value
          ? StringConstant.EMPTY
          : SignupErrorsEnum.LastNameRequired,
      }));
    }
    if (name === "invitationCode") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        invitationCode: value
          ? StringConstant.EMPTY
          : "Invitation code is required",
      }));
    } else if (name === SignupErrorsEnum.Email) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        email: validateEmail(value)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.EmailRequired,
      }));
    } else if (name === SignupErrorsEnum.Password) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: validatePassword(value)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.PasswordRequired,
      }));
    }
  };

  const handlePasswordInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    validatePasswordCreteria(value);
    if (name === SignupErrorsEnum.Password) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: validatePassword(value)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.PasswordRequired,
      }));
    }
  };

  const handleInvitationCodeChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === "invitationCode") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        invitationCode: value
          ? StringConstant.EMPTY
          : "Invitation code is required",
      }));
    }
  };

  const registerAccount = async () => {
    setShowLoader(true);
    const payload = {
      email: formData?.email?.trim(),
      firstname: formData?.firstname?.trim(),
      lastname: formData?.lastname?.trim(),
      invitationCode: formData?.invitationCode?.trim(),
      password: formData?.password?.trim(),
    };
    await Register(payload)
      .then((response) => {
        if (response?.data) {
          setShowLoader(false);
          navigateToLogin();
        } else {
          setShowLoader(false);
          setApiResponse("An error occured please enter valid data");
        }
      })
      .catch((errorData) => {
        setShowLoader(false);
        console.log(errorData);
        const ErrorCode = Number(errorData?.response?.status);
        if (ErrorCode === 409) {
          setShowLoader(false);
          setApiResponse(
            errorData?.response?.data?.message ||
              errorData?.response?.data?.error
          );
        } else if (errorData?.response?.status === 409) {
          setShowLoader(false);
          setApiResponse(
            errorData?.response?.data?.message ||
              errorData?.response?.data?.error
          );
        } else if (
          errorData?.response?.status === 400 ||
          errorData?.response?.status === 404
        ) {
          setShowLoader(false);
          setApiResponse(errorData?.response?.data);
        } else {
          setShowLoader(false);
          setApiResponse("An error occured please enter valid data");
        }
      });
  };

  const isValidFormData = () => {
    const formErrors = {
      firstname: !isEmpty(formData.firstname)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.FirstNameRequired,
      lastname: !isEmpty(formData.lastname)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.LastNameRequired,
      invitationCode: !isEmpty(formData.invitationCode)
        ? StringConstant.EMPTY
        : "Invitation code is required",
      email: validateEmail(formData.email)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.EmailRequired,
      password: validatePassword(formData.password)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.PasswordRequired,
    };

    return (
      isEmpty(formErrors.firstname) &&
      isEmpty(formErrors.lastname) &&
      isEmpty(formErrors.invitationCode) &&
      isEmpty(formErrors.email) &&
      isEmpty(formErrors.password)
    );
  };

  const validateForm = (formErrors: any) => {
    if (
      isEmpty(formErrors.firstname) &&
      isEmpty(formErrors.lastname) &&
      isEmpty(formErrors.email) &&
      isEmpty(formErrors.invitationCode) &&
      isEmpty(formErrors.password) &&
      aggreed
    ) {
      setCheckBoxError(false);
      registerAccount();
    } else {
      setCheckBoxError(true);
    }
  };

  useEffect(() => {
    setCheckBoxError(false);
  }, [aggreed]);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const formErrors = {
      firstname: !isEmpty(formData.firstname)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.FirstNameRequired,
      lastname: !isEmpty(formData.lastname)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.LastNameRequired,
      invitationCode: !isEmpty(formData.invitationCode)
        ? StringConstant.EMPTY
        : "Invitation code is required",
      email: validateEmail(formData.email)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.EmailRequired,
      password: validatePassword(formData.password)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.PasswordRequired,
    };

    setValidationErrors({ ...formErrors });
    validateForm(formErrors);
  };

  const toggleConditions = (event: any) => {
    setAggreed(event.target.checked);
  };

  return (
    <div>
      <ThemeProvider theme={defaultTheme}>
        {showLoader && <Loader />}
        <Grid container component="main" className="main-grid-section">
          <CssBaseline />
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <CollegeCrafterLogoForSignUp />

            <Box className="form-mainbox-section">
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                className="signup-form"
              >
                <Typography
                  component="h1"
                  variant="h5"
                  className="signup-header-section"
                >
                  Sign Up
                </Typography>
                <div className="signup-input-element-layout">
                  <span>{SignupLabels.FirstName}</span>
                  <TextField
                    margin="normal"
                    required
                    autoComplete="new-firstname"
                    className={"input-element"}
                    fullWidth
                    id="firstname"
                    placeholder={SignupPlaceholders.EnterName}
                    name="firstname"
                    value={formData.firstname}
                    error={!!validationErrors.firstname}
                    helperText={validationErrors.firstname}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="signup-input-element-layout">
                  <span>{SignupLabels.LastName}</span>
                  <TextField
                    margin="normal"
                    required
                    className={"input-element"}
                    fullWidth
                    autoComplete="new-lastname"
                    id="lastname"
                    placeholder={SignupPlaceholders.EnterName}
                    name="lastname"
                    value={formData.lastname}
                    error={!!validationErrors.lastname}
                    helperText={validationErrors.lastname}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="signup-input-element-layout">
                  <span>{SignupLabels.EmailAddress}</span>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    className={"input-element"}
                    id="email"
                    autoComplete="new-email"
                    placeholder={SignupPlaceholders.EnterEmail}
                    name="email"
                    value={formData.email}
                    error={!!validationErrors.email}
                    helperText={validationErrors.email}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="signup-input-element-layout">
                  <span>{SignupLabels.Password}</span>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    className="input-element"
                    id="password"
                    autoComplete="new-password"
                    name="password"
                    type={"password"}
                    value={formData.password}
                    error={!!validationErrors.password}
                    onChange={handlePasswordInputChange}
                    placeholder={SignupPlaceholders.EnterForgotPassword}
                  />
                  <div className="signup-pwd-api-error-message">
                    {errorMessage && (
                      <Typography color="error" variant="body2">
                        {errorMessage}
                      </Typography>
                    )}
                  </div>
                </div>
                <p className="pwd-restrict-message">
                  {SignupLabels.PasswordRestiction}
                </p>
                <div className="signup-input-element-layout">
                  <span>{"Invitation Code*"}</span>
                  <TextField
                    margin="normal"
                    required
                    autoComplete="new-invitationCode"
                    className="input-element"
                    name="invitationCode"
                    id="invitationCode"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 20 }}
                    type={"text"}
                    value={formData.invitationCode}
                    error={!!validationErrors.invitationCode}
                    onChange={handleInvitationCodeChange}
                    helperText={validationErrors.invitationCode}
                    placeholder={"Enter your invitation code"}
                  />
                </div>
                <div className="invitation-api-error-message">
                  {apiResponse && (
                    <Typography color="error" variant="body2">
                      {apiResponse}
                    </Typography>
                  )}
                </div>
                <div>
                  <div className="create-btn-section">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className="create-btn"
                      onClick={(e) => handleSubmit(e)}
                    >
                      <Typography style={{ textTransform: "none" }}>
                        {SignupButtonsLabel.CreateAccount}
                      </Typography>
                    </Button>
                  </div>
                </div>
                {!aggreed && checkBoxError ? (
                  <div className="signup-btn-section-error">
                    <div className="error-service-aggr-container">
                      <div className="error-service-aggr-text">
                        <Checkbox
                          size="small"
                          className="error-service-aggr-checkbox"
                          onChange={toggleConditions}
                        />
                        {SignupLabels.ServiceAgreement}{" "}
                        <Link variant="body2" className="error-terms-link">
                          Terms of Service Agreement
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="signup-btn-section">
                    <div className="service-aggr-container">
                      <div className="service-aggr-text">
                        <Checkbox
                          size="small"
                          className="service-aggr-checkbox"
                          onChange={toggleConditions}
                        />
                        {SignupLabels.ServiceAgreement}{" "}
                        <Link variant="body2" className="terms-link">
                          Terms of Service Agreement
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
                <div className="already-account">
                  {SignupLabels.HaveanAccount}{" "}
                  <Link
                    className="terms-link"
                    href="#"
                    variant="body2"
                    onClick={navigateToLogin}
                  >
                    {SignupLabels.Login}{" "}
                  </Link>
                </div>
                <div className="copy-rights-section">
                  <Copyright />
                  <CollegeCrafterEmail />
                </div>
              </Box>
            </Box>
          </Grid>
          <BackgroundImage />
        </Grid>
      </ThemeProvider>
    </div>
  );
};

import { Typography } from "@mui/material";

import "./Copyright.styles.scss";

export const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      className="collogecraftercopyrights-text"
      {...props}
    >
      {"© CollegeCrafter@2024"}
    </Typography>
  );
};

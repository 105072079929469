import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import { RouterPath } from "src/enums/RouterPath.enum";

import { NavigationComponent } from "../navigation/NavigationComponent";
import "./Home.styles.scss";

export const Home = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    navigate(RouterPath.FindMajorsSearchMode);
  }, []);

  return (
    <>
      <div className="home-page">
        <NavigationComponent />
        <div className="outlet-page">
          <Outlet />
        </div>
      </div>
    </>
  );
};

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "src/enums/RouterPath.enum";
import {
  emailRegex,
  passwordRegex,
  StringConstant,
} from "src/enums/StringConstant";
import { CollegeCrafterLogo } from "src/header/CollegeCrafterlogo";
import { IForgotPassword } from "src/interfaces/account/Account";
import { ResetPassword } from "src/services/account/Account.service";
import "../../App.scss";
import { SignupButtonsLabel } from "../../enums/AppButtonEnums.enum";
import { SignupErrorsEnum } from "../../enums/AppErrorsEnum.enum";
import { SignupLabels } from "../../enums/AppLables.enum";
import { SignupPlaceholders } from "../../enums/AppPlaceholderEnums.enum";
import { BackgroundImage } from "../backgroundimage/BackgroundImage";
import { CollegeCrafterEmail } from "../footer/CollegeCrafterEmail";
import { Copyright } from "../footer/CopyRight";
import "./ForgotPassword.styles.scss";

export const ForgotPassword = () => {
  const defaultTheme = createTheme();
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showRetypePassword, setShowRetypePassword] = React.useState(false);

  const handleToggleRetypePasswordVisibility = () => {
    setShowRetypePassword((prevShowretypePassword) => !prevShowretypePassword);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const navigateToLogin = () => {
    navigate(RouterPath.Login);
  };

  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
    retypepassword: "",
  });

  const [validationErrors, setValidationErrors] = React.useState({
    email: "",
    password: "",
    retypepassword: "",
  });

  const validateEmail = (email: any) => {
    return emailRegex.test(email);
  };

  const validatePassword = (password: any) => {
    return passwordRegex.test(password);
  };

  const validateRetypePassword = (retypepassword: any) => {
    return passwordRegex.test(retypepassword);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setApiResponse("");

    if (name === SignupErrorsEnum.Email) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        email: validateEmail(value)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.EmailRequired,
      }));
    }
  };
  const handlePwdInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setApiResponse("");

    if (name === SignupErrorsEnum.Password) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: validatePassword(value)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.PasswordRequired,
      }));
    }
  };
  const handleRetypePwdInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setApiResponse("");

    if (name === SignupErrorsEnum.RetypePassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        retypepassword:
          validatePassword(value) && formData.password === value
            ? StringConstant.EMPTY
            : SignupErrorsEnum.PasswordMismatch,
      }));
    }
  };

  const resetPassword = async () => {
    const payload: IForgotPassword = {
      email: formData.email?.trim(),
      newPassword: formData?.password?.trim(),
    };
    await ResetPassword(payload)
      .then(() => {
        navigateToLogin();
      })
      .catch((errorData) => {
        console.log(errorData);
        setApiResponse(errorData?.response?.data?.message);
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const formErrors = {
      email: validateEmail(formData.email)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.EmailRequired,
      password: validatePassword(formData.password)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.PasswordRequired,
      retypepassword:
        validateRetypePassword(formData.retypepassword) &&
        formData.password === formData.retypepassword
          ? StringConstant.EMPTY
          : SignupErrorsEnum.PasswordMismatch,
    };

    setValidationErrors(formErrors);

    if (formErrors.email || formErrors.password || formErrors.retypepassword) {
      setApiResponse("");
      return;
    } else {
      resetPassword();
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" className="forgot-pwd-main-grid-section">
        <CssBaseline />
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CollegeCrafterLogo />

          <Box className="forgot-pwd-form-section">
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              className="forgot-pwd-form-content"
            >
              <div className="forgot-pwd-header-part">
                <div>
                  <Typography className="forgot-pwd-header-section">
                    Forgot Password
                  </Typography>
                </div>
                <div>
                  <Typography className="forgot-pwd-welcomeback-text">
                    Welcome back! Please enter your new password below.
                  </Typography>
                </div>
              </div>
              <div className="input-element-layout">
                <span>{SignupLabels.ForgotPasswordEmail}</span>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="new-email"
                  className={"input-element"}
                  id="email"
                  placeholder={SignupPlaceholders.EnterEmail}
                  name="email"
                  value={formData.email}
                  error={!!validationErrors.email}
                  helperText={validationErrors.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-element-layout">
                <span>{SignupLabels.ForgotPasswordNewPwd}</span>
                <TextField
                  margin="normal"
                  required
                  id="password"
                  fullWidth
                  autoComplete="new-password"
                  className="input-element"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  error={!!validationErrors.password}
                  helperText={validationErrors.password}
                  onChange={handlePwdInputChange}
                  placeholder={SignupPlaceholders.EnterForgotPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="f-custom-input-adornment"
                      >
                        <IconButton
                          className="eye-icon-alignment"
                          onClick={handleTogglePasswordVisibility}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <p className="forgot-pwd-complexity-message">
                  {SignupLabels.PasswordRestiction}
                </p>
              </div>

              <div className="input-element-layout">
                <span>{SignupLabels.retypepassword}</span>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="new-retypepassword"
                  id="retypepassword"
                  className="input-element"
                  name="retypepassword"
                  type={showRetypePassword ? "text" : "password"}
                  value={formData.retypepassword}
                  error={!!validationErrors.retypepassword}
                  helperText={validationErrors.retypepassword}
                  onChange={handleRetypePwdInputChange}
                  placeholder="Please re-enter your password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="fr-custom-input-adornment"
                      >
                        <IconButton
                          className="eye-icon-alignment"
                          onClick={handleToggleRetypePasswordVisibility}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          {showRetypePassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <div className="forgot-api-error-message">
                  {apiResponse && (
                    <Typography color="error" variant="body2">
                      {apiResponse}
                    </Typography>
                  )}
                </div>
                <div className="save-btn-section">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={
                      formData?.email?.length == 0 ||
                      formData?.password?.length == 0 ||
                      formData?.retypepassword?.length == 0
                        ? "save-btn-disabled-mode"
                        : "save-btn"
                    }
                    disabled={
                      formData?.email?.length == 0 ||
                      formData?.password?.length == 0 ||
                      formData?.retypepassword?.length == 0
                    }
                  >
                    <Typography style={{ textTransform: "none" }}>
                      {SignupButtonsLabel.save}
                    </Typography>
                  </Button>
                </div>
              </div>

              <div className="already-have-account">
                <div className="login-section">
                  <span>{SignupLabels.HaveanAccount}</span>
                  <Link
                    variant="body2"
                    className="forgot-pwd-login-label"
                    onClick={navigateToLogin}
                  >
                    {SignupLabels.Login}
                  </Link>
                </div>
              </div>
              <div className="forgotpwd-bottom-section">
                <Copyright />
                <CollegeCrafterEmail />
              </div>
            </Box>
          </Box>
        </Grid>
        <BackgroundImage />
      </Grid>
    </ThemeProvider>
  );
};

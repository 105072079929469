import { CircularProgress } from "@mui/material";
import "./Loader.scss";

export const Loader = () => {
  return (
    <div className="overlay">
      <CircularProgress />
    </div>
  );
};

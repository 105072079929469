import { Popper } from "@mui/material";

export const CustomPopper = (props: any) => {
  return (
    <Popper
      {...props}
      placement="bottom-start"
      modifiers={[
        {
          name: "flip",
          enabled: false,
        },
        {
          name: "preventOverflow",
          options: {
            altBoundary: true,
            tether: false,
          },
        },
      ]}
    />
  );
};

import { CoursesList } from "src/enums/Courses.enum";
import { Category } from "src/interfaces/account/Account";

export const categoryData:Category[]  = [
  {
    id: 1,
    name: CoursesList.STEM,
    prompt: "The study of Science, Technology, Engineering, and Mathematics.",
    className: "STEM",
    subcategories: [
      {
        id: 1,
        name: CoursesList.Science,
        prompt: "The systematic study of the structure and behavior of the physical and natural world.",
        subCategoryItems: [
          { id: 1, name: CoursesList.Physics, prompt: "The study of matter, energy, and the interactions between them." },
          { id: 2, name: CoursesList.Chemistry, prompt: "The study of substances and their properties and reactions." },
          { id: 3, name: CoursesList.Biology, prompt: "The study of living organisms." },
          { id: 4, name: CoursesList.Agriculture, prompt: "The science or practice of farming." },
          { id: 5, name: CoursesList.Astronomy, prompt: "The study of celestial objects and space." },
          { id: 6, name: CoursesList.Botany, prompt: "The scientific study of plants." },
          { id: 7, name: CoursesList.EarthScience, prompt: "The study of the Earth's structure and processes." },
          { id: 8, name: CoursesList.EnvironmentalScience, prompt: "The study of the environment and solutions to environmental problems." },
          { id: 9, name: CoursesList.ForensicScience, prompt: "The application of scientific methods to solve crimes." },
          { id: 10, name: CoursesList.Geology, prompt: "The study of the Earth's physical structure and substance." },
          { id: 11, name: CoursesList.Genetics, prompt: "The study of genes, genetic variation, and heredity in living organisms." },
          { id: 12, name: CoursesList.MarineBiology, prompt: "The study of marine organisms and ecosystems." },
          { id: 13, name: CoursesList.Anatomy, prompt: "The study of the structure of organisms and their parts." },
          { id: 14, name: CoursesList.PhysicalScience, prompt: "The study of non-living systems, as opposed to life sciences." },
          { id: 15, name: CoursesList.Zoology, prompt: "The study of animals and their biological processes." },
          { id: 16, name: CoursesList.Neuroscience, prompt: "The study of the nervous system and brain." },
          { id: 17, name: CoursesList.Epidemiology, prompt: "The study of how diseases spread and can be controlled." },
        ].sort((a, b) => a.name.localeCompare(b?.name)),
      },
      {
        id: 2,
        name: CoursesList.Mathematics,
        prompt: "The study of numbers, quantities, shapes, and patterns.",
        subCategoryItems: [
          { id: 18, name: CoursesList.Algebra, prompt: "The study of mathematical symbols and rules for manipulating them." },
          { id: 19, name: CoursesList.PreAlgebra, prompt: "A preparatory course for algebra, covering basic arithmetic and introductory algebraic concepts." },
          { id: 20, name: CoursesList.Calculus, prompt: "The study of continuous change and the rates at which quantities change." },
          { id: 21, name: CoursesList.PreCalculus, prompt: "A course that prepares students for calculus, covering algebra and trigonometry." },
          { id: 22, name: CoursesList.Geometry, prompt: "The study of shapes, sizes, relative positions, and properties of space." },
          { id: 23, name: CoursesList.Trigonometry, prompt: "The study of relationships involving lengths and angles of triangles." },
          { id: 24, name: CoursesList.Statistics, prompt: "The study of data collection, analysis, interpretation, and presentation." },
          { id: 25, name: CoursesList.IntegratedMath, prompt: "A course that integrates algebra, geometry, and other mathematical concepts." },
          { id: 26, name: CoursesList.FundamentalsofMath, prompt: "Basic principles and skills in mathematics." },
          { id: 27, name: CoursesList.ConsumerMath, prompt: "Mathematics related to personal finance and everyday calculations." },
          { id: 28, name: CoursesList.ComputerMath, prompt: "Mathematics used in computer science, including algorithms and logic." },
          { id: 29, name: CoursesList.MathApplications, prompt: "The application of mathematical methods to solve real-world problems." },
          { id: 30, name: CoursesList.MultivariableCalculus, prompt: "Calculus involving multiple variables." },
          { id: 31, name: CoursesList.PracticalMath, prompt: "Math used in practical, everyday situations." },
          { id: 32, name: CoursesList.QuantitativeLiteracy, prompt: "The ability to understand and use quantitative information." },
          { id: 33, name: CoursesList.Probability, prompt: "The study of the likelihood of events occurring." },
        ].sort((a, b) => a.name.localeCompare(b?.name)),
      },
      {
        id: 3,
        name: CoursesList.Engineering,
        prompt: "The application of science and math to solve problems.",
        subCategoryItems: [
          { id: 34, name: CoursesList.DPrinting, prompt: "The process of creating three-dimensional objects using a digital model." },
          { id: 35, name: CoursesList.ComputerAidedDesign, prompt: "The use of computers to create and modify engineering designs." },
          { id: 36, name: CoursesList.Robotics, prompt: "The design, construction, and operation of robots." },
          { id: 37, name: CoursesList.Electronics, prompt: "The study and use of electrical circuits and devices." },
          { id: 38, name: CoursesList.ArchitectureDesign, prompt: "The art and science of designing buildings and structures." },
          { id: 39, name: CoursesList.CivilEngineering, prompt: "The design and construction of public works, such as roads and bridges." },
          { id: 40, name: CoursesList.MechanicalEngineering, prompt: "The design and production of mechanical systems and devices." },
        ].sort((a, b) => a.name.localeCompare(b?.name)),
      },
      {
        id: 4,
        name: CoursesList.Technology,
        prompt: "The application of scientific knowledge for practical purposes.",
        subCategoryItems: [
          { id: 41, name: CoursesList.Animation, prompt: "The art of creating moving images through a sequence of images." },
          { id: 42, name: CoursesList.ComputerScience, prompt: "The study of computers and computational systems." },
          { id: 43, name: CoursesList.Coding, prompt: "The process of writing instructions for computers to follow." },
          { id: 44, name: CoursesList.WebDesign, prompt: "The process of creating websites, including layout, content production, and graphic design." },
          { id: 45, name: CoursesList.GraphicDesign, prompt: "The art of creating visual content to communicate messages." },
          { id: 46, name: CoursesList.TypingKeyboarding, prompt: "The skill of typing on a keyboard quickly and accurately." },
          { id: 47, name: CoursesList.AppDevelopment, prompt: "The process of creating applications for mobile devices and computers." },
          { id: 48, name: CoursesList.MediaDesign, prompt: "The design of digital content, including audio, video, and images." },
          { id: 49, name: CoursesList.AudioMusicProduction, prompt: "The process of creating and recording music and audio." },
          { id: 50, name: CoursesList.ComputerRepair, prompt: "The process of identifying and fixing computer hardware and software issues." },
          { id: 51, name: CoursesList.WebProgramming, prompt: "The process of creating and maintaining the code that powers websites." },
          { id: 52, name: CoursesList.WordProcessing, prompt: "The use of computer software to create, edit, and format text documents." },
        ].sort((a, b) => a.name.localeCompare(b?.name)),
      },
      {
        id: 5,
        name: CoursesList.Medicine,
        prompt: "The science of diagnosing, treating, and preventing illness.",
        subCategoryItems: [
          { id: 53, name: CoursesList.Surgery, prompt: "The branch of medicine that deals with the physical manipulation of a bodily structure to diagnose, treat, or cure a condition." },
          { id: 54, name: CoursesList.Cardiology, prompt: "The branch of medicine that deals with disorders of the heart." },
          { id: 55, name: CoursesList.Dermatology, prompt: "The branch of medicine that deals with skin disorders." },
          { id: 56, name: CoursesList.Neurology, prompt: "The branch of medicine that deals with disorders of the nervous system." },
          { id: 57, name: CoursesList.Psychiatry, prompt: "The branch of medicine that deals with mental health disorders." },
          { id: 58, name: CoursesList.Pediatrics, prompt: "The branch of medicine that deals with the health of infants, children, and adolescents." },
          { id: 59, name: CoursesList.Radiology, prompt: "The branch of medicine that uses imaging to diagnose and treat diseases." },
          { id: 60, name: CoursesList.Nursing, prompt: "The profession of providing care for the sick and infirm." },
          { id: 61, name: CoursesList.Dentistry, prompt: "The branch of medicine that deals with the diagnosis, prevention, and treatment of oral diseases." },
          { id: 62, name: CoursesList.SpeechLanguagePathology, prompt: "The study of human communication, its disorders, and the assessment and treatment of those disorders." },
          { id: 63, name: CoursesList.OccupationalTherapy, prompt: "A form of therapy that helps individuals perform daily activities through therapeutic practices." },
          { id: 64, name: CoursesList.Anesthesiology, prompt: "The branch of medicine concerned with the relief of pain and the management of anesthesia during surgical procedures." },
          { id: 65, name: CoursesList.Veterinary, prompt: "The branch of medicine that deals with the diagnosis and treatment of animals." },
          { id: 66, name: CoursesList.PhysicalTherapy, prompt: "A treatment method that uses physical agents and exercises to promote healing and improve physical function." },
          { id: 67, name: CoursesList.Optometry, prompt: "The practice of examining the eyes and visual systems for defects and diseases." },
          { id: 68, name: CoursesList.Gynecology, prompt: "The branch of medicine that deals with the female reproductive system." },
          { id: 69, name: CoursesList.Pharmacy, prompt: "The science of preparing and dispensing medications." },
          { id: 70, name: CoursesList.Podiatry, prompt: "The branch of medicine that deals with the diagnosis and treatment of foot disorders." },
          { id: 71, name: CoursesList.Orthodontics, prompt: "The branch of dentistry that deals with correcting irregularities of the teeth and jaw." },
          { id: 72, name: CoursesList.Chiropractic, prompt: "A form of alternative medicine focused on diagnosing and treating mechanical disorders of the musculoskeletal system." },
          { id: 73, name: CoursesList.EmergencyMedicine, prompt: "A medical specialty that focuses on the diagnosis and treatment of acute illnesses and injuries." },
          { id: 74, name: CoursesList.ForensicPathology, prompt: "A branch of pathology that determines the cause of death by examining a corpse." },
          { id: 75, name: CoursesList.PlasticSurgery, prompt: "The branch of medicine that involves the restoration, reconstruction, or alteration of the human body." }
        ].sort((a, b) => a.name.localeCompare(b?.name)),
      }
      
    ],
  },
  {
    id: 2,
    name: CoursesList.Humanities,
    prompt: "The study of human society, culture, and the arts.",
    className: "Humanities",
    subcategories: [
      {
        id: 9,
        name: CoursesList.EnglishLanguageArts,
        prompt: "The study of reading, writing, and communication in the English language.",
        subCategoryItems: [
          { id: 1, name: CoursesList.AmericanLiterature, prompt: "The study of literature produced in the United States." },
          { id: 2, name: CoursesList.Debate, prompt: "The art of formal argumentation." },
          { id: 3, name: CoursesList.Poetry, prompt: "The study of poetic forms and techniques." },
          { id: 4, name: CoursesList.Composition, prompt: "The process of writing essays and other forms of discourse." },
          { id: 5, name: CoursesList.Rhetoric, prompt: "The art of persuasive speaking and writing." },
          { id: 6, name: CoursesList.Literature, prompt: "The study of written works, especially those considered to have artistic merit." },
          { id: 7, name: CoursesList.Journalism, prompt: "The activity of gathering, assessing, creating, and presenting news and information." },
          { id: 8, name: CoursesList.History, prompt: "The study of past events." },
          { id: 9, name: CoursesList.Philosophy, prompt: "The study of fundamental questions about existence, knowledge, and ethics." },
          { id: 10, name: CoursesList.CreativeWriting, prompt: "The art of writing imaginative or original works." },
          { id: 11, name: CoursesList.WorldLiterature, prompt: "The study of literature from around the globe." },
          { id: 12, name: CoursesList.ComparativeLiterature, prompt: "The study of literature across cultural and linguistic boundaries." },
          { id: 13, name: CoursesList.LiteraryAnalysis, prompt: "The practice of examining and interpreting literature." },
          { id: 14, name: CoursesList.TechnicalWriting, prompt: "The practice of creating clear and concise documents." },
          { id: 15, name: CoursesList.Communication, prompt: "The study of how information is conveyed." },
          { id: 16, name: CoursesList.LiteratureandComposition, prompt: "The combination of literature study and writing." },
          { id: 17, name: CoursesList.LanguageandComposition, prompt: "The study of language and its usage in composition." },
        ].sort((a, b) => a.name.localeCompare(b.name)),
      },
      {
        id: 10,
        name: CoursesList.Arts,
        prompt: "The expression or application of human creative skill and imagination.",
        subCategoryItems: [
          { id: 1, name: CoursesList.ChorusChoir, prompt: "The art of singing in a group." },
          { id: 2, name: CoursesList.MarchingBand, prompt: "A group of musicians who perform while marching." },
          { id: 3, name: CoursesList.JazzBand, prompt: "A musical ensemble that plays jazz music." },
          { id: 4, name: CoursesList.Piano, prompt: "The study and performance of piano music." },
          { id: 5, name: CoursesList.VideoProductionFilmmaking, prompt: "The process of creating video content." },
          { id: 6, name: CoursesList.MusicalTheater, prompt: "The art of theater combining songs, spoken dialogue, and dance." },
          { id: 7, name: CoursesList.Drama, prompt: "The study and performance of dramatic works." },
          { id: 8, name: CoursesList.FiberArts, prompt: "Artistic work that uses textiles and fibers." },
          { id: 9, name: CoursesList.Ceramics, prompt: "The art of making objects from clay." },
          { id: 10, name: CoursesList.Drawing, prompt: "The practice of creating images using pencils, pens, or other tools." },
          { id: 11, name: CoursesList.Painting, prompt: "The art of applying pigment to a surface." },
          { id: 12, name: CoursesList.DigitalArt, prompt: "Art created using digital technology." },
          { id: 13, name: CoursesList.Photography, prompt: "The art of capturing images using a camera." },
          { id: 14, name: CoursesList.MusicTheory, prompt: "The study of the practices and possibilities of music." },
          { id: 15, name: CoursesList.PerformanceArts, prompt: "Art forms that are performed in front of an audience." },
          { id: 16, name: CoursesList.ArtHistory, prompt: "The study of the history and development of art." },
          { id: 17, name: CoursesList.Orchestra, prompt: "A large ensemble of musicians playing together." },
          { id: 18, name: CoursesList.Percussion, prompt: "The study and performance of percussion instruments." },
        ].sort((a, b) => a.name.localeCompare(b.name)),
      },
      {
        id: 11,
        name: CoursesList.SocialStudies,
        prompt: "The study of human society and social relationships.",
        subCategoryItems: [
          { id: 1, name: CoursesList.WorldHistory, prompt: "The study of global historical events." },
          { id: 2, name: CoursesList.Geography, prompt: "The study of the Earth's physical features and human societies." },
          { id: 3, name: CoursesList.CivicsandGovernment, prompt: "The study of citizenship and government." },
          { id: 4, name: CoursesList.EuropeanHistory, prompt: "The study of historical events in Europe." },
          { id: 5, name: CoursesList.InternationalRelations, prompt: "The study of relationships between countries." },
          { id: 6, name: CoursesList.WomensStudies, prompt: "The study of women's roles and experiences in society." },
          { id: 7, name: CoursesList.ReligiousStudies, prompt: "The study of religious beliefs and practices." },
          { id: 8, name: CoursesList.Sociology, prompt: "The study of social behavior and society." },
          { id: 9, name: CoursesList.Anthropology, prompt: "The study of human cultures and societies." },
          { id: 10, name: CoursesList.CurrentEvents, prompt: "The study of contemporary issues and events." },
          { id: 11, name: CoursesList.PoliticalScience, prompt: "The study of political systems and behavior." },
          { id: 12, name: CoursesList.WorldReligions, prompt: "The study of major religions around the globe." },
          { id: 13, name: CoursesList.Economics, prompt: "The study of the production, distribution, and consumption of goods." },
          { id: 14, name: CoursesList.AmericanHistory, prompt: "The study of the history of the United States." },
          { id: 15, name: CoursesList.ComparativeGovernment, prompt: "The study of different governments and political systems." },
          { id: 16, name: CoursesList.Law, prompt: "The study of legal systems and principles." },
          { id: 17, name: CoursesList.Psychology, prompt: "The study of the mind and behavior." },
        ].sort((a, b) => a.name.localeCompare(b.name)),
      },
      {
        id: 12,
        name: CoursesList.ForeignLanguages,
        prompt: "The study of languages other than one's native language.",
        subCategoryItems: [
          { id: 1, name: CoursesList.Spanish, prompt: "The study of the Spanish language." },
          { id: 2, name: CoursesList.Chinese, prompt: "The study of the Chinese language." },
          { id: 3, name: CoursesList.Japanese, prompt: "The study of the Japanese language." },
          { id: 4, name: CoursesList.Italian, prompt: "The study of the Italian language." },
          { id: 5, name: CoursesList.French, prompt: "The study of the French language." },
          { id: 6, name: CoursesList.Russian, prompt: "The study of the Russian language." },
          { id: 7, name: CoursesList.German, prompt: "The study of the German language." },
          { id: 8, name: CoursesList.Hindi, prompt: "The study of the Hindi language." },
          { id: 9, name: CoursesList.ASL, prompt: "The study of American Sign Language." },
          { id: 10, name: CoursesList.Korean, prompt: "The study of the Korean language." },
          { id: 11, name: CoursesList.Arabic, prompt: "The study of the Arabic language." },
          { id: 12, name: CoursesList.Portuguese, prompt: "The study of the Portuguese language." },
          { id: 13, name: CoursesList.Latin, prompt: "The study of the Latin language." },
          { id: 14, name: CoursesList.AncientGreek, prompt: "The study of the AncientGreek language." },
          { id: 15, name: CoursesList.Hebrew, prompt: "The study of the Hebrew language." },
          { id: 16, name: CoursesList.GeneralLinguistics, prompt: "The study of the General Linguistics language." },
        ].sort((a, b) => a.name.localeCompare(b.name)),
      },
    ],
  },
  {
    id: 3,
    name: CoursesList?.Extracurriculars,
    prompt: "Various activities outside the regular academic curriculum.",
    className: "Extracurricular",
    subcategories: [
      {
        id: 13,
        name: CoursesList?.Sports,
        prompt: "Physical activities that involve competition and skill.",
        subCategoryItems: [
          { id: 1, name: CoursesList.GeneralFitness, prompt: "The state of health and well-being achieved through physical activity." },
          { id: 2, name: CoursesList.Weightlifting, prompt: "The sport of lifting weights for exercise or competition." },
          { id: 3, name: CoursesList.Wrestling, prompt: "A sport involving grappling and combat techniques." },
          { id: 4, name: CoursesList.Basketball, prompt: "A team sport played on a rectangular court." },
          { id: 5, name: CoursesList.Baseball, prompt: "A bat-and-ball game played between two teams." },
          { id: 6, name: CoursesList.Tennis, prompt: "A racket sport played individually or in doubles." },
          { id: 7, name: CoursesList.Softball, prompt: "A variant of baseball played with a larger ball." },
          { id: 8, name: CoursesList.Lacrosse, prompt: "A team sport played with a small ball and a long-handled stick." },
          { id: 9, name: CoursesList.FieldHockey, prompt: "A team sport played on grass or artificial turf." },
          { id: 10, name: CoursesList.IceHockey, prompt: "A team sport played on ice with skates." },
          { id: 11, name: CoursesList.Volleyball, prompt: "A team sport in which players hit a ball over a net." },
          { id: 12, name: CoursesList.Swimming, prompt: "The sport of moving through water using the body." },
          { id: 13, name: CoursesList.Pickleball, prompt: "A paddle sport that combines elements of tennis, badminton, and ping-pong." },
          { id: 14, name: CoursesList.Cricket, prompt: "A bat-and-ball game played between two teams." },
          { id: 15, name: CoursesList.Badminton, prompt: "A racquet sport played using shuttlecocks." },
          { id: 16, name: CoursesList.Squash, prompt: "A racquet sport played by two or four players." },
          { id: 17, name: CoursesList.Aerobics, prompt: "A form of physical exercise that combines rhythmic exercise." },
          { id: 18, name: CoursesList.Gymnastics, prompt: "A sport involving exercises and performances on various apparatus." },
          { id: 19, name: CoursesList.Yoga, prompt: "A physical and mental practice that includes postures and meditation." },
          { id: 20, name: CoursesList.Football, prompt: "A team sport played between two teams of eleven players." },
          { id: 21, name: CoursesList.Soccer, prompt: "A team sport played with a spherical ball." },
          { id: 22, name: CoursesList.Boxing, prompt: "A combat sport in which two people fight with gloves." },
          { id: 23, name: CoursesList.TrackandField, prompt: "Athletic contests based on running, jumping, and throwing." },
          { id: 24, name: CoursesList.Running, prompt: "The activity of moving at a speed faster than a walk." },
          { id: 25, name: CoursesList.Archery, prompt: "The sport of shooting arrows with a bow." },
          { id: 26, name: CoursesList.Cycling, prompt: "The sport of riding bicycles." },
          { id: 27, name: CoursesList.Fencing, prompt: "A sport involving the use of swords." },
          { id: 28, name: CoursesList.Dance, prompt: "The art of moving the body rhythmically to music." },
        ].sort((a, b) => a.name.localeCompare(b.name)),
      },
      {
        id: 14,
        name: CoursesList?.ClubsAndOrg,
        prompt: "Student-led groups focused on various interests and activities.",
        subCategoryItems: [
          { id: 1, name: CoursesList.SpecialOlympics, prompt: "A global movement to provide sports training and competition for individuals with intellectual disabilities." },
          { id: 2, name: CoursesList.HealthAndWellness, prompt: "Programs focusing on physical and mental health." },
          { id: 3, name: CoursesList.SocialMedia, prompt: "A club focused on the impact of social media." },
          { id: 4, name: CoursesList.ComedyClub, prompt: "A club dedicated to humor and comedy performance." },
          { id: 5, name: CoursesList.BookClub, prompt: "A group of people who meet to discuss books." },
          { id: 6, name: CoursesList.AcademicDecathlon, prompt: "A competition that involves a range of academic subjects." },
          { id: 7, name: CoursesList.ModelUN, prompt: "A simulation of the United Nations for students." },
          { id: 8, name: CoursesList.Newspaper, prompt: "A student-run publication that reports on school events." },
          { id: 9, name: CoursesList.AnimeClub, prompt: "A group dedicated to the discussion and appreciation of anime." },
          { id: 10, name: CoursesList.VideoGameClub, prompt: "A club for students interested in video games." },
          { id: 11, name: CoursesList.Yearbook, prompt: "A student publication that documents the school year." },
          { id: 12, name: CoursesList.StudentLeadership, prompt: "A group focused on developing leadership skills." },
          { id: 13, name: CoursesList.StudentCouncil, prompt: "A student organization that represents the student body." },
          { id: 14, name: CoursesList.MockTrial, prompt: "A simulated court trial for educational purposes." },
          { id: 15, name: CoursesList.Cooking, prompt: "A club focused on culinary skills and cooking." },
          { id: 16, name: CoursesList.EnvironmentalClub, prompt: "A group dedicated to environmental issues." },
          { id: 17, name: CoursesList.CultureHeritageClub, prompt: "A club celebrating diverse cultures and heritages." },
          { id: 18, name: CoursesList.InternationalClub, prompt: "A group focused on international cultures and issues." },
          { id: 19, name: CoursesList.LegoClub, prompt: "A club for building and exploring with LEGO." },
          { id: 20, name: CoursesList.OutdoorClub, prompt: "A club focused on outdoor activities." },
          { id: 21, name: CoursesList.DECA, prompt: "A club focused on entrepreneurship and marketing." },
        ].sort((a, b) => a.name.localeCompare(b.name)),
      },
    ],
  },
  {
    id: 4,
    prompt: "Courses related to business and vocational studies.",
    name: CoursesList.Employment,
    className: "Employment",
    subcategories: [
      {
        id: 15,
        name: CoursesList.Employment,
        prompt: "Courses related to business fundamentals and practices.",
        subCategoryItems: [
          { id: 1, name: CoursesList.Accounting, prompt: "The process of recording financial transactions." },
          { id: 2, name: CoursesList.PersonalFinance, prompt: "Managing personal financial activities." },
          { id: 3, name: CoursesList.Marketing, prompt: "The action of promoting and selling products." },
          { id: 4, name: CoursesList.BusinessManagement, prompt: "The administration of an organization's resources." },
          { id: 5, name: CoursesList.Entrepreneurship, prompt: "The process of starting and operating a new business." },
          { id: 6, name: CoursesList.ConsumerEducation, prompt: "Education about consumer rights and responsibilities." },
          { id: 7, name: CoursesList.BusinessLaw, prompt: "The study of laws that govern business and commercial transactions." },
        ].sort((a, b) => a.name.localeCompare(b.name)),
      },
      {
        id: 16,
        name: CoursesList?.VocationalStudies,
        prompt: "Courses aimed at providing practical skills for various trades.",
        subCategoryItems: [
          { id: 1, name: CoursesList.AutoBodyRepair, prompt: "The repair of vehicle bodywork." },
          { id: 2, name: CoursesList.AutoMechanics, prompt: "The study of automotive repair and maintenance." },
          { id: 3, name: CoursesList.BuildingandConstruction, prompt: "Courses focused on building trades and construction skills." },
          { id: 4, name: CoursesList.Cosmetology, prompt: "The study and application of beauty treatments." },
          { id: 5, name: CoursesList.CriminalJustice, prompt: "The system of practices and institutions for the enforcement of laws." },
          { id: 6, name: CoursesList.DriverEducation, prompt: "Training in safe and effective vehicle operation." },
          { id: 7, name: CoursesList.FutureFarmersofAmerica, prompt: "Programs focused on agricultural education and leadership." },
          { id: 8, name: CoursesList.FireScience, prompt: "The study of fire prevention and safety." },
          { id: 9, name: CoursesList.HeatingandCooling, prompt: "Training in HVAC systems and technologies." },
          { id: 10, name: CoursesList.Systems, prompt: "Courses on various technical systems." },
          { id: 11, name: CoursesList.Plumbing, prompt: "The study of plumbing systems and installation." },
          { id: 12, name: CoursesList.RefrigerationFundamentals, prompt: "Basics of refrigeration technology." },
          { id: 13, name: CoursesList.Woodworking, prompt: "The craft of making items from wood." },
          { id: 14, name: CoursesList.Metalworking, prompt: "The process of working with metal to create objects." },
          { id: 15, name: CoursesList.JROTC, prompt: "Junior Reserve Officer Training Corps program focused on leadership." },
        ].sort((a, b) => a.name.localeCompare(b.name)),
      },
      {
        id: 17,
        name: CoursesList?.FamilyandConsumerSciences,
        prompt: "Courses focused on family, consumer education, and related fields.",
        subCategoryItems: [
          { id: 1, name: CoursesList.CulinaryArts, prompt: "The art of preparing and cooking food." },
          { id: 2, name: CoursesList.HomeEconomics, prompt: "The study of managing the home and family." },
          { id: 3, name: CoursesList.Nutrition, prompt: "The science of food and its impact on health." },
          { id: 4, name: CoursesList.CPR, prompt: "Training in cardiopulmonary resuscitation techniques." },
          { id: 5, name: CoursesList.FashionDesign, prompt: "The art of designing clothing and accessories." },
          { id: 6, name: CoursesList.RetailMarketing, prompt: "The study of marketing strategies in retail." },
          { id: 7, name: CoursesList.ChemistryofFoods, prompt: "Understanding the chemical processes in food." },
          { id: 8, name: CoursesList.EarlyChildhoodDevelopment, prompt: "The study of child development in early years." },
          { id: 9, name: CoursesList.EarlyChildhood, prompt: "Education and care of young children." },
          { id: 10, name: CoursesList.Education, prompt: "The process of facilitating learning." },
          { id: 11, name: CoursesList.FashionConstruction, prompt: "Techniques for creating clothing." },
          { id: 12, name: CoursesList.InteriorDesign, prompt: "The art of designing indoor spaces." },
          { id: 13, name: CoursesList.FamilyStudies, prompt: "The study of family structures and dynamics." },
        ].sort((a, b) => a.name.localeCompare(b.name)),
      },
    ],
  }
]


export const getButtonStyles = (
  selectedItems?: any,
  selectedCategoryName?: string,
  item?: string | null | any
): React.CSSProperties => {
  const isItemSelected = selectedItems?.has(item?.key ? item.key : item);

  return {
    padding: "8px 10px",
    gap: "20px",
    width: "auto",
    backgroundColor:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "rgba(230, 250, 181, 0.49)"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "#B9E6FE"
        : selectedCategoryName === CoursesList.Humanities
        ? "#F4EBFF"
        : selectedCategoryName === CoursesList.Employment
        ? "#FFEAD5"
        : "",
    border:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "1px solid var(--Success-700, #027A48)"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "1px solid var(--Blue-light-600, #0086C9)"
        : selectedCategoryName === CoursesList.Humanities
        ? "1px solid var(--Purple, #5841D9)"
        : selectedCategoryName === CoursesList.Employment
        ? "1px solid var(--Orange, #FEB273)"
        : "",
    borderRadius:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "20px"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "20px"
        : selectedCategoryName === CoursesList.Humanities
        ? "20px"
        : selectedCategoryName === CoursesList.Employment
        ? "20px"
        : "",
    color:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "#027A48"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "black"
        : selectedCategoryName === CoursesList.Humanities
        ? "#5841D9"
        : selectedCategoryName === CoursesList.Employment
        ? "#EC4A0A"
        : "",
    minWidth:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "180px"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "180px"
        : selectedCategoryName === CoursesList.Humanities
        ? "180px"
        : selectedCategoryName === CoursesList.Employment
        ? "180px"
        : "",
    maxWidth:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "350px"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "350px"
        : selectedCategoryName === CoursesList.Humanities
        ? "350px"
        : selectedCategoryName === CoursesList.Employment
        ? "350px"
        : "",
    height:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "45px"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "45px"
        : selectedCategoryName === CoursesList.Humanities
        ? "45px"
        : selectedCategoryName === CoursesList.Employment
        ? "45px"
        : "",
    textTransform: "none",
    justifyContent: "space-around",
    alignItems: "center",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  };
};

export const getSelectedItemsStyles = (
  selectedItems?: any,
  selectedCategoryName?: string,
  item?: string | null | any
): React.CSSProperties => {
  const isItemSelected = selectedItems?.has(item?.key ? item.key : item);

  return {
    padding: "8px 10px",
    gap: "20px",
    width: "auto",
    background:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "#d8eea2"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "var(--Blue-light-300, #7cd4fd)"
        : selectedCategoryName === CoursesList.Humanities
        ? "#bdb4fe"
        : selectedCategoryName === CoursesList.Employment
        ? "var(--Orange-300, #feb273)"
        : "",
    border:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "3px solid var(--Success-700, #027a48)"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "3px solid var(--Blue-light-600, #0086c9)"
        : selectedCategoryName === CoursesList.Humanities
        ? "3px solid var(--Purple, #5841d9)"
        : selectedCategoryName === CoursesList.Employment
        ? "3px solid var(--Orange-600, #c4320a)"
        : "",
    borderRadius:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "20px"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "20px"
        : selectedCategoryName === CoursesList.Humanities
        ? "20px"
        : selectedCategoryName === CoursesList.Employment
        ? "20px"
        : "",
    color:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "#027a48"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "#293056"
        : selectedCategoryName === CoursesList.Humanities
        ? "#5841d9"
        : selectedCategoryName === CoursesList.Employment
        ? "#c4320a"
        : "",
    minWidth:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "180px"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "180px"
        : selectedCategoryName === CoursesList.Humanities
        ? "180px"
        : selectedCategoryName === CoursesList.Employment
        ? "180px"
        : "",
    maxWidth:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "350px"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "350px"
        : selectedCategoryName === CoursesList.Humanities
        ? "350px"
        : selectedCategoryName === CoursesList.Employment
        ? "350px"
        : "",
    height:
      isItemSelected || selectedCategoryName === CoursesList.STEM
        ? "45px"
        : selectedCategoryName === CoursesList.Extracurriculars
        ? "45px"
        : selectedCategoryName === CoursesList.Humanities
        ? "45px"
        : selectedCategoryName === CoursesList.Employment
        ? "45px"
        : "",
    textTransform: "none",
    justifyContent: "space-around",
    alignItems: "center",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  };
};

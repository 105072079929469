import { Button, Icon } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  leftnavlogo,
  lock,
  search,
  settings,
  star,
  threelayers,
  user,
} from "src/assets";
import { DrawerEnum, StorageEnum } from "src/enums/AppLables.enum";
import { RouterPath } from "src/enums/RouterPath.enum";
import { avatarsGroup } from "src/enums/avatars.enum";
import { selectedFavorites } from "src/store/app.selector";
import { getSessionStorage } from "src/utils/Cookie.util";
import { useEvent } from "./EventProvider";
import "./NavigationComponent.styles.scss";

export const NavigationComponent = () => {
  const userAvatarID = getSessionStorage(StorageEnum.UserAvatar);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isButtonEnabled, setButtonEnabled] = React.useState(false);
  const username = getSessionStorage(StorageEnum.LoggedInUsername);
  const { triggerEvent } = useEvent();
  const { eventData } = useEvent();
  const favData = useSelector(selectedFavorites);

  React.useEffect(() => {
    const checkRoute = () => {
      if (location.pathname.includes("/generatemajor")) {
        setButtonEnabled(true);
      } else {
        setButtonEnabled(false);
      }

      if (location.pathname.includes("/profilepage")) {
        makeActive("profile");
      }
      if (location.pathname.includes("/findmajorsmanualsearch")) {
        makeActive("findMajors");
      }
      if (location.pathname.includes("/findmajorssearchmode")) {
        makeActive("findMajors");
      }
      if (location.pathname.includes("/findmajors")) {
        makeActive("findMajors");
      }
    };

    checkRoute();
  }, [location]);

  const setActive = (currentEle: HTMLElement) => {
    currentEle?.classList.add("menu-item-active");
  };

  const makeActive = (id: any) => {
    const elements = document.getElementsByClassName("menu-item");

    for (let i = 0; i < elements?.length; i++) {
      elements[i].classList.remove("menu-item-active");
    }

    const currentElement = document.getElementById(id);

    if (currentElement) {
      setActive(currentElement);
    }
  };

  const navigateToHomePage = () => {
    makeActive("findMajors");
    navigate(RouterPath.FindMajorsSearchMode);
  };

  const handleSettingsClick = () => {
    setIsSettingsDrawerOpen(!isSettingsDrawerOpen);
    navigate(RouterPath.ProfilePage);
    setTimeout(() => {
      makeActive("profile");
    }, 200);
  };
  const handleProfileSettings = () => {
    makeActive("profile");
    navigate(RouterPath.ProfilePage);
  };

  const handleFavorties = () => {
    makeActive("favorites");
    navigate(RouterPath.MenuFavorites);
  };

  const handleSubFavorties = () => {
    makeActive("subfavorites");
    navigate(RouterPath.Favorties);
  };

  const handleSearchHistory = () => {
    makeActive("searchhistory");
    navigate(RouterPath.SearchHistory);
  };

  const handleSubSearchHistory = () => {
    makeActive("subsearchhistory");
    navigate(RouterPath.SubSearchHistory);
  };

  const handleMainDrawerClose = () => {
    setIsSettingsDrawerOpen(false);
    navigateToHomePage();
  };

  const handleFindMajorsPage = () => {
    makeActive("findMajors");
    navigate(RouterPath.FindMajorsSearchMode);
  };

  const handleAddedCourses = () => {
    makeActive("findMajors");
    triggerEvent({ message: "Event from Navigation" });
    navigate(RouterPath.FindMajorsSearchMode);
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate(RouterPath.Login);
  };

  React.useEffect(() => {
    if (eventData?.message === "Event from ProfilePage") {
      handleMainDrawerClose();
    }
  }, [eventData]);

  const handleSettingsDrawer = () => {
    if (
      isSettingsDrawerOpen ||
      location?.pathname === "/home/favorties" ||
      location?.pathname === "/home/profilepage"
    ) {
      return DrawerEnum?.permanent;
    } else {
      return DrawerEnum?.temporary;
    }
  };

  React.useEffect(() => {
    if (location?.pathname === "/home/favorties") {
      makeActive("subfavorites");
    } else if (location?.pathname === "/home/searchhistory") {
      makeActive("searchhistory");
    }
  }, [location]);

  React.useEffect(() => {
    if (location?.pathname === "/home/menufavorites") {
      makeActive("favorites");
    } else if (location?.pathname === "/home/menusearchhistory") {
      makeActive("searchhistory");
    }
  }, [location]);

  const userAvatarSrc = avatarsGroup?.find(
    (item: any) => item?.id == userAvatarID
  )?.src;

  const settingsDrawer = (
    <Drawer
      variant={handleSettingsDrawer()}
      anchor="left"
      open={
        isSettingsDrawerOpen || location?.pathname === "/home/profilepage"
          ? true
          : false || location?.pathname === "/home/favorties"
          ? true
          : false
      }
      onClose={handleSettingsClick}
    >
      <div className="settings-section">
        <Button onClick={handleMainDrawerClose} className="settings-btn-sec">
          ← Settings
        </Button>
      </div>
      <div className="settingsDrawer">
        <div className="menu-item" id="profile">
          <ListItem disablePadding onClick={handleProfileSettings}>
            <ListItemButton>
              <ListItemIcon className="user-icon">
                {" "}
                <img src={user} alt=""></img>
              </ListItemIcon>
              <ListItemText primary={"Profile"} className="profile-header" />
            </ListItemButton>
          </ListItem>
        </div>
        <div className="menu-item" id="subfavorites">
          <ListItem disablePadding onClick={handleSubFavorties}>
            <ListItemButton>
              <ListItemIcon className="star-icon">
                {" "}
                <img src={star} alt=""></img>
              </ListItemIcon>
              <ListItemText
                primary={"Favorites"}
                className="favorites-header"
              />
            </ListItemButton>
          </ListItem>
        </div>
        <div className="menu-item" id="subsearchhistory">
          <ListItem disablePadding onClick={handleSubSearchHistory}>
            <ListItemButton>
              <ListItemIcon className="search-icon">
                {" "}
                <img src={search} alt=""></img>
              </ListItemIcon>
              <ListItemText
                primary={"Search History"}
                className="search-header"
              />
            </ListItemButton>
          </ListItem>
        </div>
      </div>
    </Drawer>
  );

  const drawer = (
    <>
      <Toolbar />
      <div className="college-application-nav-header">
        <Icon className="nav-collgecrafticon">
          <img src={leftnavlogo} alt="not found" />
        </Icon>

        <Typography
          component="h1"
          variant="h5"
          className="collegecraft-nav-headername"
        >
          CollegeCrafter
        </Typography>
      </div>
      <div className="left-navigation-content">
        <div className="left-navigation-first-section">
          <div className="menu-item" id="findMajors">
            <ListItem disablePadding onClick={handleFindMajorsPage}>
              <ListItemButton>
                <ListItemIcon className="threelayers-icon">
                  {" "}
                  <img src={threelayers} alt=""></img>
                </ListItemIcon>
                <ListItemText
                  primary={"Find Majors"}
                  className="find-majors-header"
                />
              </ListItemButton>
            </ListItem>
          </div>
        </div>
        <Divider className="divider-element" />
        <div className="left-navigation-second-section">
          <div>
            <div className="nav-add-btn-section">
              {isButtonEnabled ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() => handleAddedCourses()}
                  className="add-btn-section"
                >
                  <span className="add-btn-header">{"+ Add New"}</span>
                </Button>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="add-btn-section-disabled"
                >
                  <span className="add-btn-header">{"+ Add New"}</span>
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="left-navigation-third-section">
          <Divider className="divider-element" />
          <div className="avatar-section">
            <div className="img-section">
              <img src={userAvatarSrc} className="avatar-image" alt="" />
            </div>
            <div className="avatar-header-section">
              <Typography className="avatar-header-text">{username}</Typography>
            </div>
          </div>
          <div className="menu-item" id="settings">
            <ListItem disablePadding onClick={handleSettingsClick}>
              <ListItemButton>
                <ListItemIcon className="settings-icon">
                  {" "}
                  <img src={settings} alt=""></img>
                </ListItemIcon>
                <ListItemText
                  primary={"Settings"}
                  className="settings-header"
                />
              </ListItemButton>
            </ListItem>
          </div>
          <div className="menu-item" id="favorites">
            <ListItem disablePadding onClick={handleFavorties}>
              <ListItemButton>
                <ListItemIcon className="star-icon">
                  {" "}
                  <img src={star} alt=""></img>
                </ListItemIcon>

                <ListItemText
                  primary={"Favorites"}
                  className="favorites-header"
                />
              </ListItemButton>
              <div className="added-favorites-section">
                <span className="favorites-count">{favData?.length}</span>
              </div>
            </ListItem>
          </div>
          <div className="menu-item" id="searchhistory">
            <ListItem disablePadding onClick={handleSearchHistory}>
              <ListItemButton>
                <ListItemIcon className="search-icon">
                  {" "}
                  <img src={search} alt=""></img>
                </ListItemIcon>
                <ListItemText
                  primary={"Search History"}
                  className="search-header"
                />
              </ListItemButton>
            </ListItem>
          </div>
          <div className="menu-item" id="logout">
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon className="lock-icon">
                  {" "}
                  <img src={lock} alt=""></img>
                </ListItemIcon>
                <ListItemText
                  primary={"Logout"}
                  className="logout-header"
                  onClick={logout}
                />
              </ListItemButton>
            </ListItem>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div className="left-naviagtion-section">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer variant="permanent" open>
          {drawer}
          {settingsDrawer}
        </Drawer>
      </Box>
    </div>
  );
};

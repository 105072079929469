export enum CoursesList {
    STEM='STEM',
    Science='Science',
    Agriculture='Agriculture',
    Astronomy="Astronomy",
    Botany="Botany",
    Biology='Biology',
    Chemistry='Chemistry',
    EarthScience="Earth Science",
    EnvironmentalScience="Environmental Science",
    ForensicScience="Forensic Science",
    Geology="Geology",
    Genetics="Genetics",
    MarineBiology="Marine Biology",
    Anatomy="Anatomy",
    PhysicalScience="Physical Science",
    Physics='Physics',
    Zoology="Zoology",
    Neuroscience="Neuroscience",
    Epidemiology ="Epidemiology ",
    Medicine="Medicine",
    Nursing='Nursing',
    Dentistry="Dentistry",
    Pediatrics='Pediatrics',
    SpeechLanguagePathology ="Speech-Language Pathology",
    OccupationalTherapy="Occupational Therapy",
    Anesthesiology="Anesthesiology",
    Veterinary="Veterinary",
    Surgery='Surgery',
    PhysicalTherapy="Physical Therapy",
    Psychiatry="Psychiatry",
    Optometry="Optometry",
    Gynecology="Gynecology",
    Pharmacy="Pharmacy",
    Podiatry="Podiatry",
    Orthodontics="Orthodontics",
    Chiropractic="Chiropractic",
    Cardiology="Cardiology",
    Dermatology='Dermatology',
    EmergencyMedicine='Emergency Medicine',
    ForensicPathology='Forensic Pathology',
    Neurology='Neurology',    
    PlasticSurgery="Plastic Surgery",
    Radiology="Radiology",
    
    
    

    Technology='Technology',
    Animation="Animation",
    ComputerScience="Computer Science",
    Coding="Coding",
    WebDesign="Web Design",
    GraphicDesign="Graphic Design",
    TypingKeyboarding="Typing/Keyboarding",
    AppDevelopment="App Development",
    MediaDesign="Media Design",
    AudioMusicProduction="Audio/Music Production",
    ComputerRepair="Computer Repair",
    WebProgramming="Web Programming",
    WordProcessing ="Word Processing",


    Engineering='Engineering',
    DPrinting="3D Printing",
    ComputerAidedDesign="Computer Aided Design",
    Robotics="Robotics",
    Electronics="Electronics",
    ArchitectureDesign="Architecture Design",
    CivilEngineering="Civil Engineering",
    MechanicalEngineering="Mechanical Engineering",

    Mathematics='Math',
    Algebra='Algebra',
    PreAlgebra="Pre-Algebra",
    Calculus='Calculus',
    PreCalculus="Pre-Calculus",
    Geometry='Geometry',
    Trigonometry="Trigonometry",
    Statistics="Statistics",
    IntegratedMath="Integrated Math",
    FundamentalsofMath="Fundamentals of Math",
    ConsumerMath="Consumer Math",
    ComputerMath="Computer Math",
    MathApplications="Math Applications",
    MultivariableCalculus ="Multivariable Calculus",
    PracticalMath="Practical Math",
    QuantitativeLiteracy="Quantitative Literacy",
    Probability="Probability",

    Humanities='Humanities',
    EnglishLanguageArts="English Language Arts",
    AmericanLiterature="American Literature",
    Debate="Debate",
    Poetry="Poetry",
    Composition="Composition",
    Rhetoric="Rhetoric",
    Literature='Literature',
    Journalism="Journalism",
    History='History',
    Philosophy='Philosophy',
    CreativeWriting="Creative Writing",
    WorldLiterature="World Literature",
    ComparativeLiterature="Comparative Literature",
    LiteraryAnalysis="Literary Analysis",
    TechnicalWriting="Technical Writing",
    Communication="Communication",
    LiteratureandComposition="Literature and Composition",
    LanguageandComposition="Language and Composition",
    
    Arts="Arts",
    ChorusChoir="Chorus/Choir",
    MarchingBand="Marching Band",
    JazzBand="Jazz Band",
    Piano="Piano",
    VideoProductionFilmmaking ="Video Production/Filmmaking",
    MusicalTheater="Musical Theater",
    Drama="Drama",
    FiberArts="Fiber Arts",
    Ceramics="Ceramics",
    Drawing="Drawing",
    Painting="Painting",
    DigitalArt="Digital Art",
    Photography="Photography",
    MusicTheory="Music Theory",
    PerformanceArts="Performance Arts",
    ArtHistory="Art History",
    Orchestra="Orchestra",
    Percussion="Percussion",
    
    SocialStudies="Social Studies",
    WorldHistory="World History",
    Geography="Geography",
    CivicsandGovernment="Civics and Government",
    EuropeanHistory="European History",
    InternationalRelations="International Relations",
    WomensStudies="Womens Studies",
    ReligiousStudies="Religious Studies",
    Sociology="Sociology",
    Anthropology="Anthropology",
    CurrentEvents="Current Events",
    PoliticalScience="Political Science",
    WorldReligions="World Religions",
    Economics ="Economics",
    AmericanHistory="American History",
    ComparativeGovernment="Comparative Government",
    Law="Law",
    Psychology="Psychology",

    ForeignLanguages ="Foreign Languages",
    Spanish="Spanish",
    Chinese="Chinese",
    Japanese="Japanese",
    Italian="Italian",
    French="French",
    Russian="Russian",
    German="German",
    Hindi="Hindi",
    ASL="ASL",
    Korean="Korean",
    Arabic="Arabic",
    Portuguese="Portuguese",
    Latin="Latin",
    AncientGreek="Ancient Greek",
    Hebrew="Hebrew",
    GeneralLinguistics="General Linguistics",



    Employment='Employment',
    Accounting="Accounting",
    PersonalFinance="Personal Finance",
    Marketing="Marketing",
    BusinessManagement="Business Management",
    Entrepreneurship="Entrepreneurship",
    ConsumerEducation="Consumer Education",
    BusinessLaw="Business Law",

    VocationalStudies="Vocational Studies",
    AutoBodyRepair="AutoBodyRepair",
    AutoMechanics="Auto Mechanics",
    BuildingandConstruction="Building and Construction",
    Cosmetology="Cosmetology",
    CriminalJustice="Criminal Justice",
    DriverEducation="Driver Education",
    FutureFarmersofAmerica="Future Farmers of America",
    FireScience="Fire Science",
    HeatingandCooling ="Heating and Cooling ",
    Systems="Systems",
    Plumbing="Plumbing",
    RefrigerationFundamentals="Refrigeration Fundamentals",
    Woodworking="Woodworking",
    Metalworking ="Metalworking",
    JROTC="JROTC",

    FamilyandConsumerSciences ="Family and Consumer Sciences",
    CulinaryArts="Culinary Arts",
    HomeEconomics="Home Economics",
    Nutrition="Nutrition",
    CPR="CPR",
    FashionDesign="Fashion Design",
    RetailMarketing="Retail Marketing",
    ChemistryofFoods="Chemistry of Foods",
    EarlyChildhoodDevelopment="Early Childhood Development",
    EarlyChildhood ="Early Childhood ",
    Education ="Education",
    FashionConstruction="Fashion Construction",
    InteriorDesign="Interior Design",
    FamilyStudies="Family Studies",


    Extracurriculars= "Extracurriculars",
    Sports = 'Sports',
    GeneralFitness = 'General Fitness',
    Weightlifting = 'Weightlifting',
    Wrestling = 'Wrestling',
    Basketball = 'Basketball',
    Baseball = 'Baseball',
    Tennis = 'Tennis',
    Softball = 'Softball',
    Lacrosse = 'Lacrosse',
    FieldHockey = 'Field Hockey',
    IceHockey = 'Ice Hockey',
    Volleyball = 'Volleyball',
    Swimming = 'Swimming',
    Pickleball = 'Pickleball',
    Cricket = 'Cricket',
    Badminton = 'Badminton',
    Squash = 'Squash',
    Aerobics = 'Aerobics',
    Gymnastics = 'Gymnastics',
    Yoga = 'Yoga',
    Football = 'Football',
    Soccer = 'Soccer',
    Boxing = 'Boxing',
    TrackandField = 'Track and Field',
    Running = 'Running',
    Archery = 'Archery',
    Cycling = 'Cycling',
    Fencing = 'Fencing',
    Dance = 'Dance',

    ClubsAndOrg = 'Clubs and Org',
    SpecialOlympics = 'Special Olympics',
    HealthAndWellness = 'Health and Wellness',
    SocialMedia = 'Social Media',
    ComedyClub = 'Comedy Club',
    BookClub = 'Book Club',
    AcademicDecathlon = 'Academic Decathlon',
    ModelUN = 'Model UN',
    Newspaper = 'Newspaper',
    AnimeClub = 'Anime Club',
    VideoGameClub = 'Video Game Club',
    Yearbook = 'Yearbook',
    StudentLeadership = 'Student Leadership',
    StudentCouncil = 'Student Council',
    MockTrial = 'Mock Trial',
    Cooking = 'Cooking',
    EnvironmentalClub = 'Environmental Club',
    CultureHeritageClub = 'Culture/Heritage Club',
    InternationalClub = 'International Club',
    LegoClub = 'Lego Club',
    OutdoorClub = 'Outdoor Club',
    DECA = 'DECA',


  };

  

  export enum GenderNamesEnums{
    Male ='Male',
    Female = 'Female',
    Others ='Others',
    nonbinary='non-binary',
    prefernottosay='prefer not to say'
  }

  export enum GenderIdEnums{
    Male=1,
    Female=2,
    nonbinary=3,
    prefernottosay=4,
    Others=5
  }

  export enum RaceEthnicityNamesEnum {
    AfricanAmerican = "African American",
    AlaskaNative = "Alaska Native",
    Asian = "Asian",
    EastAsian = "East Asian",
    SouthAsian = "South Asian",
    Black = "Black",
    Hispanic = "Hispanic",
    LatinX = "Latin(x)",
    MiddleEastern = "Middle Eastern",
    NativeAmerican = "Native American",
    PacificIslander = "Pacific Islander",
    White = "White",
    PreferNotToSay = "Prefer not to say",
    Others = "Others",
  }

  export enum RaceEthnicityIdEnum {
    AfricanAmerican = 1,
    AlaskaNative = 2,
    Asian = 3,
    EastAsian = 4,
    SouthAsian = 5,
    Black = 6,
    Hispanic = 7,
    LatinX = 8,
    MiddleEastern = 9,
    NativeAmerican = 10,
    PacificIslander = 11,
    White = 12,
    PreferNotToSay = 13,
    Others = 14,
  }
  
  export enum USStatesNames  {
    Alabama = 'Alabama',
    Alaska = 'Alaska',
    Arizona = 'Arizona',
    Arkansas = 'Arkansas',
    California = 'California',
    Colorado = 'Colorado',
    Connecticut = 'Connecticut',
    Delaware = 'Delaware',
    Florida = 'Florida',
    Georgia = 'Georgia',
    Hawaii = 'Hawaii',
    Idaho = 'Idaho',
    Illinois = 'Illinois',
    Indiana = 'Indiana',
    Iowa = 'Iowa',
    Kansas = 'Kansas',
    Kentucky = 'Kentucky',
    Louisiana = 'Louisiana',
    Maine = 'Maine',
    Maryland = 'Maryland',
    Massachusetts = 'Massachusetts',
    Michigan = 'Michigan',
    Minnesota = 'Minnesota',
    Mississippi = 'Mississippi',
    Missouri = 'Missouri',
    Montana = 'Montana',
    Nebraska = 'Nebraska',
    Nevada = 'Nevada',
    NewHampshire = 'New Hampshire',
    NewJersey = 'New Jersey',
    NewMexico = 'New Mexico',
    NewYork = 'New York',
    NorthCarolina = 'North Carolina',
    NorthDakota = 'North Dakota',
    Ohio = 'Ohio',
    Oklahoma = 'Oklahoma',
    Oregon = 'Oregon',
    Pennsylvania = 'Pennsylvania',
    RhodeIsland = 'Rhode Island',
    SouthCarolina = 'South Carolina',
    SouthDakota = 'South Dakota',
    Tennessee = 'Tennessee',
    Texas = 'Texas',
    Utah = 'Utah',
    Vermont = 'Vermont',
    Virginia = 'Virginia',
    Washington = 'Washington',
    WestVirginia = 'West Virginia',
    Wisconsin = 'Wisconsin',
    Wyoming = 'Wyoming'
  };
  

  export enum USStatesIDs  {
    Alabama = 1,
    Alaska = 2,
    Arizona = 3,
    Arkansas = 4,
    California = 5,
    Colorado = 6,
    Connecticut = 7,
    Delaware = 8,
    Florida = 9,
    Georgia = 10,
    Hawaii = 11,
    Idaho = 12,
    Illinois = 13,
    Indiana = 14,
    Iowa = 15,
    Kansas = 16,
    Kentucky = 17,
    Louisiana = 18,
    Maine = 19,
    Maryland = 20,
    Massachusetts = 21,
    Michigan = 22,
    Minnesota = 23,
    Mississippi = 24,
    Missouri = 25,
    Montana = 26,
    Nebraska = 27,
    Nevada = 28,
    NewHampshire = 29,
    NewJersey = 30,
    NewMexico = 31,
    NewYork = 32,
    NorthCarolina = 33,
    NorthDakota = 34,
    Ohio = 35,
    Oklahoma = 36,
    Oregon = 37,
    Pennsylvania = 38,
    RhodeIsland = 39,
    SouthCarolina = 40,
    SouthDakota = 41,
    Tennessee = 42,
    Texas = 43,
    Utah = 44,
    Vermont = 45,
    Virginia = 46,
    Washington = 47,
    WestVirginia = 48,
    Wisconsin = 49,
    Wyoming = 50
  };
  
  

  export enum SuggestedCourses{
    Agriculture='Agriculture',
    Astronomy="Astronomy",
    Botany="Botany",
    Biology='Biology',
    Chemistry='Chemistry',
    EarthScience="Earth Science",
    EnvironmentalScience="Environmental Science",
    ForensicScience="Forensic Science",
    Geology="Geology",
    Genetics="Genetics",
    MarineBiology="Marine Biology",
    Anatomy="Anatomy",
    PhysicalScience="Physical Science",
    Physics='Physics',
    Zoology="Zoology",
    Neuroscience="Neuroscience",
    Epidemiology ="Epidemiology ",
    Medicine="Medicine",
    Nursing='Nursing',
    Dentistry="Dentistry",
    Pediatrics='Pediatrics',
    SpeechLanguagePathology ="Speech-Language Pathology",
    OccupationalTherapy="Occupational Therapy",
    Anesthesiology="Anesthesiology",
    Veterinary="Veterinary",
    Surgery='Surgery',
    PhysicalTherapy="Physical Therapy",
    Psychiatry="Psychiatry",
    Optometry="Optometry",
    Gynecology="Gynecology",
    Pharmacy="Pharmacy",
    Podiatry="Podiatry",
    Orthodontics="Orthodontics",
    Chiropractic="Chiropractic",
    Cardiology="Cardiology",
    Dermatology='Dermatology',
    EmergencyMedicine='Emergency Medicine',
    ForensicPathology='Forensic Pathology',
    Neurology='Neurology',    
    PlasticSurgery="Plastic Surgery",
    Radiology="Radiology",
    Animation="Animation",
    ComputerScience="Computer Science",
    Coding="Coding",
    WebDesign="Web Design",
    GraphicDesign="Graphic Design",
    TypingKeyboarding="Typing/Keyboarding",
    AppDevelopment="App Development",
    MediaDesign="Media Design",
    AudioMusicProduction="Audio/Music Production",
    ComputerRepair="Computer Repair",
    WebProgramming="Web Programming",
    WordProcessing ="Word Processing",
    DPrinting="3D Printing",
    Robotics="Robotics",
    Electronics="Electronics",
    ArchitectureDesign="Architecture Design",
    CivilEngineering="Civil Engineering",
    MechanicalEngineering="Mechanical Engineering",
    Mathematics='Math',
    Algebra='Algebra',
    PreAlgebra="Pre-Algebra",
    Calculus='Calculus',
    PreCalculus="Pre-Calculus",
    Geometry='Geometry',
    Trigonometry="Trigonometry",
    Statistics="Statistics",
    IntegratedMath="Integrated Math",
    FundamentalsofMath="Fundamentals of Math",
    MathApplications="Math Applications",
    MultivariableCalculus ="Multivariable Calculus",
    PracticalMath="Practical Math",
    QuantitativeLiteracy="Quantitative Literacy",
    Probability="Probability",
    EnglishLanguageArts="English Language Arts",
    AmericanLiterature="American Literature",
    Debate="Debate",
    Poetry="Poetry",
    Rhetoric="Rhetoric",
    Literature='Literature',
    Journalism="Journalism",
    History='History',
    Philosophy='Philosophy',
    WorldLiterature="World Literature",
    LiteraryAnalysis="Literary Analysis",
    TechnicalWriting="Technical Writing",
    Communication="Communication",
    LiteratureandComposition="Literature and Composition",
    LanguageandComposition="Language and Composition",
    Arts="Arts",
    ChorusChoir="Chorus/Choir",
    MarchingBand="Marching Band",
    JazzBand="Jazz Band",
    Piano="Piano",
    VideoProductionFilmmaking ="Video Production/Filmmaking",
    MusicalTheater="Musical Theater",
    Drama="Drama",
    FiberArts="Fiber Arts",
    Ceramics="Ceramics",
    Drawing="Drawing",
    Painting="Painting",
    DigitalArt="Digital Art",
    Photography="Photography",
    MusicTheory="Music Theory",
    PerformanceArts="Performance Arts",
    ArtHistory="Art History",
    Orchestra="Orchestra",
    Percussion="Percussion",
    SocialStudies="Social Studies",
    WorldHistory="World History",
    Geography="Geography",
    EuropeanHistory="European History",
    InternationalRelations="International Relations",
    WomensStudies="Womens Studies",
    ReligiousStudies="Religious Studies",
    Sociology="Sociology",
    Anthropology="Anthropology",
    PoliticalScience="Political Science",
    WorldReligions="World Religions",
    Economics ="Economics",
    AmericanHistory="American History",
    ComparativeGovernment="Comparative Government",
    Law="Law",
    Psychology="Psychology",
    ForeignLanguages ="Foreign Languages",
    Spanish="Spanish",
    Chinese="Chinese",
    Japanese="Japanese",
    Italian="Italian",
    French="French",
    Russian="Russian",
    German="German",
    Hindi="Hindi",
    ASL="ASL",
    Korean="Korean",
    Arabic="Arabic",
    Portuguese="Portuguese",
    Latin="Latin",
    AncientGreek="Ancient Greek",
    Hebrew="Hebrew",
    GeneralLinguistics="General Linguistics",
    Accounting="Accounting",
    PersonalFinance="Personal Finance",
    Marketing="Marketing",
    BusinessManagement="Business Management",
    Entrepreneurship="Entrepreneurship",
    ConsumerEducation="Consumer Education",
    BusinessLaw="Business Law",
    VocationalStudies="Vocational Studies",
    AutoBodyRepair="AutoBodyRepair",
    AutoMechanics="Auto Mechanics",
    BuildingandConstruction="Building and Construction",
    DriverEducation="Driver Education",
    FutureFarmersofAmerica="Future Farmers of America",
    FireScience="Fire Science",
    HeatingandCooling ="Heating and Cooling ",
    Systems="Systems",
    Plumbing="Plumbing",
    RefrigerationFundamentals="Refrigeration Fundamentals",
    Woodworking="Woodworking",
    Metalworking ="Metalworking",
    JROTC="JROTC",
    FamilyandConsumerSciences ="Family and Consumer Sciences",
    CulinaryArts="Culinary Arts",
    HomeEconomics="Home Economics",
    Nutrition="Nutrition",
    FashionDesign="Fashion Design",
    RetailMarketing="Retail Marketing",
    ChemistryofFoods="Chemistry of Foods",
    EarlyChildhoodDevelopment="Early Childhood Development",
    EarlyChildhood ="Early Childhood ",
    Education ="Education",
    FashionConstruction="Fashion Construction",
    InteriorDesign="Interior Design",
    FamilyStudies="Family Studies",
    Sports = 'Sports',
    GeneralFitness = 'General Fitness',
    Weightlifting = 'Weightlifting',
    Wrestling = 'Wrestling',
    Basketball = 'Basketball',
    Baseball = 'Baseball',
    Tennis = 'Tennis',
    Softball = 'Softball',
    Lacrosse = 'Lacrosse',
    FieldHockey = 'Field Hockey',
    IceHockey = 'Ice Hockey',
    Volleyball = 'Volleyball',
    Swimming = 'Swimming',
    Pickleball = 'Pickleball',
    Cricket = 'Cricket',
    Badminton = 'Badminton',
    Squash = 'Squash',
    Aerobics = 'Aerobics',
    Gymnastics = 'Gymnastics',
    Yoga = 'Yoga',
    Football = 'Football',
    Soccer = 'Soccer',
    Boxing = 'Boxing',
    TrackandField = 'Track and Field',
    Running = 'Running',
    Archery = 'Archery',
    Cycling = 'Cycling',
    Fencing = 'Fencing',
    Dance = 'Dance',
    ClubsAndOrg = 'Clubs and Org',
    SpecialOlympics = 'Special Olympics',
    HealthAndWellness = 'Health and Wellness',
    SocialMedia = 'Social Media',
    ComedyClub = 'Comedy Club',
    BookClub = 'Book Club',
    AcademicDecathlon = 'Academic Decathlon',
    ModelUN = 'Model UN',
    Newspaper = 'Newspaper',
    AnimeClub = 'Anime Club',
    VideoGameClub = 'Video Game Club',
    Yearbook = 'Yearbook',
    StudentLeadership = 'Student Leadership',
    StudentCouncil = 'Student Council',
    MockTrial = 'Mock Trial',
    Cooking = 'Cooking',
    EnvironmentalClub = 'Environmental Club',
    CultureHeritageClub = 'Culture/Heritage Club',
    InternationalClub = 'International Club',
    LegoClub = 'Lego Club',
    OutdoorClub = 'Outdoor Club',
    DECA = 'DECA',
    Urology = 'Urology',
    UXDesign = 'UX Design',
    XRayTechnology = 'X-ray Technology',
    Xenobiology = 'Xenobiology',
  }
 
import Typography from "@mui/material/Typography";

import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { group71 } from "src/assets";
import { SignupButtonsLabel } from "src/enums/AppButtonEnums.enum";
import { SignupErrorsEnum } from "src/enums/AppErrorsEnum.enum";
import { SignupLabels, StorageEnum } from "src/enums/AppLables.enum";
import { SignupPlaceholders } from "src/enums/AppPlaceholderEnums.enum";
import {
  GenderIdEnums,
  GenderNamesEnums,
  RaceEthnicityIdEnum,
  RaceEthnicityNamesEnum,
  USStatesIDs,
  USStatesNames,
} from "src/enums/Courses.enum";
import { RouterPath } from "src/enums/RouterPath.enum";
import {
  StringConstant,
  emailRegex,
  passwordRegex,
} from "src/enums/StringConstant";
import { avatarsGroup } from "src/enums/avatars.enum";
import {
  createUserProfileInfo,
  deleteUserProfileInfo,
  getPersonalInfoRequest,
  getProfileInfoRequest,
  updateUserPersonalInfo,
  updateUserProfileInfo,
} from "src/services/account/Account.service";
import { isEmpty } from "src/utils/App.util";
import { SetSessionStorage, getSessionStorage } from "src/utils/Cookie.util";
import { CountriesList } from "../../../src/services/countries/Countries.service";
import { SettingsBackgroundImage } from "../SettingsProfileBackgroundimage";
import { Loader } from "../loader/Loader";
import { useEvent } from "../navigation/EventProvider";
import { CustomPopper } from "./CustomPopper";
import { ProfileBackground } from "./ProfileBackground";
import "./ProfilePage.styles.scss";

export const ProfilePage = () => {
  const loggedInUserAvatarID: number = Number(
    getSessionStorage(StorageEnum.UserAvatar)
  );

  const [personalInfo, setPersonalInfo] = useState<any>(null);
  const [selectedAvatarID, setSelectedAvatarID] =
    useState<number>(loggedInUserAvatarID);
  const [updatePersonalInfo, setUpdatePersonalInfo] = useState<any>(null);
  const [profileInfo, setProfileInfo] = useState<any>(null);
  const [updateProfilelInfo, setUpdateProfileInfo] = useState<any>(null);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate();
  const userEmail: any = getSessionStorage(StorageEnum.LoggedInUserEmail);
  const { triggerEvent } = useEvent();
  const avatarsRows = [];

  const genders = [
    { id: GenderIdEnums.Male, name: GenderNamesEnums.Male },
    { id: GenderIdEnums.Female, name: GenderNamesEnums.Female },
    { id: GenderIdEnums.Others, name: GenderNamesEnums.Others },
    { id: GenderIdEnums.prefernottosay, name: GenderNamesEnums.prefernottosay },
    { id: GenderIdEnums.nonbinary, name: GenderNamesEnums.nonbinary },
  ];

  const raceEthnicityOptions: { id: number | null; race: String }[] = [
    {
      id: RaceEthnicityIdEnum.AfricanAmerican,
      race: RaceEthnicityNamesEnum.AfricanAmerican,
    },
    {
      id: RaceEthnicityIdEnum.AlaskaNative,
      race: RaceEthnicityNamesEnum.AlaskaNative,
    },
    { id: RaceEthnicityIdEnum.Asian, race: RaceEthnicityNamesEnum.Asian },
    {
      id: RaceEthnicityIdEnum.EastAsian,
      race: RaceEthnicityNamesEnum.EastAsian,
    },
    {
      id: RaceEthnicityIdEnum.SouthAsian,
      race: RaceEthnicityNamesEnum.SouthAsian,
    },
    { id: RaceEthnicityIdEnum.Black, race: RaceEthnicityNamesEnum.Black },
    {
      id: RaceEthnicityIdEnum.Hispanic,
      race: RaceEthnicityNamesEnum.Hispanic,
    },
    { id: RaceEthnicityIdEnum.LatinX, race: RaceEthnicityNamesEnum.LatinX },
    {
      id: RaceEthnicityIdEnum.MiddleEastern,
      race: RaceEthnicityNamesEnum.MiddleEastern,
    },
    {
      id: RaceEthnicityIdEnum.NativeAmerican,
      race: RaceEthnicityNamesEnum.NativeAmerican,
    },
    {
      id: RaceEthnicityIdEnum.PacificIslander,
      race: RaceEthnicityNamesEnum.PacificIslander,
    },
    { id: RaceEthnicityIdEnum.White, race: RaceEthnicityNamesEnum.White },
    {
      id: RaceEthnicityIdEnum.PreferNotToSay,
      race: RaceEthnicityNamesEnum.PreferNotToSay,
    },
    { id: RaceEthnicityIdEnum.Others, race: RaceEthnicityNamesEnum.Others },
  ];

  const listOfStates = [
    { id: USStatesIDs.Alabama, name: USStatesNames.Alabama },
    { id: USStatesIDs.Alaska, name: USStatesNames.Alaska },
    { id: USStatesIDs.Arizona, name: USStatesNames.Arizona },
    { id: USStatesIDs.Arkansas, name: USStatesNames.Arkansas },
    { id: USStatesIDs.California, name: USStatesNames.California },
    { id: USStatesIDs.Colorado, name: USStatesNames.Colorado },
    { id: USStatesIDs.Connecticut, name: USStatesNames.Connecticut },
    { id: USStatesIDs.Delaware, name: USStatesNames.Delaware },
    { id: USStatesIDs.Florida, name: USStatesNames.Florida },
    { id: USStatesIDs.Georgia, name: USStatesNames.Georgia },
    { id: USStatesIDs.Hawaii, name: USStatesNames.Hawaii },
    { id: USStatesIDs.Idaho, name: USStatesNames.Idaho },
    { id: USStatesIDs.Illinois, name: USStatesNames.Illinois },
    { id: USStatesIDs.Indiana, name: USStatesNames.Indiana },
    { id: USStatesIDs.Iowa, name: USStatesNames.Iowa },
    { id: USStatesIDs.Kansas, name: USStatesNames.Kansas },
    { id: USStatesIDs.Kentucky, name: USStatesNames.Kentucky },
    { id: USStatesIDs.Louisiana, name: USStatesNames.Louisiana },
    { id: USStatesIDs.Maine, name: USStatesNames.Maine },
    { id: USStatesIDs.Maryland, name: USStatesNames.Maryland },
    { id: USStatesIDs.Massachusetts, name: USStatesNames.Massachusetts },
    { id: USStatesIDs.Michigan, name: USStatesNames.Michigan },
    { id: USStatesIDs.Minnesota, name: USStatesNames.Minnesota },
    { id: USStatesIDs.Mississippi, name: USStatesNames.Mississippi },
    { id: USStatesIDs.Missouri, name: USStatesNames.Missouri },
    { id: USStatesIDs.Montana, name: USStatesNames.Montana },
    { id: USStatesIDs.Nebraska, name: USStatesNames.Nebraska },
    { id: USStatesIDs.Nevada, name: USStatesNames.Nevada },
    { id: USStatesIDs.NewHampshire, name: USStatesNames.NewHampshire },
    { id: USStatesIDs.NewJersey, name: USStatesNames.NewJersey },
    { id: USStatesIDs.NewMexico, name: USStatesNames.NewMexico },
    { id: USStatesIDs.NewYork, name: USStatesNames.NewYork },
    { id: USStatesIDs.NorthCarolina, name: USStatesNames.NorthCarolina },
    { id: USStatesIDs.NorthDakota, name: USStatesNames.NorthDakota },
    { id: USStatesIDs.Ohio, name: USStatesNames.Ohio },
    { id: USStatesIDs.Oklahoma, name: USStatesNames.Oklahoma },
    { id: USStatesIDs.Oregon, name: USStatesNames.Oregon },
    { id: USStatesIDs.Pennsylvania, name: USStatesNames.Pennsylvania },
    { id: USStatesIDs.RhodeIsland, name: USStatesNames.RhodeIsland },
    { id: USStatesIDs.SouthCarolina, name: USStatesNames.SouthCarolina },
    { id: USStatesIDs.SouthDakota, name: USStatesNames.SouthDakota },
    { id: USStatesIDs.Tennessee, name: USStatesNames.Tennessee },
    { id: USStatesIDs.Texas, name: USStatesNames.Texas },
    { id: USStatesIDs.Utah, name: USStatesNames.Utah },
    { id: USStatesIDs.Vermont, name: USStatesNames.Vermont },
    { id: USStatesIDs.Virginia, name: USStatesNames.Virginia },
    { id: USStatesIDs.Washington, name: USStatesNames.Washington },
    { id: USStatesIDs.WestVirginia, name: USStatesNames.WestVirginia },
    { id: USStatesIDs.Wisconsin, name: USStatesNames.Wisconsin },
    { id: USStatesIDs.Wyoming, name: USStatesNames.Wyoming },
  ];

  const navigateToLogin = () => {
    navigate(RouterPath.Login);
    localStorage.clear();
    sessionStorage.clear();
  };

  const yearsList = () => {
    const years = [];
    for (let year = 1990; year <= 9999; year++) {
      years?.push(year);
    }
    return years;
  };

  useEffect(() => {
    const getUserInfo = async () => {
      if (userEmail || updatePersonalInfo) {
        setShowLoader(true);
        await getPersonalInfoRequest(userEmail)
          .then((personalResponse) => {
            if (personalResponse) {
              setShowLoader(false);
              setPersonalInfo(personalResponse.data);
              const firstname = personalResponse?.data?.firstName;
              const lastname = personalResponse?.data?.lastName;
              const fullName = `${firstname} ${lastname}`;
              const avatar = personalResponse?.data?.avatarId ?? 1;
              SetSessionStorage(StorageEnum.UserAvatar, avatar);
              SetSessionStorage(
                StorageEnum.LoggedInUsername,
                fullName as string
              );
            }
          })
          .catch((error: any) => {
            if (
              (error.response && error.response.status === 401) ||
              (error.response && error.response.status === 403)
            ) {
              setIsTokenExpired(true);
              setShowLoader(false);
              setErrorMessage(
                "Something went wrong while connecting to the API. Please restart the application."
              );
              setTimeout(() => {
                setIsTokenExpired(false);
                navigateToLogin();
              }, 3000);
            } else {
              setErrorPopUp(true);
              setShowLoader(false);
              setIsTokenExpired(false);
              setErrorMessage("Operation failed.");
              setTimeout(() => {
                setErrorPopUp(false);
              }, 3000);
            }
          });
      }
    };

    getUserInfo();
  }, [userEmail, updatePersonalInfo]);

  const getProfileInfo = async () => {
    if (userEmail || updateProfilelInfo) {
      setShowLoader(true);
      await getProfileInfoRequest(userEmail)
        .then((profileResponse) => {
          if (profileResponse.status === 200) {
            setProfileInfo(profileResponse.data);
            setShowLoader(false);
          } else {
            setShowLoader(false);
            setProfileInfo(null);
          }
        })
        .catch((error: any) => {
          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status === 403)
          ) {
            setIsTokenExpired(true);
            setShowLoader(false);
            setProfileInfo(null);
            setErrorMessage(
              "Something went wrong while connecting to the API. Please restart the application."
            );
            setTimeout(() => {
              navigateToLogin();
              setIsTokenExpired(false);
            }, 3000);
          } else {
            setErrorPopUp(true);
            setShowLoader(false);
            setProfileInfo(null);
            setErrorMessage("An error occurred. Please try again later.");
            setTimeout(() => {
              setErrorPopUp(false);
            }, 3000);
          }
        });
    }
  };

  useEffect(() => {
    getProfileInfo();
  }, [userEmail, updateProfilelInfo]);

  const [personalInfoFormData, setPersonalInfoFormData] = useState({
    firstname: StringConstant.EMPTY,
    lastname: StringConstant.EMPTY,
    email: StringConstant.EMPTY,
    username: StringConstant.EMPTY,
  });

  const [personalInfoFormValidErrors, setPersonalInfoFormValidErrors] =
    useState({
      firstname: StringConstant.EMPTY,
      lastname: StringConstant.EMPTY,
      email: StringConstant.EMPTY,
      username: StringConstant.EMPTY,
    });

  useEffect(() => {
    if (personalInfo) {
      setPersonalInfoFormData({
        firstname: personalInfo?.firstName || "",
        lastname: personalInfo?.lastName || "",
        email: personalInfo?.email || "",
        username: personalInfo?.userName || "",
      });
    }
  }, [personalInfo]);

  interface ProfileInfoFormData {
    password: string;
    country: string;
    currentHighSchool: string;
    zipcode: string;
    graduationYear: string | null;
    gender: string;
    state: string;
    race: { id: number | null; race: String }[];
    generation: boolean;
  }

  const initialState: ProfileInfoFormData = {
    password: StringConstant.EMPTY,
    country: StringConstant.EMPTY,
    currentHighSchool: StringConstant.EMPTY,
    zipcode: StringConstant.EMPTY,
    graduationYear: "2000",
    gender: StringConstant.EMPTY,
    state: StringConstant.EMPTY,
    race: [],
    generation: false,
  };

  const [profileInfoFormData, setProfileInfoFormData] = useState(initialState);

  const [profileInfoFormValidErrors, setProfileInfoFormValidErrors] = useState({
    password: StringConstant.EMPTY,
    country: StringConstant.EMPTY,
    currentHighSchool: StringConstant.EMPTY,
    zipcode: StringConstant.EMPTY,
    graduationYear: StringConstant.EMPTY || null,
    gender: StringConstant.EMPTY,
    state: StringConstant.EMPTY,
    race: StringConstant.EMPTY || null,
    generation: false,
  });

  useEffect(() => {
    if (profileInfo) {
      const updatedProfileInfoFormData = {
        password: StringConstant.EMPTY,

        country: profileInfo.country || StringConstant.EMPTY,

        currentHighSchool:
          profileInfo.currentHighSchool || StringConstant.EMPTY,

        zipcode: profileInfo.zipcode || StringConstant.EMPTY,

        graduationYear: profileInfo.graduationYear || null,

        gender: profileInfo.gender || StringConstant.EMPTY,
        state: profileInfo.state || StringConstant.EMPTY,

        race:
          raceEthnicityOptions?.filter((item) =>
            profileInfo?.race?.some((rac: String) => rac === item.race)
          ) || [],

        generation: profileInfo.generation || false,
      };

      if (
        updatedProfileInfoFormData.graduationYear === "" ||
        updatedProfileInfoFormData.graduationYear === null
      ) {
        updatedProfileInfoFormData.graduationYear = null;
      }

      setProfileInfoFormData(updatedProfileInfoFormData);
    }
  }, [profileInfo]);

  const validateEmail = (email: any) => {
    return emailRegex.test(email);
  };

  const handleGenerationToggleChange = (event: any) => {
    setProfileInfoFormData({
      ...profileInfoFormData,
      generation: event?.target?.checked,
    });
  };

  const validatePassword = (password: any) => {
    return passwordRegex.test(password);
  };

  const handlePersonInfoFormInputChange = (event: any) => {
    const { name, value } = event.target;

    setPersonalInfoFormData({
      ...personalInfoFormData,
      [name]: value,
    });

    if (name === SignupErrorsEnum.FirstName) {
      setPersonalInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        firstname: value
          ? StringConstant.EMPTY
          : SignupErrorsEnum.FirstNameRequired,
      }));
    }

    if (name === SignupErrorsEnum.LastName) {
      setPersonalInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        lastname: value
          ? StringConstant.EMPTY
          : SignupErrorsEnum.LastNameRequired,
      }));
    }

    if (name === SignupErrorsEnum.username) {
      setPersonalInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        username: value
          ? StringConstant.EMPTY
          : SignupErrorsEnum.UserNameRequried,
      }));
    } else if (name === SignupErrorsEnum.Email) {
      setPersonalInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        email: validateEmail(value)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.EmailRequired,
      }));
    }
  };

  const validatePersonalInfoForm = (formErrors: any) => {
    if (
      isEmpty(formErrors.firstname) &&
      isEmpty(formErrors.lastname) &&
      isEmpty(formErrors.email) &&
      isEmpty(formErrors.password) &&
      isEmpty(formErrors.username)
    ) {
      return;
    }
  };

  const handleAvatarSelect = (avatarId: any) => {
    setSelectedAvatarID(avatarId);
  };

  const updatePersonalInfoRequest = async () => {
    const payload = {
      firstName: (personalInfoFormData?.firstname || "").trim(),
      lastName: (personalInfoFormData?.lastname || "").trim(),
      email: (personalInfoFormData?.email || "").trim(),
      userName: (personalInfoFormData?.username || "").trim(),
      avatarId: Number(selectedAvatarID),
    };

    await updateUserPersonalInfo(payload, userEmail)
      .then((response) => {
        if (response) {
          setUpdatePersonalInfo(response?.data);

          setSuccessPopUp(true);
          setErrorMessage("Your information has been updated successfully.");
          setTimeout(() => {
            setSuccessPopUp(false);
            setErrorMessage("");
          }, 3000);
        }
      })
      .catch((error: any) => {
        if (
          (error.response && error.response.status === 401) ||
          (error.response && error.response.status === 403)
        ) {
          setIsTokenExpired(true);

          setErrorMessage(
            "Something went wrong while connecting to the API. Please restart the application."
          );
          setTimeout(() => {
            setIsTokenExpired(false);
            navigateToLogin();
          }, 3000);
        } else {
          setErrorPopUp(true);
          setErrorMessage("Operation failed.");
          setTimeout(() => {
            setErrorPopUp(false);
          }, 3000);
        }
      });
  };

  const updateProfileInfoRequest = async () => {
    const payload = {
      country: profileInfoFormData?.country || "",
      currentHighSchool: (profileInfoFormData?.currentHighSchool || "").trim(),
      zipcode: (profileInfoFormData?.zipcode || "").trim(),
      graduationYear: Number(profileInfoFormData?.graduationYear) || 2000,
      gender: profileInfoFormData?.gender || "",
      state: profileInfoFormData?.state || "",
      race:
        profileInfoFormData?.race?.length > 0
          ? profileInfoFormData?.race?.map((item) => item.race)
          : [],
      generation: profileInfoFormData?.generation || false,
    };

    if (profileInfo) {
      await updateUserProfileInfo(payload, userEmail)
        .then((response) => {
          setShowLoader(true);
          if (response) {
            setUpdateProfileInfo(response?.data);
            setSuccessPopUp(true);
            setErrorMessage("Your information has been updated successfully.");
            setTimeout(() => {
              setSuccessPopUp(false);
              setErrorMessage("");
            }, 3000);
            setShowLoader(false);
          } else {
            setUpdateProfileInfo(null);
            setShowLoader(false);
          }
        })
        .catch((error: any) => {
          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status === 403)
          ) {
            setIsTokenExpired(true);
            setShowLoader(false);
            setUpdateProfileInfo(null);
            setShowLoader(false);
            setErrorMessage(
              "Something went wrong while connecting to the API. Please restart the application."
            );
            setTimeout(() => {
              setIsTokenExpired(false);
              navigateToLogin();
            }, 3000);
          } else {
            setErrorPopUp(true);
            setShowLoader(false);
            setIsTokenExpired(false);
            setUpdateProfileInfo(null);
            setShowLoader(false);
            setErrorMessage("Operation failed.");
            setTimeout(() => {
              setErrorPopUp(false);
            }, 3000);
          }
        });
    } else {
      await createUserProfileInfo(payload, userEmail)
        .then((response) => {
          setShowLoader(true);
          if (response) {
            getProfileInfo();
            setSuccessPopUp(true);
            setErrorMessage("Your information has been saved successfully.");
            setTimeout(() => {
              setSuccessPopUp(false);
              setErrorMessage("");
            }, 3000);
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
        })
        .catch((error: any) => {
          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status === 403)
          ) {
            setIsTokenExpired(true);
            setShowLoader(false);
            setErrorMessage(
              "Something went wrong while connecting to the API. Please restart the application."
            );
            setTimeout(() => {
              setIsTokenExpired(false);
              navigateToLogin();
            }, 3000);
          } else {
            setErrorPopUp(true);
            setShowLoader(false);
            setErrorMessage("Operation failed.");
            setTimeout(() => {
              setErrorPopUp(false);
            }, 3000);
          }
        });
    }
  };

  const handlePersonalInfoSaveClick = (event: any) => {
    event.preventDefault();

    const formErrors = {
      firstname: !isEmpty(personalInfoFormData.firstname)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.FirstNameRequired,
      username: !isEmpty(personalInfoFormData.username)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.UserNameRequried,

      lastname: !isEmpty(personalInfoFormData.lastname)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.LastNameRequired,
      email:
        validateEmail(personalInfoFormData.email) ||
        !isEmpty(personalInfoFormData.email)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.EmailRequired,
    };

    setPersonalInfoFormValidErrors({ ...formErrors });
    validatePersonalInfoForm(formErrors);

    if (
      formErrors.firstname ||
      formErrors.lastname ||
      formErrors.email ||
      formErrors.username
    ) {
      return;
    } else {
      updatePersonalInfoRequest();
    }
  };

  const validateProfileInfoForm = (formErrors: any) => {
    if (
      isEmpty(formErrors.password) &&
      (isNaN(formErrors.graduationYear) ||
        isEmpty(formErrors.graduationYear)) &&
      isEmpty(formErrors.zipcode) &&
      isEmpty(formErrors.currentHighSchool) &&
      isEmpty(formErrors.state) &&
      isEmpty(formErrors.country)
    ) {
      return;
    }
  };

  const handleProfileInfoSaveClick = (event: any) => {
    event.preventDefault();

    const formErrors = {
      country: !isEmpty(profileInfoFormData.country)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.CountryRequried,
      currentHighSchool: !isEmpty(profileInfoFormData.currentHighSchool)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.SchoolRequried,
      graduationYear:
        profileInfoFormData.graduationYear === "null" ||
        profileInfoFormData.graduationYear === ""
          ? SignupErrorsEnum.graduationRequried
          : StringConstant.EMPTY,

      zipcode: !isEmpty(profileInfoFormData.zipcode)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.zipcodeRequried,
      gender: !isEmpty(profileInfoFormData.gender)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.genderRequired,
      state: !isEmpty(profileInfoFormData.state)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.StateRequired,
      race:
        profileInfoFormData?.race?.length > 0
          ? StringConstant.EMPTY
          : SignupErrorsEnum.raceRequried,
      generation: false,
      password:
        validatePassword(profileInfoFormData.password) ||
        !isEmpty(profileInfoFormData.password)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.PasswordRequired,
    };

    setProfileInfoFormValidErrors({ ...formErrors });
    validateProfileInfoForm(formErrors);

    if (
      formErrors?.country ||
      formErrors?.currentHighSchool ||
      formErrors?.gender ||
      formErrors?.state ||
      formErrors?.generation ||
      profileInfoFormData.graduationYear === "null" ||
      profileInfoFormData.graduationYear === "" ||
      formErrors?.zipcode
    ) {
      return;
    } else {
      updateProfileInfoRequest();
    }
  };

  const handleProfileInfoFormInputChange = (event: any) => {
    const { name, value } = event?.target;

    setProfileInfoFormData({
      ...profileInfoFormData,
      [name]: value,
    });

    if (name === SignupErrorsEnum.currentHighSchool) {
      setProfileInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        currentHighSchool:
          value?.length > 0
            ? StringConstant.EMPTY
            : SignupErrorsEnum.SchoolRequried,
      }));
    }
    if (name === SignupErrorsEnum.Country) {
      setProfileInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        country: value
          ? StringConstant.EMPTY
          : SignupErrorsEnum.CountryRequried,
      }));
    }
    if (name === SignupErrorsEnum.zipcode) {
      setProfileInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        zipcode: value
          ? StringConstant.EMPTY
          : SignupErrorsEnum.zipcodeRequried,
      }));
    }

    if (name === SignupErrorsEnum.State) {
      setProfileInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        state: value ? StringConstant.EMPTY : SignupErrorsEnum.StateRequired,
      }));
    }

    if (name === SignupErrorsEnum.graduation) {
      setProfileInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        graduationYear: value
          ? StringConstant.EMPTY
          : SignupErrorsEnum.graduationRequried,
      }));
    } else if (name === SignupErrorsEnum.Password) {
      setProfileInfoFormValidErrors((prevErrors) => ({
        ...prevErrors,
        password: validatePassword(value)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.PasswordRequired,
      }));
    }
  };

  const handleDeleteProfile = () => {
    setDeleteConfirm(true);
  };

  const AvatarProfilePage = () => {
    const userAvatarSrc = avatarsGroup?.find(
      (item: any) => item?.id === Number(loggedInUserAvatarID)
    )?.src;

    return (
      <AppBar
        sx={{
          height: "150px",
          width: "150px",
          position: "relative !important",
          marginTop: "30px",
          boxShadow: "none",
          background: `url('${userAvatarSrc}') center center / cover no-repeat`,
        }}
      ></AppBar>
    );
  };

  const navigateToHomePage = () => {
    navigate(RouterPath.FindMajorsSearchMode);
    triggerEvent({ message: "Event from ProfilePage" });
  };

  const handleDeleteCancel = () => {
    setDeleteConfirm(false);
  };

  const handleDeleteOkay = async () => {
    await deleteUserProfileInfo(userEmail)
      .then((res) => {
        if (res?.status === 200) {
          setDeleteConfirm(false);
          navigate(RouterPath.Login);
        }
      })
      .catch((error: any) => {
        if (
          (error.response && error.response.status === 401) ||
          (error.response && error.response.status === 403)
        ) {
          setIsTokenExpired(true);

          setDeleteConfirm(false);
          setErrorMessage(
            "Something went wrong while connecting to the API. Please restart the application."
          );
          setTimeout(() => {
            setIsTokenExpired(false);
            navigateToLogin();
          }, 3000);
        } else {
          setErrorPopUp(true);

          setDeleteConfirm(false);
          setErrorMessage("Operation failed.");
          setTimeout(() => {
            setErrorPopUp(false);
          }, 3000);
        }
      });
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#7F56D9",
      },
    },
  });

  for (let i = 0; i < avatarsGroup.length; i += 15) {
    avatarsRows.push(avatarsGroup.slice(i, i + 15));
  }
  return (
    <div className="logged-in-user-profilepage-section">
      <Grid component={"form"}>
        <ProfileBackground>
          {showLoader && <Loader />}

          <div className="user-profile-page-section">
            <div>
              <SettingsBackgroundImage />
            </div>
            <div className="user-avatar-section">
              <div>
                <AvatarProfilePage />
              </div>
              <div className="user-loggedin-details-section">
                <div className="loggedin-username-section">
                  <Typography className="loggedin-username">
                    {`${personalInfo?.firstName ?? ""} ${
                      personalInfo?.lastName ?? ""
                    }`}
                  </Typography>
                </div>
                <div className="email-header-section">
                  <Typography className="email-header">
                    {personalInfo?.email}
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="profile-page-scroll-section">
            <div>
              <div className="update-personal-info-section">
                <div className="personal-info-first-section">
                  <div className="personal-info-header">
                    <span>Personal info</span>
                  </div>
                  <div className="personal-info-update-details-header">
                    <span>Update your personal details.</span>
                  </div>
                </div>

                <div className="personal-info-second-section">
                  <div className="personal-section-box-shadow">
                    <div className="personal-info-first-lastname-section">
                      <div className="personal-info-first-name-section">
                        <span className="personal-info-firstname-header">
                          {SignupLabels.FirstName}
                        </span>
                        <TextField
                          margin="normal"
                          autoComplete="new-firstname"
                          required
                          className={"personal-info-firstname-textfield"}
                          fullWidth
                          id="firstname"
                          placeholder={"Enter your first name"}
                          name="firstname"
                          value={personalInfoFormData.firstname}
                          error={!!personalInfoFormValidErrors.firstname}
                          helperText={personalInfoFormValidErrors.firstname}
                          onChange={handlePersonInfoFormInputChange}
                        />
                      </div>
                      <div className="personal-info-last-name-section">
                        <span className="personal-info-lastname-header">
                          {SignupLabels.LastName}
                        </span>
                        <TextField
                          margin="normal"
                          required
                          autoComplete="new-lastname"
                          className={"personal-info-lastname-textfield"}
                          fullWidth
                          id="lastname"
                          placeholder={"Enter your last name"}
                          name="lastname"
                          value={personalInfoFormData.lastname}
                          error={!!personalInfoFormValidErrors.lastname}
                          helperText={personalInfoFormValidErrors.lastname}
                          onChange={handlePersonInfoFormInputChange}
                        />
                      </div>
                    </div>
                    <div className="personal-info-email-section">
                      <span className="personal-info-email-header">
                        {SignupLabels.EmailAddress}
                      </span>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="new-email"
                        className={"personal-info-email-field"}
                        id="email"
                        disabled={true}
                        placeholder={SignupPlaceholders.EnterEmail}
                        name="email"
                        value={personalInfoFormData.email}
                        error={!!personalInfoFormValidErrors.email}
                        helperText={personalInfoFormValidErrors.email}
                        onChange={handlePersonInfoFormInputChange}
                      />
                    </div>
                    <div className="personal-info-username-section">
                      <span className="personal-info-username-header">
                        {SignupLabels.Username}
                      </span>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="new-username"
                        className={"personal-info-username-field"}
                        id="username"
                        placeholder={SignupPlaceholders.Username}
                        name="username"
                        value={personalInfoFormData.username}
                        error={!!personalInfoFormValidErrors.username}
                        helperText={personalInfoFormValidErrors.username}
                        onChange={handlePersonInfoFormInputChange}
                      />
                    </div>
                    <div className="avatar-section">
                      <span className="avatar-header">Select An Avatar</span>
                      {avatarsRows?.map((row, index) => (
                        <Stack
                          key={index}
                          direction="row"
                          spacing={1}
                          marginBottom={"0.586rem"}
                        >
                          {row?.map((avatar) => (
                            <div
                              key={avatar?.id}
                              style={{ position: "relative" }}
                            >
                              <Avatar
                                src={avatar?.src}
                                onClick={() => handleAvatarSelect(avatar?.id)}
                                sx={{
                                  cursor: "pointer",
                                  border:
                                    selectedAvatarID === avatar?.id
                                      ? "2px solid #5841d9"
                                      : "none",
                                }}
                              />
                              {loggedInUserAvatarID &&
                                loggedInUserAvatarID === avatar?.id && (
                                  <img
                                    src={group71}
                                    alt="Selected"
                                    style={{
                                      position: "absolute",
                                      top: " -4px",
                                      right: "0px",
                                      width: "41px",
                                      height: " 48px",
                                    }}
                                  />
                                )}
                            </div>
                          ))}
                        </Stack>
                      ))}
                    </div>

                    <div className="personal-info-action-btn-sections">
                      <div className="personal-info-cancel">
                        <Button
                          variant="outlined"
                          onClick={navigateToHomePage}
                          className="personal-info-cancel-btn-section"
                        >
                          <span className="cancel-btn">
                            {SignupButtonsLabel.Cancel}
                          </span>
                        </Button>
                      </div>

                      <div className="personal-info-save">
                        {!personalInfoFormData?.firstname ||
                        !personalInfoFormData?.lastname ||
                        !personalInfoFormData?.email ||
                        !personalInfoFormData?.username ? (
                          <Button
                            type="submit"
                            variant="contained"
                            disabled={true}
                            className="personal-info-savedisabled-btn-section"
                          >
                            <span>{SignupButtonsLabel.SaveChanges}</span>
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            variant="contained"
                            id="userpersonaleinfo"
                            onClick={handlePersonalInfoSaveClick}
                            className="personal-info-save-btn-section"
                          >
                            <span>{SignupButtonsLabel.SaveChanges}</span>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="personal-info-divider">
              <Divider></Divider>
            </div>
            <div>
              <div className="update-profile-info-section">
                <div className="profile-info-first-section">
                  <div className="profile-info-header">
                    <span>Profile</span>
                  </div>
                  <div className="profile-info-update-details-header">
                    <span>Update your account information.</span>
                  </div>
                </div>

                <div className="profile-info-second-section">
                  <div className="profile-section-box-shadow">
                    <div className="profile-info-country-section">
                      <span className="profile-info-country-header">
                        {SignupLabels.Country}
                      </span>
                      <Autocomplete
                        id="country-select-demo"
                        options={CountriesList}
                        autoHighlight
                        value={
                          CountriesList?.find(
                            (country: any) =>
                              country.country === profileInfoFormData.country
                          ) || null
                        }
                        PopperComponent={CustomPopper}
                        onChange={(event, newValue) => {
                          setProfileInfoFormData({
                            ...profileInfoFormData,
                            country: newValue ? newValue.country : "",
                          });
                        }}
                        getOptionLabel={(option) => option?.country}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https:flagcdn.com/w40/${
                                option.code ? option.code.toLowerCase() : ""
                              }.png 2x`}
                              src={`https:flagcdn.com/w20/${
                                option.code ? option.code.toLowerCase() : ""
                              }.png`}
                              alt=""
                            />
                            {option.country} ({option.code})
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            required
                            autoComplete="new-country"
                            className="profile-info-country-field"
                            id="country"
                            placeholder={SignupPlaceholders.Country}
                            name="country"
                            error={!profileInfoFormData.country}
                            helperText={!profileInfoFormData.country}
                            onChange={handleProfileInfoFormInputChange}
                          />
                        )}
                      />
                    </div>
                    <div className="profile-info-state-section">
                      <span className="profile-info-state-header">
                        {SignupLabels.States}
                      </span>
                      <Autocomplete
                        fullWidth
                        options={listOfStates}
                        value={
                          listOfStates?.find(
                            (state) =>
                              state?.name === profileInfoFormData?.state
                          ) || null
                        }
                        PopperComponent={CustomPopper}
                        onChange={(event, newValue) => {
                          setProfileInfoFormData({
                            ...profileInfoFormData,
                            state: newValue ? newValue?.name : "",
                          });
                        }}
                        getOptionLabel={(option) => option?.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            className="profile-info-state-field"
                            id="state"
                            autoComplete="new-state"
                            placeholder={"Please select your state"}
                            name="state"
                            error={!profileInfoFormData?.state}
                            helperText={!profileInfoFormData?.state}
                          />
                        )}
                      />
                    </div>

                    <div className="profile-info-school-section">
                      <span className="profile-info-school-header">
                        {SignupLabels.CurrentHighSchool}
                      </span>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        type="text"
                        autoComplete="new-currentHighSchool"
                        className="profile-info-school-field"
                        id="currentHighSchool"
                        placeholder="Select your current high school"
                        name="currentHighSchool"
                        value={profileInfoFormData.currentHighSchool}
                        error={!profileInfoFormData.currentHighSchool}
                        helperText={
                          profileInfoFormValidErrors.currentHighSchool
                        }
                        onChange={handleProfileInfoFormInputChange}
                      />
                    </div>
                    <div className="profile-info-zipcode-section">
                      <span className="profile-info-zipcode-header">
                        {SignupLabels.ZipCode}
                      </span>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="new-zipcode"
                        type="text"
                        className={"profile-info-zipcode-field"}
                        id="zipcode"
                        placeholder={SignupPlaceholders.Zipcode}
                        name="zipcode"
                        value={profileInfoFormData.zipcode}
                        error={!!profileInfoFormValidErrors.zipcode}
                        helperText={profileInfoFormValidErrors.zipcode}
                        onChange={handleProfileInfoFormInputChange}
                      />
                    </div>
                    <div className="profile-info-graduateYear-section">
                      <span className="profile-info-graduateYear-header">
                        {SignupLabels.Graduation}
                      </span>
                      <Autocomplete
                        fullWidth
                        options={yearsList()}
                        getOptionLabel={(option) => option?.toString()}
                        value={
                          profileInfoFormData?.graduationYear
                            ? Number(profileInfoFormData.graduationYear)
                            : 2000
                        }
                        PopperComponent={CustomPopper}
                        onChange={(event, newValue) => {
                          setProfileInfoFormData({
                            ...profileInfoFormData,
                            graduationYear: String(newValue),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            required
                            autoComplete="new-graduationYear"
                            type="number"
                            className="profile-info-graduateYear-field"
                            id="graduationYear"
                            placeholder={SignupPlaceholders.Graduation}
                            name="graduationYear"
                            error={!!profileInfoFormValidErrors.graduationYear}
                            helperText={
                              profileInfoFormValidErrors.graduationYear
                            }
                            onChange={handleProfileInfoFormInputChange}
                          />
                        )}
                      />
                    </div>
                    <div className="profile-info-gender-section">
                      <span className="profile-info-gender-header">
                        {SignupLabels.Gender}
                      </span>
                      <Autocomplete
                        fullWidth
                        options={genders}
                        value={
                          genders.find(
                            (gender) =>
                              gender.name === profileInfoFormData.gender
                          ) || null
                        }
                        PopperComponent={CustomPopper}
                        onChange={(event, newValue) => {
                          setProfileInfoFormData({
                            ...profileInfoFormData,
                            gender: newValue ? newValue.name : "",
                          });
                        }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            className="profile-info-gender-field"
                            id="gender"
                            placeholder={SignupPlaceholders.Gender}
                            name="gender"
                            required
                            error={!profileInfoFormData.gender}
                            helperText={!profileInfoFormData.gender}
                          />
                        )}
                      />
                    </div>
                    <div className="profile-info-ethnicity-section">
                      <span className="profile-info-ethnicity-header">
                        {SignupLabels.RaceEthnicity}
                      </span>
                      <Autocomplete
                        fullWidth
                        multiple
                        options={raceEthnicityOptions}
                        value={
                          profileInfoFormData?.race?.length > 0
                            ? profileInfoFormData?.race
                            : []
                        }
                        PopperComponent={CustomPopper}
                        onChange={(event, newValue) => {
                          setProfileInfoFormData({
                            ...profileInfoFormData,
                            race: newValue,
                          });
                        }}
                        getOptionLabel={(option) => String(option.race)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            margin="normal"
                            autoComplete="new-ethnicity"
                            className="profile-info-ethnicity-field"
                            id="ethnicity"
                            placeholder={
                              profileInfoFormData?.race?.length > 0
                                ? ""
                                : "Select race/ethnicity"
                            }
                            name="ethnicity"
                          />
                        )}
                      />
                    </div>

                    <div className="profile-toggle-section">
                      <ThemeProvider theme={theme}>
                        <div className="profile-toggle-section">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={profileInfoFormData.generation}
                                onChange={handleGenerationToggleChange}
                                color="primary"
                              />
                            }
                            className="first-genaration-label-text"
                            value={profileInfoFormData?.generation}
                            label="I am a first generation college student"
                          />
                        </div>
                      </ThemeProvider>
                    </div>

                    <div className="profile-info-action-btn-sections">
                      <div className="profile-info-delete">
                        <span onClick={handleDeleteProfile}>
                          Delete Account
                        </span>
                      </div>

                      <div className="profile-info-cancel">
                        <Button
                          variant="outlined"
                          onClick={navigateToHomePage}
                          className="profile-info-cancel-btn-section"
                        >
                          <span className="cancel-btn">
                            {SignupButtonsLabel.Cancel}
                          </span>
                        </Button>
                      </div>

                      <div className="profile-info-save">
                        {!profileInfoFormData?.country ||
                        !profileInfoFormData?.currentHighSchool ||
                        !profileInfoFormData?.gender ||
                        !profileInfoFormData?.state ||
                        !profileInfoFormData?.graduationYear ||
                        profileInfoFormData?.graduationYear === "null" ||
                        profileInfoFormData?.graduationYear === null ||
                        !profileInfoFormData?.zipcode ? (
                          <Button
                            type="submit"
                            variant="contained"
                            className="profile-info-savedisabled-btn-section"
                            disabled={true}
                          >
                            <span>{SignupButtonsLabel.SaveChanges}</span>
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            id="userprofileinfo"
                            variant="contained"
                            className="profile-info-save-btn-section"
                            onClick={handleProfileInfoSaveClick}
                          >
                            <span>{SignupButtonsLabel.SaveChanges}</span>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Dialog open={deleteConfirm} style={{ marginRight: "-60px" }}>
            <Paper style={{ width: "400px", height: "170px" }}>
              <DialogTitle>{"Warning"}</DialogTitle>{" "}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete?
                </DialogContentText>
              </DialogContent>
              <div className="profile-info-delete-popup-action-btn-sections">
                <div className="profile-info-delete-popup-cancel">
                  <Button
                    variant="outlined"
                    onClick={handleDeleteCancel}
                    className="profile-info-delete-popup-cancel-btn-section"
                  >
                    <span className="cancel-btn">
                      {SignupButtonsLabel.Cancel}
                    </span>
                  </Button>
                </div>

                <div className="profile-info-delete-popup-save">
                  <Button
                    type="submit"
                    variant="contained"
                    className="profile-info-delete-popup-save-btn-section"
                    onClick={handleDeleteOkay}
                  >
                    <span>{SignupButtonsLabel.Okay}</span>
                  </Button>
                </div>
              </div>
            </Paper>
          </Dialog>
          <Dialog open={errorPopUp} style={{ marginRight: "-60px" }}>
            <Paper style={{ width: "400px", height: "110px" }}>
              <DialogTitle>{"Failed"}</DialogTitle>{" "}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {errorMessage}
                </DialogContentText>
              </DialogContent>
            </Paper>
          </Dialog>
          <Dialog open={successPopUp} style={{ marginRight: "-60px" }}>
            <Paper style={{ width: "400px", height: "110px" }}>
              <DialogTitle>{"Success"}</DialogTitle>{" "}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {errorMessage}
                </DialogContentText>
              </DialogContent>
            </Paper>
          </Dialog>

          <Dialog open={isTokenExpired} style={{ marginRight: "-60px" }}>
            <Paper style={{ width: "400px", height: "130px" }}>
              <DialogTitle>{"Something went wrong"}</DialogTitle>{" "}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {errorMessage}
                </DialogContentText>
              </DialogContent>
            </Paper>
          </Dialog>
        </ProfileBackground>
      </Grid>
    </div>
  );
};

import AppBar from "@mui/material/AppBar";
import SettingsProfileBackgroundimage from "../../src/assets/SettingsProfileBackgroundimage.png";

export const SettingsBackgroundImage = () => {
  return (
    <AppBar
      sx={{
        height: "120px",
        background: `url('${SettingsProfileBackgroundimage}') center center / cover no-repeat`,
      }}
    ></AppBar>
  );
};

import { createContext, useContext, useState, ReactNode } from "react";
import { EventContextType } from "src/interfaces/account/Account";

const EventContext = createContext<EventContextType | undefined>(undefined);

export const EventProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [eventData, setEventData] = useState<any | null>(null);

  const triggerEvent = (data: any) => {
    setEventData(data);
  };

  return (
    <EventContext.Provider value={{ eventData, triggerEvent }}>
      {children}
    </EventContext.Provider>
  );
};

export const useEvent = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error("useEvent must be used within an EventProvider");
  }
  return context;
};

import {
  Button,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router";
import { clear, collegegrouplogo, searchmode } from "src/assets";
import { SuggestedCourses } from "src/enums/Courses.enum";
import { RouterPath } from "src/enums/RouterPath.enum";
import { Page } from "../page/Page";
import "./FindMajorsManualSearch.styles.scss";

export const FindMajorsManualSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showMaxMessage, setShowMaxMessage] = useState(false);
  const [showInvalidCourse, setShowInvalidCourse] = useState(false);
  const [invalidText, setInvalidText] = useState(false);
  const [inputValue, setInputValue] = useState<any>("");
  const [userTags, setUserTags] = useState<string[] | any>([]);
  const predefinedTags = Object?.values(SuggestedCourses);
  const userTagsLength = userTags?.length == 0 || userTags?.length === 0;

  const handleSearchMode = () => {
    if (location.pathname.includes("/findmajorsmanualsearch")) {
      navigate(RouterPath.FindMajors);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    const regex = /^[a-zA-Z\s][a-zA-Z\s,\/]*$/;
    if (value && !regex.test(value)) {
      setInvalidText(true);
      setShowMaxMessage(false);
      return;
    } else {
      setInputValue("");
      setInvalidText(false);
    }

    if (value?.endsWith(",")) {
      const newTag: any = value.slice(0, -1).trim();
      setShowMaxMessage(false);
      setInvalidText(false);
      setShowInvalidCourse(false);

      if (newTag && userTags.length < 2 && !userTags.includes(newTag)) {
        if (predefinedTags?.includes(newTag)) {
          setUserTags([...userTags, newTag]);
          setInputValue("");
        } else {
          setShowInvalidCourse(true);
        }
      }
    } else if (userTags.length === 2 && value) {
      setInvalidText(false);
      setShowMaxMessage(true);
    } else {
      setInputValue(value);
      setShowMaxMessage(false);
      setShowInvalidCourse(false);
      setInvalidText(false);
    }
  };

  const handleDelete = (tagToDelete: string) => {
    setUserTags(userTags?.filter((tag: any) => tag !== tagToDelete));
  };

  const handleGenerateMajors = () => {
    const params: any = Array?.from(userTags);

    if (params?.length > 0) {
      if (params?.length === 2) {
        navigate(
          generatePath(RouterPath.GenerateMajors2, {
            firstCategory: params[0]?.replace(/\//g, "%"),
            secondCategory: params[1]?.replace(/\//g, "%"),
          })
        );
      } else if (params?.length === 1) {
        navigate(
          generatePath(RouterPath.GenerateMajors1, {
            firstCategory: params[0]?.replace(/\//g, "%"),
          })
        );
      }
    }
  };

  const handleSuggestedTagClick = (suggestedOptions: string) => {
    const newTag: any = suggestedOptions?.trim();
    if (userTags?.length < 2 && !userTags?.includes(newTag)) {
      if (predefinedTags?.includes(newTag)) {
        setUserTags([...userTags, newTag]);
        setInputValue("");
      }
    } else if (userTags?.length === 2 && newTag) {
      setInvalidText(false);
      setShowMaxMessage(true);
    }
  };

  useEffect(() => {
    setShowMaxMessage(false);
    setInvalidText(false);
  }, [userTagsLength]);

  const filteredSuggestions = predefinedTags?.filter((filteredTag) =>
    filteredTag?.toLowerCase()?.startsWith(inputValue?.toLowerCase())
  );

  return (
    <Page>
      <div className="findMajors-Manual-Search-Page">
        <div className="manual-search-mode-align">
          <Button
            id="searchmode"
            onClick={handleSearchMode}
            className="search-mode-hover"
          >
            <img src={searchmode} alt="search mode" />
          </Button>
        </div>
        <div className="manual-search-page">
          <div className="collegegrouplogo-section">
            <img src={collegegrouplogo} alt="" className="collegegroup-img" />
          </div>
          <div className="manual-search-content">
            <div className="poweredby-text-section">
              <p className="poweredby-text">
                <span className="span-text">
                  Our Major Module, powered by ChatGPT4, will suggest majors
                  based on your interest. We will also provide potential career
                  options, salaries and notable people in those fields that you
                  might relate to.
                </span>
              </p>
            </div>
            <div className="choose-text-section">
              <p className="choose-text">
                <span className="span-text">
                  <b>You can type in up to 2 majors.</b> When you type a comma,
                  it will create a tag.
                </span>
              </p>

              <p className="choose-text">
                <span className="span-text">
                  You can export your results after your search in your
                  favorites or in your search history
                </span>
              </p>
              <p className="second-choose-text">
                <span className="span-text">
                  If you would like a little more guidance in finding your
                  major, switch the search mode in the top right corner of this
                  screen.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="manual-search-field">
          <TextField
            id="search"
            autoComplete="off"
            focused={true}
            name="search"
            variant="outlined"
            className="manual-search-field-generate-major-input-field"
            placeholder={
              userTags?.length > 0 ? "" : "Type in your desired major here"
            }
            value={inputValue}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {userTags?.map((tag: any, index: any) => (
                    <Chip
                      key={index}
                      label={tag}
                      size="medium"
                      onDelete={() => handleDelete(tag)}
                      className="chip-text-aligment"
                      style={{ marginRight: 8 }}
                      deleteIcon={<img src={clear} alt="clear" />}
                    />
                  ))}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <div id="generate-major-btn" className="generate-major-btn">
                    {userTags?.length === 0 || userTags?.length == 0 ? (
                      <Button
                        id="generateMajor"
                        variant="contained"
                        className="genarate-btn-text-disabled"
                        disabled={true}
                      >
                        Generate Major
                      </Button>
                    ) : (
                      <Button
                        id="generateMajor"
                        variant="contained"
                        className="genarate-btn-text"
                        onClick={handleGenerateMajors}
                      >
                        Generate Major
                      </Button>
                    )}
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="suggested-courses-section">
          {inputValue && (
            <p>
              <span className="suggested-header">
                {filteredSuggestions?.length > 0 ? "Suggested:" : ""}
              </span>
              {filteredSuggestions?.map((suggestedOptions, index) => (
                <span
                  key={suggestedOptions}
                  className="suggestion-list"
                  onClick={() => handleSuggestedTagClick(suggestedOptions)}
                >
                  {suggestedOptions}
                  {index < filteredSuggestions?.length - 1 ? "," : ""}
                </span>
              ))}
            </p>
          )}
        </div>

        <div className="max-err-msg">
          {showMaxMessage && (
            <Typography color="error" variant="body2">
              Only 2 majors are allowed
            </Typography>
          )}
        </div>

        <div className="max-err-msg">
          {showInvalidCourse && (
            <Typography color="error" variant="body2">
              The course you entered is not valid. Please select a valid course
              from the suggested list.
            </Typography>
          )}
        </div>

        <div className="max-err-msg">
          {invalidText && !showMaxMessage && !showInvalidCourse && (
            <Typography color="error" variant="body2">
              Please enter valid major
            </Typography>
          )}
        </div>
      </div>
    </Page>
  );
};

import { Button, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { collegegrouplogo, searchmode } from "src/assets";
import { CoursesList } from "src/enums/Courses.enum";
import { Category, SubCategory } from "src/interfaces/account/Account";
import { Page } from "../page/Page";

import ClearIcon from "@mui/icons-material/Clear";
import { generatePath, useLocation, useNavigate } from "react-router";
import { RouterPath } from "src/enums/RouterPath.enum";
import { useEvent } from "../navigation/EventProvider";
import "./FindMajors.styles.scss";
import {
  categoryData,
  getButtonStyles,
  getSelectedItemsStyles,
} from "./FindMajorsUtil";

export const FindMajorsPage: React.FC = () => {
  const maxSelectedItems = 3;
  const { eventData } = useEvent();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState<
    Category | null | any
  >(null);
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<SubCategory | null>(null);
  const [selectedItems, setSelectedItems] = useState<
    Set<{ key: string; bubbleStyle: string }>
  >(new Set());
  const [showMaxMessage, setShowMaxMessage] = useState(false);

  const scrollToBottom = () => {
    const element = document.getElementById("generate-major-btn");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const setActive = (currentEle: HTMLElement) => {
    currentEle?.classList.add("menu-item-active");
  };

  const makeActive = (id: any) => {
    const elements = document.getElementsByClassName("menu-item");

    for (let i = 0; i < elements?.length; i++) {
      elements[i].classList.remove("menu-item-active");
    }

    const currentElement = document.getElementById(id);
    if (currentElement) {
      setActive(currentElement);
    }
  };

  useEffect(() => {
    makeActive("findMajors");
    scrollToBottom();
  }, [selectedCategory, selectedSubCategory, selectedItems]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
    setSelectedSubCategory(null);
  };

  const handleSubCategoryClick = (subCategory: SubCategory) => {
    setSelectedSubCategory(subCategory);
  };

  const handleItemClick = (item: string, subCategoryName: string) => {
    setSelectedItems((prevItems) => {
      let isExist = false;
      prevItems?.forEach((prv) => {
        if (prv.key === item) {
          isExist = true;
        }
      });

      if (prevItems?.size < maxSelectedItems && !isExist) {
        const newItems = new Set(prevItems);
        newItems.add({ key: item, bubbleStyle: subCategoryName });
        return newItems;
      }

      return prevItems;
    });
  };

  const handleClearAllSelectedItems = () => {
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setSelectedItems(new Set());
  };

  useEffect(() => {
    if (eventData) {
      handleClearAllSelectedItems();
    }
  }, [eventData]);

  const handleClearItem = (item: any) => {
    setSelectedItems((prevItems) => {
      const newItems = new Set(prevItems);

      newItems.delete(item);

      if (newItems?.size == 0 || newItems?.size === 0) {
        handleClearAllSelectedItems();
      }
      return newItems;
    });
  };

  const handleGenerateMajor = () => {
    const selectedMajorItems = Array?.from(selectedItems);

    setShowMaxMessage(false);

    if (selectedMajorItems?.length > 0) {
      if (selectedMajorItems?.length === 3) {
        navigate(
          generatePath(RouterPath.GenerateMajors3, {
            firstCategory: selectedMajorItems[0]?.key?.replace(/\//g, "%"),
            secondCategory: selectedMajorItems[1]?.key?.replace(/\//g, "%"),
            thirdCategory: selectedMajorItems[2]?.key?.replace(/\//g, "%"),
          })
        );
      } else if (selectedMajorItems?.length === 2) {
        navigate(
          generatePath(RouterPath.GenerateMajors2, {
            firstCategory: selectedMajorItems[0]?.key?.replace(/\//g, "%"),
            secondCategory: selectedMajorItems[1]?.key?.replace(/\//g, "%"),
          })
        );
      } else if (selectedMajorItems?.length === 1) {
        navigate(
          generatePath(RouterPath.GenerateMajors1, {
            firstCategory: selectedMajorItems[0]?.key?.replace(/\//g, "%"),
          })
        );
      }
    }
  };

  const handleMouseEnter = (e: any, categoryName: string) => {
    switch (categoryName) {
      case CoursesList.STEM: {
        e?.target?.classList.add("green-hover");
        return;
      }

      case CoursesList.Humanities: {
        e?.target?.classList.add("purple-hover");
        return;
      }

      case CoursesList.Extracurriculars: {
        e?.target?.classList.add("blue-hover");
        return;
      }

      case CoursesList.Employment: {
        e?.target?.classList.add("orange-hover");
        return;
      }
    }
  };

  const handleClick = (e: any, categoryName: string, level: number) => {
    if (level === 1) {
      const tier1Elements = document.querySelectorAll('[name="tier1"]');

      tier1Elements?.forEach((e) => {
        e?.classList.remove("green-select");
        e?.classList.remove("purple-select");
        e?.classList.remove("blue-select");
        e?.classList.remove("orange-select");
      });
    }

    if (level === 2) {
      const tier1Elements = document.querySelectorAll('[name="tier2"]');

      tier1Elements?.forEach((e) => {
        e?.classList.remove("green-select");
        e?.classList.remove("purple-select");
        e?.classList.remove("blue-select");
        e?.classList.remove("orange-select");
      });
    }

    if (
      (selectedItems?.size < maxSelectedItems && level === 3) ||
      level !== 3
    ) {
      setShowMaxMessage(false);
      switch (categoryName) {
        case CoursesList.STEM: {
          e?.target?.classList.add("green-select");
          return;
        }

        case CoursesList.Humanities: {
          e?.target?.classList.add("purple-select");
          return;
        }

        case CoursesList.Extracurriculars: {
          e?.target?.classList.add("blue-select");
          return;
        }

        case CoursesList.Employment: {
          e?.target?.classList.add("orange-select");
          return;
        }
      }
    } else {
      setShowMaxMessage(true);
    }
  };

  const handleMouseLeave = (e: any, categoryName: string) => {
    switch (categoryName) {
      case CoursesList.STEM: {
        e?.target?.classList.remove("green-hover");
        return;
      }

      case CoursesList.Humanities: {
        e?.target?.classList.remove("purple-hover");
        return;
      }

      case CoursesList.Extracurriculars: {
        e?.target?.classList.remove("blue-hover");
        return;
      }

      case CoursesList.Employment: {
        e?.target?.classList.remove("orange-hover");
        return;
      }
    }
  };

  const handleBlur = (e: any, categoryName: string, level: number) => {
    switch (categoryName) {
      case CoursesList.STEM: {
        if (level !== 3) {
          e?.target?.classList.remove("green-select");
        }
        return;
      }

      case CoursesList.Humanities: {
        if (level !== 3) {
          e?.target?.classList.remove("purple-select");
        }
        return;
      }

      case CoursesList.Extracurriculars: {
        if (level !== 3) {
          e?.target?.classList.remove("blue-select");
        }
        return;
      }

      case CoursesList.Employment: {
        if (level !== 3) {
          e?.target?.classList.remove("orange-select");
        }
        return;
      }
    }
  };

  const handleSearchModeToggle = () => {
    if (location?.pathname?.includes("/findmajors")) {
      navigate(RouterPath.FindMajorsManualSearch);
    }
  };

  const handleTooltip = (courseName?: string, prompt?: string | any) => {
    return (
      <div>
        <div className="tooltip-course-header-section">
          <span>{`${courseName}`}</span>
        </div>
        <div className="tooltip-propmt-text">
          <span>{prompt}</span>
        </div>
      </div>
    );
  };

  return (
    <Page>
      <div className="find-majors-main-page-section">
        <div className="major-section">
          <div className="search-mode-align">
            <Button
              id="searchmode"
              onClick={handleSearchModeToggle}
              className="search-mode-hover"
            >
              <img src={searchmode} alt=""></img>
            </Button>
          </div>
          <div className="find-majors-page">
            <div className="collegegrouplogo-section">
              <img src={collegegrouplogo} alt="" className="collegegroup-img" />
            </div>
            <div className="findmajors-content">
              <div className="poweredby-text-section">
                <p className="poweredby-text">
                  <span className="span-text">
                    Our Major Module, powered by ChatGPT4, will suggest majors
                    based on your interest. We will also provide potential
                    career options, salaries and notable people in those fields
                    that you might relate to.
                    <br />
                  </span>
                </p>
              </div>

              <div className="choose-text-section">
                <p className="choose-text">
                  <span className="span-text">
                    Choose from the options below, subjects and extracurricular
                    activities that you enjoy or feel good about that you want
                    to pursue in college and beyond.
                    <b>You can choose up to 3 in total.</b>{" "}
                  </span>
                </p>

                <p className="choose-text">
                  <span className="span-text">
                    You can export your results after your search in your
                    favorites or in your search history.
                  </span>
                </p>
                <p className="second-choose-text">
                  <span className="span-text">
                    If you selected this by accident and know exactly what you
                    want to major in, switch the search mode in the top right
                    corner of this screen.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="select-categories-section">
            <div className="categories-btn-section">
              <Typography className="select-category-header">
                Select from these categories
              </Typography>
              <div className="categories-mainselection-btns">
                {categoryData?.map((category) => (
                  <div key={category.id} className="category-button-wrapper">
                    <Tooltip
                      title={handleTooltip(category?.name, category?.prompt)}
                      placement="top"
                      classes={{ tooltip: "custom-tooltip" }}
                    >
                      <Button
                        name={"tier1"}
                        variant="contained"
                        onClick={() => handleCategoryClick(category)}
                        style={getButtonStyles(selectedItems, category.name)}
                        onMouseEnter={(e) => handleMouseEnter(e, category.name)}
                        onMouseLeave={(e) => handleMouseLeave(e, category.name)}
                        onFocus={(e) => handleClick(e, category.name, 1)}
                      >
                        {category.name}
                      </Button>
                    </Tooltip>
                  </div>
                ))}
              </div>

              {selectedCategory && (
                <div className="subcategories-border">
                  <Typography className="subcategories-header">
                    Select from these {selectedCategory.name} categories
                  </Typography>
                  {selectedCategory.subcategories && (
                    <div className="subcategories-section">
                      {selectedCategory.subcategories?.map(
                        (subCategory: any) => (
                          <div
                            key={subCategory.id}
                            className="subCategory-button-wrapper"
                          >
                            <Tooltip
                              title={handleTooltip(
                                subCategory?.name,
                                subCategory?.prompt
                              )}
                              placement="top"
                              classes={{ tooltip: "custom-tooltip" }}
                            >
                              <Button
                                name={"tier2"}
                                onClick={() =>
                                  handleSubCategoryClick(subCategory)
                                }
                                style={getButtonStyles(
                                  selectedItems,
                                  selectedCategory.name
                                )}
                                onMouseEnter={(e) =>
                                  handleMouseEnter(e, selectedCategory.name)
                                }
                                onMouseLeave={(e) =>
                                  handleMouseLeave(e, selectedCategory.name)
                                }
                                onFocus={(e) =>
                                  handleClick(e, selectedCategory.name, 2)
                                }
                              >
                                {subCategory.name}
                              </Button>
                            </Tooltip>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              )}
              {selectedSubCategory && (
                <div className="subcategories-border">
                  <Typography className="subcategories-header">
                    Select from these {selectedSubCategory?.name} categories
                  </Typography>
                  <div className="subcategories-section">
                    {selectedSubCategory?.subCategoryItems?.map((item: any) => (
                      <div key={item} className="subCategory-button-wrapper">
                        <Tooltip
                          title={handleTooltip(item?.name, item?.prompt)}
                          placement="top"
                          classes={{ tooltip: "custom-tooltip" }}
                        >
                          <Button
                            onClick={() =>
                              handleItemClick(item?.name, selectedCategory.name)
                            }
                            style={getButtonStyles(
                              selectedItems,
                              selectedCategory.name
                            )}
                            onMouseEnter={(e) =>
                              handleMouseEnter(e, selectedCategory.name)
                            }
                            onMouseLeave={(e) =>
                              handleMouseLeave(e, selectedCategory.name)
                            }
                            onFocus={(e) =>
                              handleClick(e, selectedCategory.name, 3)
                            }
                            onBlur={(e) =>
                              handleBlur(e, selectedCategory.name, 3)
                            }
                          >
                            {item?.name}
                          </Button>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="generate-majors-btn-section">
              <div className="selected-items-section">
                {selectedItems.size > 0 ? (
                  <div className="selected-items-wrapper">
                    <div className="selected-items">
                      {Array?.from(selectedItems).map((item) => (
                        <div
                          key={item.key}
                          className="selected-item-button-wrapper"
                        >
                          <Button
                            style={getSelectedItemsStyles(
                              selectedItems,
                              item.bubbleStyle
                            )}
                          >
                            {item.key}
                            <ClearIcon
                              onClick={() => handleClearItem(item)}
                            ></ClearIcon>
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="selected-items-wrapper-total-section">
                    <span>You can choose up to 3 in total</span>{" "}
                  </div>
                )}
                <div className="selected-items-wrapper-btn">
                  <div id="generate-major-btn" className="generate-major-btn">
                    {Array?.from(selectedItems)?.length > 0 ? (
                      <Button
                        id="generateMajor"
                        onClick={handleGenerateMajor}
                        variant="contained"
                        className="genarate-btn-text"
                      >
                        Generate Major
                      </Button>
                    ) : (
                      <Button
                        id="generateMajor"
                        className="genarate-btn-text-disabled"
                      >
                        Generate Major
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="api-error-message max-err-msg">
          {showMaxMessage && (
            <Typography color="error" variant="body2">
              Only 3 choices are allowed
            </Typography>
          )}
        </div>
      </div>
    </Page>
  );
};

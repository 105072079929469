export enum SignupErrorsEnum {
  EmailRequired = "Please enter valid email",
  PasswordRequired = "Please enter valid password",
  PasswordMismatch = "Passwords do not match",
  FirstNameRequired = "Please enter first name",
  LastNameRequired = "Please enter last name",
  UserNameRequried = "Please enter user name",
  CountryRequried = "Please select country",
  zipcodeRequried = "Please enter zipcode",
  SchoolRequried = "Please select school",
  graduationRequried = "Plesase enter graduation year",
  raceRequried = "Plesase enter race",
  genderRequired = "Please select gender",
  graduation = "graduationYear",
  FirstName = "firstname",
  LastName = "lastname",
  zipcode = "zipcode",
  username = "username",
  school = "school",
  Email = "email",
  Password = "password",
  RetypePassword = "retypepassword",
  InvalildUser = "Invalid Username/Password",
  Country = "country",
  gender = "gender",
  race = "race",
  enthnicity = "enthnicity",
  currentHighSchool = "currentHighSchool",
  StateRequired = "state is required",
  State="state"

}

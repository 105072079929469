export enum SignupPlaceholders {
  EnterName = "Enter your name",
  EnterEmail = "Enter your email",
  Username='Enter your username',
  EnterPassword = "Enter your password",
  
  EnterForgotPassword = 'Enter your password',
  userpassword = "userpassword",
  CurrentHighSchool = "Select current high school",
  Zipcode = "Enter your zipcode",
  Graduation = "Enter your graduation year",
  Gender = " Select your gender",
  RaceEthnicity = " Select your race/ethnicity",
  Country = " Select your  country"
}
import { Typography } from "@mui/material";
import { emaillogo } from "../../assets";

import "./CollegeCrafterEmail.styles.scss";

export const CollegeCrafterEmail = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      className="collogecrafteremail-text"
      {...props}
    >
      <img src={emaillogo} alt={"email"} style={{ marginRight: "0.353rem" }} />

      {"help@collegecrafter.net"}
    </Typography>
  );
};


export interface Favourites {
  userEmail:string;
}

export interface InitialStateModel {
  favorites: Favourites[];
}

const initialState: InitialStateModel = {
  favorites: [],

};

export const appReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case 'favorites':
      return {
        ...state,
        favorites: action.payload,
      };
    default:
      return state;
  }
};

import { CircularProgress, Stack } from "@mui/material";
import updatedLoader from "../../assets/updatedLoader.png";

import "./MajorLoader.styles.scss";

export const MajorLoader = () => {
  return (
    <div className="major-overlay">
      <Stack spacing={2} direction="column" className="loader-container">
        <div className="rotating-loader">
          <CircularProgress
            sx={{
              color: "transparent",
            }}
            size={45}
            thickness={4}
          />
          <img src={updatedLoader} alt="Loading..." className="custom-loader" />
        </div>
        <div className="hold-message">
          <span>Hold tight! We are generating your majors...</span>
        </div>
      </Stack>
    </div>
  );
};

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Document,
  Font,
  Image,
  Page as PDFPage,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { PDFLogo, deleteicon, upload } from "src/assets";
import { SignupButtonsLabel } from "src/enums/AppButtonEnums.enum";
import { StorageEnum } from "src/enums/AppLables.enum";
import { RouterPath } from "src/enums/RouterPath.enum";
import {
  deleteUserFavoritesAPI,
  getUserFavorites,
} from "src/services/account/Account.service";
import { LoadFavoritesAction } from "src/store/app.action";
import { getSessionStorage } from "src/utils/Cookie.util";
import { Loader } from "../loader/Loader";
import { useEvent } from "../navigation/EventProvider";
import { ProfileBackground } from "../profilepage/ProfileBackground";
import { SettingsBackgroundImage } from "../SettingsProfileBackgroundimage";
import "./Favourites.styles.scss";

export const UserFavourites = () => {
  const [page, setPage] = React.useState(0);
  const userEmail: any = getSessionStorage(StorageEnum.LoggedInUserEmail);
  const defaultTheme = createTheme();
  const [showLoader, setShowLoader] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState<any>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorPopUp, setErrorPopUp] = React.useState(false);
  const [clearAllPopUp, setClearAllPopUp] = React.useState(false);
  const [isTokenExpired, setIsTokenExpired] = React.useState(false);
  const { triggerEvent } = useEvent();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const systemDate = new Date().toLocaleDateString();

  const navigateToLogin = () => {
    navigate(RouterPath.Login);
    localStorage.clear();
    sessionStorage.clear();
  };

  const getFavoriesData = async () => {
    if (userEmail) {
      setShowLoader(true);
      await getUserFavorites(userEmail)
        .then((userFavorites) => {
          if (userFavorites?.data) {
            const sortedfav = userFavorites?.data?.sort((a: any, b: any) =>
              b.currentDate > a.currentDate ? 1 : -1
            );
            setShowLoader(false);
            setData(sortedfav);
            dispatch(LoadFavoritesAction(sortedfav));
          }
        })
        .catch((error: any) => {
          if (
            (error?.response && error?.response?.status === 401) ||
            (error?.response && error?.response?.status === 403)
          ) {
            setShowLoader(false);
            setIsTokenExpired(true);
            setErrorMessage(
              "Something went wrong while connecting to the API. Please restart the application."
            );
            setTimeout(() => {
              navigateToLogin();
              setIsTokenExpired(false);
            }, 3000);
          } else {
            setErrorPopUp(true);
            setIsTokenExpired(false);
            setShowLoader(false);
            setErrorMessage("Operation failed.");
            setTimeout(() => {
              setErrorPopUp(false);
            }, 3000);
          }
        });
    }
  };

  React.useEffect(() => {
    getFavoriesData();
  }, []);

  const handleChangePage = (event?: unknown, newPage?: number | any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event?.target?.value);
    setPage(0);
  };

  const handleClearAllClick = () => {
    setClearAllPopUp(true);
  };

  const handleClearAllOkayClick = async () => {
    const newData = data?.map((rowData: any) => rowData?.userFavoriteId);

    const payload = {
      favoriteIds: [...newData],
    };
    await deleteUserFavoritesAPI(payload, userEmail)
      .then((response: any) => {
        if (response) {
          setClearAllPopUp(false);
          getFavoriesData();
          triggerEvent({ message: "Event from Fav Delete" });
        } else {
          setClearAllPopUp(false);
        }
      })
      .catch((error: any) => {
        if (
          (error?.response && error?.response.status === 401) ||
          (error?.response && error?.response.status === 403)
        ) {
          setClearAllPopUp(false);
          setIsTokenExpired(true);
          setErrorMessage(
            "Something went wrong while connecting to the API. Please restart the application."
          );
          setTimeout(() => {
            setIsTokenExpired(false);
            navigateToLogin();
          }, 3000);
        } else {
          setErrorPopUp(true);
          setClearAllPopUp(false);
          setIsTokenExpired(false);
          setErrorMessage("Operation failed.");
          setTimeout(() => {
            setErrorPopUp(false);
          }, 3000);
        }
      });
  };

  const handleDeleteOkay = async (row: any) => {
    const payload = {
      favoriteIds: [row?.userFavoriteId],
    };
    await deleteUserFavoritesAPI(payload, userEmail)
      .then((response: any) => {
        if (response) {
          getFavoriesData();
          triggerEvent({ message: "Event from Fav Delete" });
        }
      })
      .catch((error: any) => {
        if (
          (error?.response && error?.response?.status === 401) ||
          (error?.response && error?.response?.status === 403)
        ) {
          setIsTokenExpired(true);
          setErrorMessage(
            "Something went wrong while connecting to the API. Please restart the application."
          );
          setTimeout(() => {
            navigateToLogin();
            setIsTokenExpired(false);
          }, 3000);
        } else {
          setErrorPopUp(true);
          setIsTokenExpired(false);
          setErrorMessage("Operation failed.");
          setTimeout(() => {
            setErrorPopUp(false);
          }, 3000);
        }
      });
  };

  const handleClearAllCancel = () => {
    setClearAllPopUp(false);
  };

  const formattedData = data?.map((item: any) => ({
    FavoriteName: item?.majorName,
    Description: item?.majorDescription,
    Interests: `${item?.firstCategoryTag || ""}${
      item?.secondCategoryTag ? ", " + item?.secondCategoryTag : ""
    }${item?.thirdCategoryTag ? ", " + item?.thirdCategoryTag : ""}`,
  }));

  Font?.register({
    family: "Inter",
    src: "/fonts/Inter-VariableFont_opsz,wght.ttf",
  });

  const pdfStyles = StyleSheet?.create({
    pdfTableBody: {
      padding: 20,
    },
    pdfSubHeader: {
      textAlign: "center",
      fontSize: 16,
      fontFamily: "Inter",
      color: "#101828",
      marginBottom: "5px",
      fontWeight: "extrabold",
    },
    pdfHeaderContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    pdfLeftContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    pdfLogo: {
      width: 19,
      height: 19,
      marginRight: 3,
    },
    pdfMainHeader: {
      fontSize: 18,
      color: "#101828",
      fontFamily: "Inter",
      fontWeight: "extrabold",
    },
    pdfdatesaved: {
      textAlign: "right",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#344054",
    },
    pdfTableHeader: {
      flexDirection: "row",
      backgroundColor: "#D5D9EB",
      color: "#000000",
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderColor: "#D5D9EB",
    },
    pdfHeaderCell: {
      flex: 1,
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "extrabold",
      borderRightWidth: 1,
      borderRightColor: "#D5D9EB",
      paddingVertical: 8,
      paddingHorizontal: 10,
    },
    pdfTableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderBottomColor: "#D5D9EB",
      borderLeftColor: "#D5D9EB",
    },
    pdfCell: {
      flex: 1,
      textAlign: "left",
      fontSize: 12,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
      borderRightWidth: 1,
      borderRightColor: "#D5D9EB",
      paddingVertical: 8,
      paddingHorizontal: 10,
    },
    pdfNameCell: {
      flex: 1.2,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
    pdfrowCount: {
      flex: 0.4,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
    pdfDescriptionCell: {
      flex: 3,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
    pdfInterestsCell: {
      flex: 1.5,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
  });

  const DownloadFavouritesPDF = () => {
    const pdfRecords = [];
    const recordsSize = 4;
    for (let i = 0; i < formattedData?.length; i += recordsSize) {
      pdfRecords?.push(formattedData.slice(i, i + recordsSize));
    }

    return (
      <Document>
        {pdfRecords?.map((record: any, pageIndex: any) => (
          <PDFPage
            key={pageIndex}
            style={pdfStyles?.pdfTableBody}
            size="A4"
            orientation="portrait"
          >
            {pageIndex === 0 && (
              <>
                <View style={pdfStyles?.pdfHeaderContainer}>
                  <View style={pdfStyles?.pdfLeftContainer}>
                    <Image src={PDFLogo} style={pdfStyles?.pdfLogo} />
                    <Text style={pdfStyles?.pdfMainHeader}>CollegeCrafter</Text>
                  </View>
                  <View>
                    <Text style={pdfStyles?.pdfdatesaved}>
                      Saved PDF - {`Saved on ${systemDate}`}
                    </Text>
                  </View>
                </View>

                <Text style={pdfStyles?.pdfSubHeader}> Favorites</Text>
              </>
            )}
            <View style={pdfStyles?.pdfTableHeader}>
              <Text style={[pdfStyles?.pdfHeaderCell, pdfStyles.pdfrowCount]}>
                S.NO
              </Text>
              <Text style={[pdfStyles?.pdfHeaderCell, pdfStyles.pdfNameCell]}>
                Favorite Name
              </Text>
              <Text
                style={[
                  pdfStyles?.pdfHeaderCell,
                  pdfStyles?.pdfDescriptionCell,
                ]}
              >
                Description
              </Text>
              <Text
                style={[pdfStyles?.pdfHeaderCell, pdfStyles?.pdfInterestsCell]}
              >
                Interests
              </Text>
            </View>
            {record.map((item: any, index: any) => (
              <View key={index} style={pdfStyles?.pdfTableRow}>
                <Text style={[pdfStyles?.pdfCell, pdfStyles?.pdfrowCount]}>
                  {pageIndex * recordsSize + index + 1}
                </Text>
                <Text style={[pdfStyles?.pdfCell, pdfStyles?.pdfNameCell]}>
                  {item?.FavoriteName}
                </Text>
                <Text
                  style={[pdfStyles?.pdfCell, pdfStyles?.pdfDescriptionCell]}
                >
                  {item?.Description}
                </Text>
                <Text style={[pdfStyles?.pdfCell, pdfStyles?.pdfInterestsCell]}>
                  {item?.Interests}
                </Text>
              </View>
            ))}
          </PDFPage>
        ))}
      </Document>
    );
  };

  const handleDownloadPDF = async () => {
    const doc = <DownloadFavouritesPDF />;
    const blob = await pdf(doc)?.toBlob();
    const link = document?.createElement("a");
    link.href = URL?.createObjectURL(blob);
    link.download = `CollegeCrafterFavorites${systemDate}.pdf`;

    link.click();
  };

  return (
    <div>
      <ProfileBackground>
        {showLoader && <Loader />}
        <ThemeProvider theme={defaultTheme}>
          <div className="user-favorites-page-content-sections">
            <div className="user-favorites-page-first-section">
              <SettingsBackgroundImage />
            </div>

            <div className="user-favorites-page-second-section">
              <div className="user-favorite-page-header-section">
                <span>Favorites</span>
              </div>
              <div className="user-favorites-action-buttons">
                <div className="user-favorite-page-savepdf-button-section">
                  {data?.length <= 0 ? (
                    <Button
                      variant="contained"
                      type="submit"
                      className="savepdf-button-disabled"
                    >
                      <img
                        src={upload}
                        alt={""}
                        className="savepdf-button-disabled-image"
                      ></img>
                      <span>{SignupButtonsLabel.SaveAsPDF}</span>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleDownloadPDF}
                      className="savepdf-button"
                    >
                      <img
                        src={upload}
                        alt={""}
                        className="savepdf-button-image"
                      ></img>
                      <span>{SignupButtonsLabel.SaveAsPDF}</span>
                    </Button>
                  )}
                </div>

                <div className="user-favorite-page-clear-button-section">
                  {data?.length <= 0 ? (
                    <Button
                      variant="contained"
                      type="submit"
                      className="clear-all-button-disabled"
                    >
                      <span>{SignupButtonsLabel.Clear}</span>
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleClearAllClick}
                      type="submit"
                      className="clear-all-button"
                    >
                      <span>{SignupButtonsLabel.Clear}</span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className="favorites-page-scroll-section">
              <div className="favorites-grid-section">
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: 330 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            colSpan={11}
                            className="main-row-alignment"
                          >
                            <span className="all-favorites-header">
                              {" "}
                              All favorites
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            className="column-headers"
                            key={"major"}
                            style={{ width: 600 }}
                          >
                            Major
                          </TableCell>

                          <TableCell
                            className="column-headers"
                            key={"tags"}
                            style={{ width: 400 }}
                          >
                            Interests
                          </TableCell>
                          <TableCell
                            className="column-headers"
                            key={"date"}
                            style={{ width: 160 }}
                          >
                            Date Saved
                          </TableCell>
                          <TableCell
                            className="column-headers"
                            key={"delete"}
                            style={{ width: 50 }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row: any, index: any) => (
                            <TableRow key={index}>
                              <TableCell>
                                <div style={{ marginBottom: "0.206rem" }}>
                                  <span className="favorite-name">
                                    {" "}
                                    {row?.majorName}{" "}
                                  </span>
                                </div>
                                <div>
                                  <span className="fav-description">
                                    {row?.majorDescription}
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="tags-align">
                                  {row?.firstCategoryTag?.replace(/%/g, "/")}
                                  {row?.secondCategoryTag &&
                                    `, ${row?.secondCategoryTag.replace(
                                      /%/g,
                                      "/"
                                    )}`}
                                  {row?.thirdCategoryTag &&
                                    `, ${row?.thirdCategoryTag.replace(
                                      /%/g,
                                      "/"
                                    )}`}
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="date-saved">
                                  {new Date(
                                    row?.currentDate
                                  )?.toLocaleDateString()}
                                </div>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => handleDeleteOkay(row)}
                                >
                                  <img src={deleteicon} alt={"delete"}></img>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    className="pagination-align"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </ThemeProvider>

        <Dialog open={clearAllPopUp} style={{ marginRight: "-60px" }}>
          <Paper style={{ width: "400px", height: "190px" }}>
            <DialogTitle>{"Warning"}</DialogTitle>{" "}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to clear all favorites? This action cannot
                be undone.
              </DialogContentText>
            </DialogContent>
            <div className="favorites-clear-section-action-btns">
              <div className="favorites-clear-cancel">
                <Button
                  variant="outlined"
                  onClick={handleClearAllCancel}
                  className="favorites-clear-cancel-btn-section"
                >
                  <span className="cancel-btn">
                    {SignupButtonsLabel.Cancel}
                  </span>
                </Button>
              </div>

              <div className="favorites-clear-clear-btn">
                <Button
                  type="submit"
                  variant="contained"
                  className="favorites-clear-clear-btn-section"
                  onClick={handleClearAllOkayClick}
                >
                  <span>{SignupButtonsLabel.Okay}</span>
                </Button>
              </div>
            </div>
          </Paper>
        </Dialog>
        <Dialog open={errorPopUp} style={{ marginRight: "-60px" }}>
          <Paper style={{ width: "400px", height: "110px" }}>
            <DialogTitle>{"Failed"}</DialogTitle>{" "}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errorMessage}
              </DialogContentText>
            </DialogContent>
          </Paper>
        </Dialog>
        <Dialog open={isTokenExpired} style={{ marginRight: "-60px" }}>
          <Paper style={{ width: "400px", height: "130px" }}>
            <DialogTitle>{"Something went wrong"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errorMessage}
              </DialogContentText>
            </DialogContent>
          </Paper>
        </Dialog>
      </ProfileBackground>
    </div>
  );
};

export enum UrlProvider {
  Register = "/api/v1/auth/register",
  Login = "/api/v1/auth/login",
  ResetPassword = "/api/v1/auth/forgetPassword",
  UserSettings = "/api/v1/userinfo/profile/info/{loginemail}",
  GenerateMajors = "/api/v1/findmajors/generate",
  PersonalInfo = "/api/v1/userinfo/personalinfo",
  UpdatePersonalInfo = "/api/v1/userinfo/personalinfo/update",
  profileInfo = "/api/v1/userinfo/profile/info",
  UpdateProfileInfo = "/api/v1/userinfo/profile/update",
  CreateProfileInfo = "/api/v1/userinfo/profile",
  DeleteProfileInfo = "/api/v1/users",
  GetUserFavorites = "/api/v1/favorite/user",
  GetUserSearchHistory = "/api/v1/Search/user",
  AddUserFavorites = "/api/v1/favorite/addFavorite/user",
  DeleteUserFavorites = "/api/v1/favorite/users/remove",
  DeleteUserSearchHistory = "/api/v1/Search/user/remove",
  UserSearchHistory = "/api/v1/Search/user/saveHistory",
  GetRefreshToken="/api/v1/auth/token/refresh",
}

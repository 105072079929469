import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.scss";
import { UserFavourites } from "./components/favourites/Favourites";
import { FindColleges } from "./components/findcolleges/FindColleges";
import { FindMajorsPage } from "./components/findmajors/FindMajors";
import { FindMajorsManualSearch } from "./components/findmajors/FindMajorsManualSearch";
import { FindMajorsSearchMode } from "./components/findmajors/FindMajorsSearchMode";
import { FindMentors } from "./components/findmentors/FindMentors";
import { FindScholarships } from "./components/findscholarships/FindScholarships";
import { ForgotPassword } from "./components/forgotpassword/ForgotPassword";
import { GenerateMajor } from "./components/genaratemajor/GenarateMajor";
import { Home } from "./components/home/Home";
import { LoginForm } from "./components/login/LoginForm";
import { EventProvider } from "./components/navigation/EventProvider";
import { ProfilePage } from "./components/profilepage/ProfilePage";
import { SearchHistory } from "./components/searchhistory/SearchHistory";
import { SignupForm } from "./components/signup/SignupForm";
import { StorageEnum } from "./enums/AppLables.enum";
import { RouterPath } from "./enums/RouterPath.enum";
import {
  getRefreshToken,
  getUserFavorites,
} from "./services/account/Account.service";
import { LoadFavoritesAction } from "./store/app.action";
import {
  SetSessionStorage,
  getSessionStorage,
  validateToken,
} from "./utils/Cookie.util";
import { PrivateRoute } from "./utils/PrivateRoutes";

export const App = () => {
  return (
    <EventProvider>
      <BrowserRouter>
        <Routers />
      </BrowserRouter>
    </EventProvider>
  );
};

export const Routers = () => {
  const userEmail: any = getSessionStorage(StorageEnum.LoggedInUserEmail);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const getFavorites = async () => {
    if (userEmail) {
      await getUserFavorites(userEmail)
        .then((userFavorites) => {
          if (userFavorites) {
            dispatch(LoadFavoritesAction(userFavorites?.data));
          }
        })
        .catch((error: any) => {
          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status === 403)
          ) {
            navigate(RouterPath.Login);
          } else {
            console.log(error);
          }
        });
    }
  };

  useEffect(() => {
    const checkIsValidToken = validateToken();
    getFavorites();
    if (!checkIsValidToken) {
      navigate(RouterPath.Login);
    }
  }, []);

  const handleRefreshToken = async () => {
    await getRefreshToken()
      .then((tokenResponse: any) => {
        if (tokenResponse?.data?.refreshToken) {
          SetSessionStorage(
            StorageEnum.AuthToken,
            tokenResponse.data?.refreshToken
          );
        } else {
          const loginPath = `${RouterPath.Login}`;

          localStorage.clear();
          sessionStorage.clear();
          navigate(loginPath);
        }
      })
      .catch((error) => {
        console.log("error", error);

        const loginPath = `${RouterPath.Login}`;
        localStorage.clear();
        sessionStorage.clear();
        navigate(loginPath);
      });
  };

  useEffect(() => {
    const tokenTime = setInterval(handleRefreshToken, 8 * 60 * 1000);

    return () => {
      clearInterval(tokenTime);
    };
  }, []);

  useEffect(() => {
    if (location.pathname) {
      SetSessionStorage("currentPath", location.pathname);
    }
  }, []);

  useEffect(() => {
    const storedCurrentPath = getSessionStorage("currentPath");
    const loginPath = `${RouterPath.Login}`;
    if (storedCurrentPath === "/") {
      navigate(loginPath);
    } else if (storedCurrentPath) {
      navigate(storedCurrentPath);
    } else {
      navigate(loginPath);
    }
  }, []);

  return (
    <Routes>
      <Route
        path={RouterPath.Root}
        element={<Navigate to={RouterPath.Login} />}
      />
      <Route
        path={RouterPath.Star}
        element={<PrivateRoute element={<LoginForm />} />}
      />
      <Route path={RouterPath.Register} element={<SignupForm />} />
      <Route path={RouterPath.Login} element={<LoginForm />} />
      <Route path={RouterPath.ForgotPassword} element={<ForgotPassword />} />
      <Route
        path={RouterPath.Home}
        element={<PrivateRoute element={<Home />} />}
      >
        <Route
          path={RouterPath.ProfilePage}
          element={<PrivateRoute element={<ProfilePage />} />}
        />
        <Route
          path={RouterPath.FindMajors}
          element={<PrivateRoute element={<FindMajorsPage />} />}
        />
        <Route
          path={RouterPath.FindMajorsSearchMode}
          element={<PrivateRoute element={<FindMajorsSearchMode />} />}
        />

        <Route
          path={RouterPath.FindMajorsManualSearch}
          element={<PrivateRoute element={<FindMajorsManualSearch />} />}
        />
        <Route
          path={RouterPath.GenerateMajors1}
          element={<PrivateRoute element={<GenerateMajor />} />}
        />
        <Route
          path={RouterPath.GenerateMajors2}
          element={<PrivateRoute element={<GenerateMajor />} />}
        />
        <Route
          path={RouterPath.GenerateMajors3}
          key={getSessionStorage(StorageEnum.AuthToken)}
          element={<PrivateRoute element={<GenerateMajor />} />}
        />
        <Route
          path={RouterPath.Favorties}
          element={<PrivateRoute element={<UserFavourites />} />}
        />
        <Route
          path={RouterPath.MenuFavorites}
          element={<PrivateRoute element={<UserFavourites />} />}
        />
        <Route
          path={RouterPath.SearchHistory}
          element={<PrivateRoute element={<SearchHistory />} />}
        />
        <Route
          path={RouterPath.SubSearchHistory}
          element={<PrivateRoute element={<SearchHistory />} />}
        />
        <Route
          path={RouterPath.FindColleges}
          element={<PrivateRoute element={<FindColleges />} />}
        />
        <Route
          path={RouterPath.FindMentors}
          element={<PrivateRoute element={<FindMentors />} />}
        />
        <Route
          path={RouterPath.FindScholarships}
          element={<PrivateRoute element={<FindScholarships />} />}
        />
      </Route>
    </Routes>
  );
};

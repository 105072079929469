import { Grid } from "@mui/material";

import Signup from "../../assets/Signup.png";

export const BackgroundImage = () => {
  return (
    <Grid
      className="collegecrafter-background-image"
      item
      xs={false}
      sm={false}
      md={6}
      lg={6}
      sx={{
        backgroundImage: `url('${Signup}')`,
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    />
  );
};

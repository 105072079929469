import React from "react";
import { useNavigate } from "react-router";
import { StorageEnum } from "src/enums/AppLables.enum";
import { RouterPath } from "src/enums/RouterPath.enum";
import { getSessionStorage } from "src/utils/Cookie.util";

interface PrivateRouteProps {
  element: React.ReactElement;
  path?: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps | any> = ({
  element,
}) => {
  const authToken = getSessionStorage(StorageEnum.AuthToken);
  const navigate = useNavigate();
  const backToLogin = `${RouterPath.Login}`;

  if (!authToken) {
    return navigate(backToLogin);
  } else {
    return element;
  }
};

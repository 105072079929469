export enum SignupButtonsLabel {
  CreateAccount = "Create Account",
  SignupGoogle = "Sign up with Google",
  SigninGoogle = "Sign in with Google",
  save = "Save New Password",
  Signin = "Sign In",
  Cancel = "Cancel",
  SaveChanges = "Save changes",
  ExportList = "Export List",
  SaveAsPDF ="Save PDF",
  ShareEmail="Share",
  Clear = "Clear All",
  previoues = "Previoues",
  next = "Next",
  Okay = "Okay",
  DELETE='Delete'
}

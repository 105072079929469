export enum RouterPath {
  Login = "/login",
  Root = "/",
  Star = "*",
  Register = "/register",
  ForgotPassword = "/forgotpassword",
  Home = "/home",
  FindMajors = "/home/findmajors",
  FindMajorsManualSearch = "/home/findmajorsmanualsearch",
  FindMajorsSearchMode = "/home/findmajorssearchmode",
  ProfileSettings = "/home/profilesettings",
  ProfilePage = "/home/profilepage",
  Favorties = "/home/favorties",
  MenuFavorites = "/home/menufavorites",
  SubSearchHistory= "/home/subsearchhistory",
  SearchHistory = "/home/searchhistory",
  FindColleges="/home/findcolleges",
  FindMentors="/home/findmentors",
  FindScholarships="/home/findscholarships",
  GenerateMajors1="/home/generatemajor/:firstCategory",
  GenerateMajors2="/home/generatemajor/:firstCategory/:secondCategory",
  GenerateMajors3="/home/generatemajor/:firstCategory/:secondCategory/:thirdCategory"

}

import { PageProps } from "src/interfaces/account/Account";

import { Box, CssBaseline } from "@mui/material";
import "./ProfileBackground.styles.scss";

export const ProfileBackground = ({ children }: PageProps) => {
  return (
    <Box
      sx={{
        height: "100vh",
        position: "relative  !important",
        padding: "2.5rem  !important",
        background: "rgb(249, 248, 248)  !important",
        overflow: "hidden  !important",
        overflowX: "hidden !important",
        scrollBehavior: "smooth",
        fontSize: 13,
        width: "calc(100vw - 240px)  !important",
      }}
    >
      <CssBaseline />
      {children}
    </Box>
  );
};

import { StorageEnum } from "src/enums/AppLables.enum";
import { RouterPath } from "src/enums/RouterPath.enum";

export const setCookie = (value: string) => {
  const expirationDays = 30;
  const expirationDate = new Date();
  const cookieValue = `${encodeURIComponent(value)}`;

  // expirationDate.setTime(expirationDate.getTime() + 0.5 * 60 * 1000);
  expirationDate.setDate(expirationDate.getDate() + expirationDays);

  document.cookie = `${StorageEnum.AuthToken}=${cookieValue}`;
  document.cookie = `${
    StorageEnum.ExpirationDate as string
  }=${expirationDate.toUTCString()}`;
  document.cookie = `${StorageEnum.Path as string}=${RouterPath.Login}`;
};

export const SetSessionStorage = (key: string, value: string | any) => {
  sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

export const getCookie = (name: string | StorageEnum) => {
  const cookieName = name + "=";
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName)) {
      return decodeURIComponent(cookie.substring(cookieName.length));
    }
  }

  return null;
};

export const removeCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const extractExpirationDate = () => {
  try {
    const expirationDate = getCookie(StorageEnum.ExpirationDate);

    if (expirationDate) {
      return new Date(expirationDate);
    }
  } catch (error) {
    console.error("Error parsing cookie:", error);
  }

  return null;
};

export const validateToken = (): boolean => {
  const tokenFromCookie = getCookie(StorageEnum.ExpirationDate);

  if (tokenFromCookie) {
    const expirationDate = extractExpirationDate();
    const isTokenExpired = expirationDate && expirationDate < new Date();

    if (isTokenExpired) {
      removeCookie(StorageEnum.AuthToken);
      removeCookie(StorageEnum.ExpirationDate);
      removeCookie(StorageEnum.Path);
      return false;
    }
  }
  return true;
};

export const getBearerToken = () => {
  const tokenFromCookie = getCookie(StorageEnum.AuthToken);
  const tokenFromSession = getSessionStorage(StorageEnum.AuthToken);

  return tokenFromCookie
    ? tokenFromCookie
    : tokenFromSession
    ? tokenFromSession
    : null;
};

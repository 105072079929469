import { Page } from "../page/Page";

import "./FindScholarships.styles.scss";

export const FindScholarships = () => {
  return (
    <Page>
      <div>
        <h1 className="find-scholarships-page">In Progress</h1>
      </div>
    </Page>
  );
};

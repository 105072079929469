import { PageProps } from "src/interfaces/account/Account";

import { Box, CssBaseline } from "@mui/material";
import "./Page.styles.scss";

export const Page = ({ children }: PageProps) => {
  return (
    <Box
      sx={{
        height: "100vh",
        position: "relative  !important",
        padding: "2.5rem  !important",
        background: "var(--Blue-gray-200, #D5D9EB)  !important",
        overflow: "auto  !important",
        overflowX: "hidden !important",
        scrollBehavior: "smooth",
        fontSize: 13,
        width: "calc(100vw - 240px)  !important",
      }}
    >
      <CssBaseline />
      {children}
    </Box>
  );
};

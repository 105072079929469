import { Icon, Typography } from "@mui/material";
import { collegecraftlogo } from "src/assets";

import "./SignupLog.styles.scss";

export const CollegeCrafterLogoForSignUp = () => {
  return (
    <div className="signup-college-application-header">
      <Typography width={"100%"} className="signup-collegecraft-headername">
        <Icon className="signup-collgecrafticon">
          <img src={collegecraftlogo} alt="image not found" />
        </Icon>
        CollegeCrafter
      </Typography>
    </div>
  );
};

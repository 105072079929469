import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RouterPath } from "src/enums/RouterPath.enum";
import {
  StringConstant,
  emailRegex,
  passwordRegex,
} from "src/enums/StringConstant";
import { CollegeCrafterLogo } from "src/header/CollegeCrafterlogo";
import { Login, getUserFavorites } from "src/services/account/Account.service";
import { LoadFavoritesAction } from "src/store/app.action";
import { SetSessionStorage } from "src/utils/Cookie.util";
import "../../App.scss";
import { SignupButtonsLabel } from "../../enums/AppButtonEnums.enum";
import { SignupErrorsEnum } from "../../enums/AppErrorsEnum.enum";
import { SignupLabels, StorageEnum } from "../../enums/AppLables.enum";
import { SignupPlaceholders } from "../../enums/AppPlaceholderEnums.enum";
import { BackgroundImage } from "../backgroundimage/BackgroundImage";
import { CollegeCrafterEmail } from "../footer/CollegeCrafterEmail";
import { Copyright } from "../footer/CopyRight";
import { Loader } from "../loader/Loader";
import "./LoginForm.styles.scss";

export const LoginForm = () => {
  const defaultTheme = createTheme();
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const navigateToSignup = () => {
    navigate(RouterPath.Register);
  };

  const navigateToHome = () => {
    navigate(RouterPath.Home);
  };

  const navigateToForgot = () => {
    navigate(RouterPath.ForgotPassword);
  };

  const [formData, setFormData] = React.useState({
    email: StringConstant.EMPTY,
    password: StringConstant.EMPTY,
  });

  const [validationErrors, setValidationErrors] = React.useState({
    email: StringConstant.EMPTY,
    password: StringConstant.EMPTY,
  });

  const validateEmail = (email: any) => {
    return emailRegex.test(email);
  };

  const validatePassword = (password: any) => {
    return passwordRegex.test(password);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === SignupErrorsEnum.Email) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        email: validateEmail(value)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.EmailRequired,
      }));
    }
  };

  const handlePwdInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    if (name === SignupErrorsEnum.Password) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: validatePassword(value)
          ? StringConstant.EMPTY
          : SignupErrorsEnum.PasswordRequired,
      }));
    }
  };

  const loginUser = async () => {
    setShowLoader(true);
    const payload = {
      email: formData?.email?.trim(),
      password: formData?.password?.trim(),
    };
    await Login(payload)
      .then(async (userData) => {
        if (userData?.data) {
          const authToken = userData.data.token;
          const firstname = userData?.data?.user?.firstname;
          const lastname = userData?.data?.user?.lastname;
          const userEmail = userData?.data?.user?.email;
          const fullName = `${firstname} ${lastname}`;
          const userAvatarId = userData?.data?.avatarId ?? 1;
          const userID = userData?.data?.user?.user_id;

          SetSessionStorage(StorageEnum.AuthToken, authToken as string);
          SetSessionStorage(StorageEnum.userID, userID as number);
          SetSessionStorage(StorageEnum.UserAvatar, userAvatarId as number);
          SetSessionStorage(StorageEnum.LoggedInUsername, fullName as string);
          SetSessionStorage(StorageEnum.LoggedInUserEmail, userEmail as string);

          if (userEmail) {
            await getUserFavorites(userEmail)
              .then((userFavorites) => {
                if (userFavorites) {
                  dispatch(LoadFavoritesAction(userFavorites?.data));
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }

          setShowLoader(false);
          navigateToHome();
        }
      })
      .catch((errorData) => {
        console.log(errorData);
        setShowLoader(false);
        setApiResponse(true);
      });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const formErrors = {
      email: validateEmail(formData.email)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.EmailRequired,
      password: validatePassword(formData.password)
        ? StringConstant.EMPTY
        : SignupErrorsEnum.PasswordRequired,
    };

    setValidationErrors(formErrors);

    if (formErrors.email || formErrors.password) {
      setApiResponse(false);
      return;
    } else {
      loginUser();
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" className="login-main-grid-section">
        <CssBaseline />{" "}
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <CollegeCrafterLogo />

          <Box className="collecraft-login-form-section">
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              className="collecraft-login-form-contents"
            >
              <div className="header-section-aligmenent">
                <Typography
                  component="h1"
                  variant="h5"
                  className="login-header-section"
                >
                  {SignupLabels.Login}
                </Typography>
              </div>
              <div className="welcomeback-text-section">
                <Typography className="welcomeback-text">
                  Welcome back! Please enter your details.
                </Typography>
              </div>
              <div className="input-element-layout">
                <span>{SignupLabels.LoginEmail}</span>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="new-email"
                  className="input-element"
                  id="email"
                  placeholder={SignupPlaceholders.EnterEmail}
                  name="email"
                  value={formData.email}
                  error={!!validationErrors.email}
                  helperText={validationErrors.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="input-element-layout">
                <span>{SignupLabels.LoginPaasword}</span>
                <TextField
                  margin="normal"
                  required
                  autoComplete="new-password"
                  className="input-element"
                  name="password"
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  error={!!validationErrors.password}
                  onChange={handlePwdInputChange}
                  helperText={validationErrors.password}
                  placeholder={SignupPlaceholders.EnterForgotPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className="custom-input-adornment"
                      >
                        <IconButton
                          className="eye-icon-alignment"
                          onClick={handleTogglePasswordVisibility}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="remember-container">
                <div className="checkbox-section"></div>
                <div className="login-forgot-password">
                  <Link
                    href="#"
                    variant="body2"
                    className="login-forgot-pwd-label"
                    onClick={navigateToForgot}
                  >
                    Forgot password
                  </Link>
                </div>
              </div>

              <div>
                <div className="login-api-error-message">
                  {apiResponse && (
                    <Typography color="error" variant="body2">
                      {"Incorrect username or password"}
                    </Typography>
                  )}
                </div>
                <div className="sign-in-btn-align">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={
                      formData?.email?.length === 0 ||
                      formData?.password?.length === 0
                        ? "login-sign-btn-disable-mode "
                        : "login-sign-btn"
                    }
                    disabled={
                      formData?.email?.length === 0 ||
                      formData?.password?.length === 0
                    }
                  >
                    <Typography style={{ textTransform: "none" }}>
                      {SignupButtonsLabel.Signin}
                    </Typography>
                  </Button>
                </div>
              </div>

              <div>
                <div className="donthave-account-section">
                  <label className="have-account">Don’t have an account?</label>
                  <Link
                    href="#"
                    variant="body2"
                    className="dont-have-account-signup"
                    onClick={navigateToSignup}
                  >
                    {" "}
                    {SignupLabels.Signup}
                  </Link>
                </div>
              </div>
              <div className="login-bottom-section">
                <Copyright />
                <CollegeCrafterEmail />
              </div>
            </Box>
          </Box>
        </Grid>
        {showLoader && <Loader />}
        <BackgroundImage />
      </Grid>
    </ThemeProvider>
  );
};

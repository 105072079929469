export enum SignupLabels {
  FirstName = "First Name*",
  LastName = "Last Name*",
  EmailAddress = "Email*",
  Password = "Password*",
  PasswordRestiction = "Must be at least 8 characters long and should contain a combination of letters, numbers, and other characters",
  TermsConditions = "I have read the Terms of service agreement conditions",
  HaveanAccount = "Already have an account?",
  CopyRights = "CollegeCraft2024©",
  ContactEmail = "help@collgecraft.com",
  Login = "Log In",
  Signup = "Sign Up",
  ServiceAgreement = "I have read the",
  retypepassword = "Retype Password*",
  ForgotPasswordEmail = "Email*",
  ForgotPasswordNewPwd = "New Password*",
  LoginEmail = "Email*",
  LoginPaasword = "Password*",
  Username = "Username*",
  Country = "Country*",
  CurrentHighSchool = "Current High School*",
  Graduation = "Graduation Year*",
  ZipCode = "Zip Code*",
  Gender = "Gender*",
  RaceEthnicity = "Race/Ethnicity",
  States ="States*"
}

export enum StorageEnum {
  AuthToken = "authToken",
  ExpirationDate = "expirationDate",
  Path = "path",
  LoggedInUsername = "username",
  LoggedInUserEmail = "useremail",
  UserAvatar="userAvatar",
  userID="userID"
}

export enum DrawerEnum {
  permanent = "permanent",
  persistent = "persistent",
  temporary = "temporary",
}

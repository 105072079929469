import { eyeIcon, heart } from "src/assets";
import { Page } from "../page/Page";

import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { RouterPath } from "src/enums/RouterPath.enum";
import "./FindMajorsSearchMode.styles.scss";

export const FindMajorsSearchMode = () => {
  const navigate = useNavigate();

  const handleManualSearchClick = () => {
    navigate(RouterPath.FindMajorsManualSearch);
  };
  const handleAISearchClick = () => {
    navigate(RouterPath.FindMajors);
  };

  return (
    <Page>
      <div className="manualsearch-ai-boxes-sections">
        <div className="second-box-section">
          <div className="second-box-content-section">
            <div className="eye-section">
              <img src={eyeIcon} alt="" />
            </div>
            <div className="ai-search-first-header-section">
              <div>
                <span> I do not know what I </span>
              </div>
              <div>
                <span>want to major in</span>
              </div>
            </div>
            <div className="ai-search-second-header-section">
              <div>
                <span>We’re here to help! We can </span>
              </div>
              <div>
                <span>share some ideas based on your choices.</span>
              </div>
            </div>
            <div className="get-started-btn-section">
              <div id="generate-major-aibtn" className="get-started-btn">
                <Button
                  id="start"
                  onClick={handleAISearchClick}
                  variant="contained"
                  className="get-started-btn-text"
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="first-box-section">
          <div className="first-box-content-section">
            <div className="eye-section">
              <img src={heart} alt="" />
            </div>
            <div className="manualsearch-first-header-section">
              <div>
                <span> I know some majors I </span>
              </div>
              <div>
                <span>may be interested in</span>
              </div>
            </div>
            <div className="manualsearch-second-header-section">
              <div>
                <span>Great! Click below to get started and</span>
              </div>

              <div>
                <span>share what you want to major in.</span>
              </div>
            </div>
            <div className="get-started-btn-section">
              <div id="generate-major-btn" className="get-started-btn">
                <Button
                  id="start"
                  onClick={handleManualSearchClick}
                  variant="contained"
                  className="get-started-btn-text"
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

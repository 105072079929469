import avatar1 from "../assets/avatars/avatar1.svg";
import avatar10 from "../assets/avatars/avatar10.svg";
import avatar11 from "../assets/avatars/avatar11.svg";
import avatar12 from "../assets/avatars/avatar12.svg";
import avatar13 from "../assets/avatars/avatar13.svg";
import avatar14 from "../assets/avatars/avatar14.svg";
import avatar15 from "../assets/avatars/avatar15.svg";
import avatar16 from "../assets/avatars/avatar16.svg";
import avatar17 from "../assets/avatars/avatar17.svg";
import avatar18 from "../assets/avatars/avatar18.svg";
import avatar19 from "../assets/avatars/avatar19.svg";
import avatar2 from "../assets/avatars/avatar2.svg";
import avatar20 from "../assets/avatars/avatar20.svg";
import avatar21 from "../assets/avatars/avatar21.svg";
import avatar22 from "../assets/avatars/avatar22.svg";
import avatar23 from "../assets/avatars/avatar23.svg";
import avatar24 from "../assets/avatars/avatar24.svg";
import avatar25 from "../assets/avatars/avatar25.svg";
import avatar26 from "../assets/avatars/avatar26.svg";
import avatar27 from "../assets/avatars/avatar27.svg";
import avatar28 from "../assets/avatars/avatar28.svg";
import avatar29 from "../assets/avatars/avatar29.svg";
import avatar3 from "../assets/avatars/avatar3.svg";
import avatar4 from "../assets/avatars/avatar4.svg";
import avatar5 from "../assets/avatars/avatar5.svg";
import avatar6 from "../assets/avatars/avatar6.svg";
import avatar7 from "../assets/avatars/avatar7.svg";
import avatar8 from "../assets/avatars/avatar8.svg";
import avatar9 from "../assets/avatars/avatar9.svg";


export const avatarsGroup = [
    { id: 1, src: avatar1 },
    { id: 2, src: avatar2 },  
    { id: 3, src: avatar3 },
    { id: 4, src: avatar4 },
    { id: 5, src: avatar5 },
    { id: 6, src: avatar6 },
    { id: 7, src: avatar7 },
    { id: 8, src: avatar8 },
    { id: 9, src: avatar9 },
    { id: 10, src: avatar10 },
    { id: 11, src: avatar11 },
    { id: 12, src: avatar12 },
    { id: 13, src: avatar13 },
    { id: 14, src: avatar14 },
    { id: 15, src: avatar15 },
    { id: 16, src: avatar16 },
    { id: 17, src: avatar17 },
    { id: 18, src: avatar18 },
    { id: 19, src: avatar19 },
    { id: 20, src: avatar20 },
    { id: 21, src: avatar21 },
    { id: 22, src: avatar22 },
    { id: 23, src: avatar23 },
    { id: 24, src: avatar24 },
    { id: 25, src: avatar25 },
    { id: 26, src: avatar26 },
    { id: 27, src: avatar27 },
    { id: 28, src: avatar28 },
    { id: 29, src: avatar29 },
  
];

import axios, { AxiosResponse } from "axios";
import { getBearerToken } from "src/utils/Cookie.util";

const getHeaders = (isTokenExist: string | null) => {
  return isTokenExist
    ? {
      "Content-Type": "application/json",
      Authorization: `Bearer ${isTokenExist}`,
    }
    : {
      "Content-Type": "application/json",
    };
};

export const PostApi = (
  params?: any,
  apiUrl?: string
): Promise<AxiosResponse<any, any>> => {
  const isTokenExist = getBearerToken();
  const headers = getHeaders(isTokenExist);
  return axios({
    url: apiUrl,
    method: "POST",
    withCredentials: true,
    headers: headers,
    data: params,
  });
};

export const getApi = (
  params?: any,
  apiUrl?: string
): Promise<AxiosResponse<any, any>> => {
  const isTokenExist = getBearerToken();
  const headers = getHeaders(isTokenExist);
  return axios({
    url: apiUrl,
    method: "GET",
    withCredentials: true,
    headers,
    data: params,
  });
};

export const getUserInfoApi = (
  apiUrl?: string
): Promise<AxiosResponse<any, any>> => {
  const isTokenExist = getBearerToken();
  const headers = getHeaders(isTokenExist);
  return axios({
    url: apiUrl,
    method: "GET",
    withCredentials: true,
    headers,
  });
};

export const getRefreshTokenOperation = (
  apiUrl?: string
): Promise<AxiosResponse<any, any>> => {
  const isTokenExist = getBearerToken();
  const headers = getHeaders(isTokenExist);
  return axios({
    url: apiUrl,
    method: "GET",
    withCredentials: true,
    headers,
  });
};



export const PutApi = (
  params?: any,
  apiUrl?: string
): Promise<AxiosResponse<any, any>> => {
  return axios({
    url: apiUrl,
    method: "PUT",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getBearerToken()}`,
    },
    data: params,
  });
};

export const DeleteApi = (
  params?: any,
  apiUrl?: string
): Promise<AxiosResponse<any, any>> => {
  return axios({
    url: apiUrl,
    method: "DELETE",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getBearerToken()}`,
    },
    data: params,
  });
};


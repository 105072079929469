import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import {
  Document,
  Font,
  Image,
  pdf,
  Page as PDFPage,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  arraw,
  barchart,
  collegegrouplogo,
  findmajors,
  generatebook,
  greenloveicon,
  historybarchat,
  historyhome,
  home,
  loveicon,
  notablepeople,
  PDFLogo,
  persons,
  upload,
} from "src/assets";
import { SignupButtonsLabel } from "src/enums/AppButtonEnums.enum";
import { DrawerEnum, StorageEnum } from "src/enums/AppLables.enum";
import { RouterPath } from "src/enums/RouterPath.enum";
import { NotablePerson } from "src/interfaces/account/Account";
import {
  addFavoritesRequest,
  generateMajorsRequest,
  saveHistoryOperation,
} from "src/services/account/Account.service";
import { LoadFavoritesAction } from "src/store/app.action";
import { selectedFavorites } from "src/store/app.selector";
import { getSessionStorage } from "src/utils/Cookie.util";
import { MajorLoader } from "../loader/MajorLoader";
import { Page } from "../page/Page";
import "./GenarateMajor.styles.scss";

export const GenerateMajor = () => {
  const params = useParams();
  const data = useSelector(selectedFavorites);
  const [isSettingsDrawerOpen, setIsSettingsDrawerOpen] = React.useState(false);
  const [currentMajor, setCurrentMajor] = useState<any>(null);
  const [showLoader, setShowLoader] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const userEmail: any = getSessionStorage(StorageEnum.LoggedInUserEmail);
  const userID: any = getSessionStorage(StorageEnum.userID);
  const navigate = useNavigate();
  const systemDate = new Date().toLocaleDateString();
  const [generateMajorsResponse, setGenerateMajorsResponse] = useState(
    [] as {
      majorName: string;
      majorDescription: string;
      possibleCareers: string[];
      medianIncome: string;
      notablePeople:
        | { notablePeopleName: string; notablePeopleDescription: string }[]
        | any;
      isFavorite: boolean;
    }[]
  );
  const today = new Date();
  const monthAbbreviation = today.toLocaleString("default", { month: "short" });
  const day = today.getDate();
  const dispatch = useDispatch();
  const year = today.getFullYear();
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const amOrPm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedDate = `${monthAbbreviation} ${day} ${year}, ${formattedHours}:${formattedMinutes}${amOrPm}`;
  const tags = [
    params?.firstCategory?.replace(/%/g, "/"),
    params?.secondCategory?.replace(/%/g, "/"),
    params?.thirdCategory?.replace(/%/g, "/"),
  ];

  const navigateToLogin = () => {
    navigate(RouterPath.Login);
    localStorage.clear();
    sessionStorage.clear();
  };

  const handleSettingsClick = (major: any) => {
    setCurrentMajor(major);
    setIsSettingsDrawerOpen(!isSettingsDrawerOpen);
  };

  const handleMainDrawerClose = () => {
    setIsSettingsDrawerOpen(false);
  };

  const generateMajorsOperation = async () => {
    setShowLoader(true);
    const requestBody = {
      firstCategory: params?.firstCategory || "",
      secondCategory: params?.secondCategory || "",
      thirdCategory: params?.thirdCategory || "",
    };
    await generateMajorsRequest(requestBody, userID)
      .then((response) => {
        if (response?.data?.length > 0) {
          setShowLoader(false);

          const updatedResponse = response?.data?.map((mapData: any) => {
            const favorite = data?.some(
              (favData: any) => favData?.majorName === mapData?.majorName
            );

            return {
              ...mapData,
              isFavorite: favorite,
            };
          });
          setGenerateMajorsResponse(updatedResponse);
        }
      })
      .catch((error: any) => {
        if (
          (error.response && error.response.status === 401) ||
          (error.response && error.response.status == 401) ||
          (error.response && error.response.status === 403) ||
          (error.response && error.response.status == 403)
        ) {
          setIsTokenExpired(true);
          setShowLoader(false);
          setErrorMessage(
            "Something went wrong while connecting to the API. Please restart the application."
          );
          setTimeout(() => {
            setIsTokenExpired(false);
            navigateToLogin();
          }, 3000);
        } else {
          setErrorPopUp(true);
          setShowLoader(false);
          setIsTokenExpired(false);
          setErrorMessage("Operation failed.");
          setTimeout(() => {
            setErrorPopUp(false);
            navigate(RouterPath.FindMajorsSearchMode);
          }, 3000);
        }
      });
  };

  useEffect(() => {
    generateMajorsOperation();
  }, [params]);

  const handleSettingsDrawer = () => {
    if (isSettingsDrawerOpen) {
      return DrawerEnum?.permanent;
    } else {
      return DrawerEnum?.temporary;
    }
  };

  const handleAddFavorites = async (rowData: any) => {
    if (!rowData?.isFavorite) {
      rowData.isFavorite = true;

      const payload = {
        firstCategoryTag: params?.firstCategory || "",
        secondCategoryTag: params?.secondCategory || "",
        thirdCategoryTag: params?.thirdCategory || "",
        majorName: rowData?.majorName || "",
        majorDescription: rowData?.majorDescription || "",
        medianIncome: rowData?.medianIncome,
        notablePeople: rowData?.notablePeople?.map((mapData: any) => ({
          notablePeopleName: mapData?.notablePeopleName,
          notablePeopleDescription: mapData?.notablePeopleDescription,
        })),
        possibleCareers: rowData?.possibleCareers?.map(
          (mapData: any) => mapData
        ),
      };

      await addFavoritesRequest(payload, userEmail)
        .then((response) => {
          if (response) {
            dispatch(LoadFavoritesAction([...data, payload]));
          }
        })
        .catch((error: any) => {
          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status === 403)
          ) {
            setIsTokenExpired(true);
            setShowLoader(false);
            setErrorMessage(
              "Something went wrong while connecting to the API. Please restart the application."
            );
            setTimeout(() => {
              setIsTokenExpired(false);
              navigateToLogin();
            }, 3000);
          } else {
            setErrorPopUp(true);
            setShowLoader(false);
            setIsTokenExpired(false);
            setErrorMessage("Operation failed.");
            setTimeout(() => {
              setErrorPopUp(false);
            }, 3000);
          }
        });
    }
  };

  const handleSearchedMajors = async () => {
    if (generateMajorsResponse?.length > 0) {
      const finalPayload: any =
        generateMajorsResponse?.map((searchData) => {
          return {
            firstCategoryTag: params?.firstCategory || "",
            secondCategoryTag: params?.secondCategory || "",
            thirdCategoryTag: params?.thirdCategory || "",
            majorName: searchData?.majorName || "",
            majorDescription: searchData?.majorDescription || "",
            medianIncome: searchData?.medianIncome || "",
            notablePeople:
              searchData?.notablePeople?.map(
                (mapData: any): NotablePerson => ({
                  notablePeopleName: mapData?.notablePeopleName || "",
                  notablePeopleDescription:
                    mapData?.notablePeopleDescription || "",
                })
              ) || [],
            possibleCareers: searchData?.possibleCareers || [],
          };
        }) || [];
      await saveHistoryOperation(finalPayload, userEmail)
        .then((respone) => {})
        .catch((error: any) => {
          if (
            (error.response && error.response.status === 401) ||
            (error.response && error.response.status == 401) ||
            (error.response && error.response.status === 403) ||
            (error.response && error.response.status == 403)
          ) {
            setIsTokenExpired(true);
            setShowLoader(false);
            setErrorMessage(
              "Something went wrong while connecting to the API. Please restart the application."
            );
            setTimeout(() => {
              setIsTokenExpired(false);
              navigateToLogin();
            }, 3000);
          } else {
            setErrorPopUp(true);
            setShowLoader(false);
            setIsTokenExpired(false);
            setErrorMessage("Operation failed.");
            setTimeout(() => {
              setErrorPopUp(false);
              navigate(RouterPath.FindMajorsSearchMode);
            }, 3000);
          }
        });
    }
  };

  const majorsResponseLength = generateMajorsResponse?.length > 0;

  useEffect(() => {
    handleSearchedMajors();
  }, [majorsResponseLength]);

  const formattedData = generateMajorsResponse?.map((item: any) => ({
    MajorName: item?.majorName,
    MajorDescription: item?.majorDescription,
    PossibleCareers: item?.possibleCareers?.map(
      (possibleData: any) => possibleData
    ),
    NotablePeople: item?.notablePeople?.map(
      (notablePersons: any) => notablePersons?.notablePeopleName
    ),
    MedianIncome: item?.medianIncome,
  }));

  Font.register({
    family: "Inter",
    src: "/fonts/Inter-VariableFont_opsz,wght.ttf",
  });

  const pdfStyles = StyleSheet?.create({
    pdfTableBody: {
      padding: 20,
    },
    pdfSubHeader: {
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Inter",
      color: "#101828",
      marginBottom: "5px",
      fontWeight: "extrabold",
    },
    pdfHeaderContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    pdfLeftContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    pdfLogo: {
      width: 19,
      height: 19,
      marginRight: 3,
    },
    pdfMainHeader: {
      fontSize: 18,
      color: "#101828",
      fontFamily: "Inter",
      fontWeight: "extrabold",
    },
    pdfdatesaved: {
      textAlign: "right",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#344054",
    },
    pdfTableHeader: {
      flexDirection: "row",
      backgroundColor: "#D5D9EB",
      color: "#000000",
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderColor: "#D5D9EB",
    },
    pdfHeaderCell: {
      flex: 1,
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "extrabold",
      borderRightWidth: 1,
      borderRightColor: "#D5D9EB",
      paddingVertical: 8,
      paddingHorizontal: 10,
    },
    pdfTableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderBottomColor: "#D5D9EB",
      borderLeftColor: "#D5D9EB",
    },
    pdfCell: {
      flex: 1,
      textAlign: "left",
      fontSize: 12,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
      borderRightWidth: 1,
      borderRightColor: "#D5D9EB",
      paddingVertical: 8,
      paddingHorizontal: 10,
    },
    majorNameCell: {
      flex: 1,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
    pdfrowCount: {
      flex: 0.4,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
    pdfDescriptionCell: {
      flex: 2.3,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
    possibleCareersCell: {
      flex: 1.4,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
    notablePeopleCell: {
      flex: 0.9,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
    medianIncomeCell: {
      flex: 0.6,
      textAlign: "left",
      fontSize: 10,
      fontFamily: "Inter",
      color: "#101828",
      fontWeight: "normal",
    },
  });

  const DownloadMajorsPDF = () => {
    const pdfRecords = [];
    const recordsSize = 6;

    for (let i = 0; i < formattedData?.length; i += recordsSize) {
      pdfRecords?.push(formattedData.slice(i, i + recordsSize));
    }

    return (
      <Document>
        {pdfRecords?.map((record: any, pageIndex: any) => (
          <PDFPage
            key={pageIndex}
            style={pdfStyles?.pdfTableBody}
            size="A4"
            orientation="portrait"
          >
            {pageIndex === 0 && (
              <>
                <View style={pdfStyles?.pdfHeaderContainer}>
                  <View style={pdfStyles?.pdfLeftContainer}>
                    <Image src={PDFLogo} style={pdfStyles?.pdfLogo} />
                    <Text style={pdfStyles?.pdfMainHeader}>CollegeCrafter</Text>
                  </View>
                  <View>
                    <Text style={pdfStyles?.pdfdatesaved}>
                      Saved PDF - {`Saved on ${systemDate}`}
                    </Text>
                  </View>
                </View>

                <Text style={pdfStyles?.pdfSubHeader}>Search Results</Text>
              </>
            )}
            <View style={pdfStyles?.pdfTableHeader}>
              <Text style={[pdfStyles?.pdfHeaderCell, pdfStyles.pdfrowCount]}>
                S.NO
              </Text>
              <Text style={[pdfStyles?.pdfHeaderCell, pdfStyles.majorNameCell]}>
                Major Name
              </Text>
              <Text
                style={[
                  pdfStyles?.pdfHeaderCell,
                  pdfStyles?.pdfDescriptionCell,
                ]}
              >
                Major Description
              </Text>
              <Text
                style={[
                  pdfStyles?.pdfHeaderCell,
                  pdfStyles?.possibleCareersCell,
                ]}
              >
                Possible Careers
              </Text>
              <Text
                style={[pdfStyles?.pdfHeaderCell, pdfStyles?.notablePeopleCell]}
              >
                Notable People
              </Text>
              <Text
                style={[pdfStyles?.pdfHeaderCell, pdfStyles?.medianIncomeCell]}
              >
                Median Income
              </Text>
            </View>
            {record?.map((item: any, index: any) => (
              <View key={index} style={pdfStyles?.pdfTableRow}>
                <Text style={[pdfStyles?.pdfCell, pdfStyles?.pdfrowCount]}>
                  {pageIndex * recordsSize + index + 1}
                </Text>
                <Text style={[pdfStyles?.pdfCell, pdfStyles?.majorNameCell]}>
                  {item?.MajorName}
                </Text>
                <Text
                  style={[pdfStyles?.pdfCell, pdfStyles?.pdfDescriptionCell]}
                >
                  {item?.MajorDescription}
                </Text>
                <Text
                  style={[pdfStyles?.pdfCell, pdfStyles?.possibleCareersCell]}
                >
                  {item?.PossibleCareers?.join(", ")}
                </Text>
                <Text
                  style={[pdfStyles?.pdfCell, pdfStyles?.notablePeopleCell]}
                >
                  {item?.NotablePeople?.join(", ")}
                </Text>
                <Text style={[pdfStyles?.pdfCell, pdfStyles?.medianIncomeCell]}>
                  {item?.MedianIncome}
                </Text>
              </View>
            ))}
          </PDFPage>
        ))}
      </Document>
    );
  };

  const handleDownloadPDF = async () => {
    const doc = <DownloadMajorsPDF />;
    const blob = await pdf(doc)?.toBlob();
    const link = document?.createElement("a");
    link.href = URL?.createObjectURL(blob);
    link.download = `CollegeCrafterSearchResults${systemDate}.pdf`;
    link.click();
  };

  return (
    <Page>
      <div className="generate-major-mainpage-section">
        {showLoader && <MajorLoader />}
        {generateMajorsResponse?.length > 0 && (
          <div>
            <div className="generate-majors-page">
              <div className="collegegrouplogo-section">
                <img
                  src={collegegrouplogo}
                  alt="college crafter logo"
                  className="collegegroup-img"
                />
              </div>
              <div className="generate-majors-content">
                <div className="generate-majors-poweredby-text-section">
                  <p className="generate-majors-poweredby-text">
                    <span className="generate-majors-span-text">
                      Our Major Module, powered by ChatGPT4, will suggest majors
                      based on your interest. We will also provide potential
                      career options, salaries and notable people in those
                      fields that you might relate to.
                      <br />
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {generateMajorsResponse?.length > 0 && (
              <div className="generate-majors-selected-courses-section">
                <div className="matched-courses-header-section">
                  <div className="findmajorslogo-section">
                    <img
                      src={findmajors}
                      alt="find majors logo"
                      className="collegegroup-img"
                    />
                  </div>
                  <div className="generate-majors-header-details">
                    <div className="generate-majors-header-name">
                      <span>Majors That Match Your Interest</span>
                    </div>
                    <div className="generate-majors-date-section">
                      <span>{formattedDate}</span>
                    </div>
                  </div>
                </div>

                <div className="tags-and-pdf">
                  <div className="generate-majors-tags-section">
                    <span>
                      Tags:{" "}
                      {tags
                        ?.filter((tag) => tag !== undefined || "")
                        ?.join(", ")}
                    </span>
                  </div>

                  <div className="generat-major-savepdf-button-section">
                    <Button
                      variant="contained"
                      onClick={handleDownloadPDF}
                      type="submit"
                      className="savepdf-button"
                    >
                      <img
                        src={upload}
                        alt={""}
                        className="savepdf-button-image"
                      ></img>
                      <span>{SignupButtonsLabel.SaveAsPDF}</span>
                    </Button>
                  </div>
                </div>

                {generateMajorsResponse?.map((major, index) => (
                  <Grid item xs={12} key={index}>
                    <div className="generate-majors-selected-list-of-courses-first-section">
                      <div className="generate-majors-selected-list-of-courses-first-section-content">
                        <div className="generate-majors-course-log">
                          <div className="generate-majors-selected-course-logo-section">
                            <img
                              src={generatebook}
                              alt="logo"
                              className="findmajors-logo-align"
                            />
                          </div>
                        </div>
                        <div className="major-options">
                          <div className="generate-majors-selected-course-content-section">
                            <div className="generate-majors-selected-course-name">
                              <span>{major.majorName}</span>
                            </div>
                            <div className="generate-majors-selected-course-description">
                              <span className="ellipsis">
                                {major?.majorDescription &&
                                  major.majorDescription.split(".").shift() +
                                    "."}
                              </span>
                            </div>

                            <div className="generate-majors-selected-course-home-barchat-persons-section">
                              <div className="home-section">
                                <div className="generate-majors-homeicon-section">
                                  <img src={home} alt="home logo" />
                                </div>
                                <div className="generate-majors-selected-course-home-info-section">
                                  <div className="generate-majors-selected-course-home-info">
                                    <span>Possible Careers</span>
                                  </div>
                                  <div className="generate-majors-selected-course-home-info-down-content">
                                    {major?.possibleCareers?.map(
                                      (career, idx) => (
                                        <span key={idx}>
                                          {career}
                                          {idx !==
                                            major.possibleCareers.length - 1 &&
                                            ", "}
                                          <br></br>
                                        </span>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="barchat-section">
                                <div className="generate-majors-barchat-section">
                                  <img src={barchart} alt="bar logo" />
                                </div>
                                <div className="generate-majors-selected-course-barchat-info-section">
                                  <div className="generate-majors-selected-course-barchat-info">
                                    <span>Median Income</span>
                                  </div>
                                  <div className="generate-majors-selected-course-barchat-info-down-content">
                                    <span>{major?.medianIncome}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="persons-section">
                                <div className="generate-majors-persons-section">
                                  <img src={persons} alt="persons logo" />
                                </div>
                                <div className="generate-majors-selected-course-persons-info-section">
                                  <div className="generate-majors-selected-course-persons-info">
                                    <span>Notable People</span>
                                  </div>
                                  <div className="generate-majors-selected-course-persons-info-down-content">
                                    {major?.notablePeople?.map(
                                      (notablePersons: any, idx: any) => (
                                        <span key={idx}>
                                          {notablePersons?.notablePeopleName}
                                          {idx !==
                                            major?.notablePeople?.length - 1}
                                          <br></br>
                                        </span>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="generate-majors-action-btns-section">
                            <div className="fav-btn">
                              <div className="generate-majors-favorites-btn-section">
                                <Button
                                  type="submit"
                                  onClick={() => handleAddFavorites(major)}
                                  className="generate-majors-favorites-btn"
                                >
                                  <img
                                    src={
                                      major?.isFavorite
                                        ? greenloveicon
                                        : loveicon
                                    }
                                    className="loveicon-space"
                                    alt="Love icon"
                                  />
                                  <span>Favorite</span>
                                </Button>
                              </div>
                              <div className="generate-majors-learn-more-btnsection">
                                <Button
                                  type="submit"
                                  onClick={() => handleSettingsClick(major)}
                                  fullWidth
                                  className="generate-majors-learn-more-btn"
                                >
                                  <span>Learn More</span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </div>
            )}
          </div>
        )}

        <Drawer
          variant={handleSettingsDrawer()}
          anchor="right"
          open={isSettingsDrawerOpen}
          onClose={handleSettingsClick}
          PaperProps={{
            sx: {
              width: "370px !important",
              maxWidth: "370px !important",
              minWidth: "370px !important",
            },
          }}
        >
          <div className="generate-majors-drawer-section">
            <div className="generate-majors-drawer-section-bnts">
              <div className="generate-majors-drawer-arraw-section">
                <Button
                  onClick={handleMainDrawerClose}
                  className="settings-btn-sec"
                >
                  <img src={arraw} alt="drawer close"></img>
                </Button>
              </div>
              <div className="generate-majors-drawer-favorites-section">
                <Button
                  type="submit"
                  onClick={() => handleAddFavorites(currentMajor)}
                  className="generate-majors-drawer-favorites-btn"
                >
                  <img
                    src={currentMajor?.isFavorite ? greenloveicon : loveicon}
                    alt="fav logo"
                    className="generate-majors-drawer-loveicon-space"
                  ></img>{" "}
                  <span>Favorite</span>
                </Button>
              </div>
            </div>
            <Divider className="generate-major-drawer-divider"></Divider>
            <div className="generate-major-drawer-scroll">
              <div className="generate-major-drawer-histroy-section">
                <div className="generate-major-drawer-histroy-content">
                  <div className="generate-major-drawer-bookopen">
                    <img
                      src={generatebook}
                      className="open-book"
                      alt="major name"
                    ></img>
                  </div>
                  <div>
                    <div className="generate-majors-drawer-history-header">
                      <span>{currentMajor?.majorName}</span>
                    </div>
                  </div>
                </div>
                <div className="generate-majors-drawer-historyhome-section">
                  <div className="generate-majors-drawer-historyhome">
                    <img src={historyhome} alt="history home" />
                  </div>
                  <div className="generate-majors-drawer-historyhome-content">
                    <div className="generate-majors-drawer-historyhome-info">
                      <span>Possible Careers</span>
                    </div>
                    <div className="generate-majors-drawer-historyhome-info-down-content">
                      {currentMajor?.possibleCareers?.map(
                        (career: any, idx: number) => (
                          <span key={idx}>
                            {career}
                            {idx !== currentMajor.possibleCareers.length - 1 &&
                              ", "}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="generate-majors-drawer-historyhome-section">
                  <div className="generate-majors-drawer-historyhome">
                    <img src={historybarchat} alt="history bar" />
                  </div>
                  <div className="generate-majors-drawer-historyhome-content">
                    <div className="generate-majors-drawer-historyhome-info">
                      <span>Median Income</span>
                    </div>
                    <div className="generate-majors-drawer-historyhome-info-down-content">
                      <span>{currentMajor?.medianIncome} </span>
                    </div>
                  </div>
                </div>
                <div className="generate-majors-drawer-historyhome-section">
                  <div className="generate-majors-drawer-historyhome">
                    <img src={notablepeople} alt="notable people" />
                  </div>
                  <div className="generate-majors-drawer-historyhome-content">
                    <div className="generate-majors-drawer-historyhome-info">
                      <span>Notable People</span>
                    </div>
                    <div className="generate-majors-drawer-historyhome-info-down-content">
                      {currentMajor?.notablePeople?.map(
                        (notablePersons: any, index: number) => (
                          <span key={index}>
                            {notablePersons?.notablePeopleName}
                            {index !==
                              currentMajor?.notablePeople?.length - 1 && ", "}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="generate-major-drawer-divider"></Divider>
              <div className="learn-more-section">
                <label className="about-major-header">About The Major</label>
                <div className="about-major-description">
                  <span>{currentMajor?.majorDescription}</span>
                </div>

                <label className="about-notablepeople-header">
                  Notable People
                </label>
                <div className="about-notablepeople-description">
                  {currentMajor?.notablePeople?.map(
                    (description: any, index: any) => (
                      <div key={index} className="notable-people-description">
                        <span className="notable-people-name">
                          {description?.notablePeopleName}
                        </span>
                        <span>{` - ${description?.notablePeopleDescription}`}</span>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </Drawer>
        <Dialog open={errorPopUp} style={{ marginRight: "-60px" }}>
          <Paper style={{ width: "400px", height: "110px" }}>
            <DialogTitle>{"Failed"}</DialogTitle>{" "}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errorMessage}
              </DialogContentText>
            </DialogContent>
          </Paper>
        </Dialog>
        <Dialog open={isTokenExpired} style={{ marginRight: "-60px" }}>
          <Paper style={{ width: "400px", height: "130px" }}>
            <DialogTitle>{"Something went wrong"}</DialogTitle>{" "}
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {errorMessage}
              </DialogContentText>
            </DialogContent>
          </Paper>
        </Dialog>
      </div>
    </Page>
  );
};
